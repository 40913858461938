import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from '../global';
//import { JwtHelperService } from "@auth0/angular-jwt";

//const helper = new JwtHelperService();

@Injectable()
export class UserService {
    public url: string;
    public identity: any;
    public token: string;
    public expires: number;

    constructor(
        private _http: HttpClient,
     //   private _Router: Router,
    ) {
       
        this.url = global.url;
    }

    login(login: string, password: string): Observable<any> {
        const data = {
            'usuario': login,
            'clave': password
        };

        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json');
        return this._http.post(this.url + 'authentication', data, { headers: headers });
    }

    update(token, user) {
        const data = user;

        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', 'Bearer ' + token);
        return this._http.put(this.url + 'usuarios/' + data.id, data, { headers: headers });
    }

    updatePass(token, data) {

        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', 'Bearer ' + token);
            return this._http.put(this.url + 'usuarios/', data, { headers: headers });
    }

    getIdentity() {
        const identity = JSON.parse(localStorage.getItem('user'));

        if (identity && identity !== 'undefined') {
            this.identity = identity;
        } else {
            this.identity = null;
        }

        return this.identity;
    }

    getExpires() {
        const expires = parseInt(localStorage.getItem('expires'), 10);
        if (expires) {
            this.expires = expires;
        } else {
            this.expires = null;
        }

        return this.expires;

    }

    getToken() {
        const token = localStorage.getItem('token');

        if (token && token !== 'undefined') {
            this.token = token;
        } else {
            this.token = null;
        }

        return this.token;
    }
    logout(): void{
        localStorage.removeItem('token');
        // user UserIsLogged= false
    }

   /* private checkToke(): void{
        const userToken = localStorage.getItem('token');
        const isExpired = helper.isTokenExpired(userToken);
        console.log('IsExpired->', isExpired); 
    }*/

}
