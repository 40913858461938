import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../../services/seguridad/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [UserService]
})
export class LoginComponent implements OnInit {
  public login: '';
  public password: '';
  public loginValid = false;
  public LoginError = '';
  public token;
  public identity;

  constructor(
    private _UserService: UserService,
    private _Router: Router,
    private _Route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.logout();
  }

  onLogin() {
    // if data has value
    if (!(this.login.length === 0 || this.password.length === 0)) {
      this._UserService.login(this.login, this.password).subscribe(
        response => {
          this.loginValid = true;

          this.token = response.token;
          this.identity = response.usuario;

          localStorage.setItem('token', this.token);
          localStorage.setItem('user', JSON.stringify(this.identity));
          localStorage.setItem('expires', response.expires);

          this._Router.navigate(['inicio']);
        },
        error => {
          this.loginValid = false;
          this.LoginError = error.message;
        }
      );
    }
  }

  logout() {
    this._Route.params.subscribe(params => {
      const logout = +params['sure'];

      if (logout === 1) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');

        this.identity = null;
        this.token = null;

        // redireccion a inicio
        this._Router.navigate(['inicio']);
      }

    });
  }

}
