import { TipoFuenteAgua } from '../catalogos/tipo_fuente_agua';
import { TipoObra } from '../catalogos/tipo_obra';
import { TipoSistema } from '../catalogos/tipo_sistema';
import { MetodoAforo } from '../catalogos/metodo_aforo';

export class InformeInventarioFuenteSuperficial {
    public id_informe = 0;
    public fuente = '';
    public tipo_fuente: TipoFuenteAgua = null;
    public tipo_obra_captacion: TipoObra = null;
    public tipo_sistema: TipoSistema = null;
    public coordenada_zona = '';
    public coordenada_x = '';
    public coordenada_y = '';
    public coordenada_z = '';
    public ancho = 0;
    public tirante = 0;
    public caudal = 0;
    public metodo_aforo: MetodoAforo = null;
    public fecha_aforo = '';
}
