import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { global } from './global';
import { InformeN } from '../models/Informe/informe';




@Injectable()
export class InformeService {
    public url: string;

    constructor(
        private _http: HttpClient

    ) {
        this.url = global.url;
    }
    report(token): Observable<any> {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', 'Bearer ' + token);
        return this._http.get(this.url + 'reports', { headers: headers });
    }

    list(token): Observable<any> {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', 'Bearer ' + token);
        return this._http.get(this.url + 'informe', { headers: headers });
    }
    addN(token, informe: InformeN): Observable<any> {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', 'Bearer ' + token);
        return this._http.post(this.url + 'informe', informe, { headers: headers });
    }
    editN(token, informe: InformeN) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', 'Bearer ' + token);

        const data_correos = <any>[];
        informe.informe_correos.forEach(item => {
            const org = { id_informe: item.id_informe, correo: item.correo, telefono: item.telefono };
            data_correos.push(org);
        });

        const data_comunidades = <any>[];
        informe.informe_comunidades.forEach(item => {
            const org = {
                id_informe: item.id_informe, id_comunidad: item.comunidad.id, id_etnia: item.etnia.id,
                id_tipo_poblacion: item.tipo_poblacion.id, coordenada_zona: item.coordenada_zona,
                coordenada_x: item.coordenada_x, coordenada_y: item.coordenada_y, coordenada_z: item.coordenada_z,
                punto_referencia: item.punto_referencia
            };
            data_comunidades.push(org);
        });

        const data_distancias = <any>[];
        informe.informe_distancias.forEach(item => {
            const org = {
                id_informe: item.id_informe, id_comunidad: item.comunidad.id,
                distancia_departamental: item.distancia_departamental, distancia_municipal: item.distancia_municipal,
                distancia_managua: item.distancia_managua, tiempo_vehiculo: item.tiempo_vehiculo, tiempo_camion: item.tiempo_camion,
                distancia_dificultad: item.distancia_dificultad
            };
            data_distancias.push(org);
        });

        const data_vias_accesos = <any>[];
        informe.informe_vias_acceso.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                id_comunidad: item.comunidad.id,
                id_tipo_revestimiento: item.tipo_revestimiento.id,
                id_tipo_medios_transporte: item.tipo_medios_transporte.id,
                origen: item.origen,
                destino: item.destino,
                distancia: item.distancia,
                tiempo: item.tiempo
            };
            data_vias_accesos.push(org);
        });
        // ------------------------------------------
        const data_actividades_econ = <any>[];
        informe.informe_actividades_economicas.forEach(item => {
            const org = {
                id_informe: item.id_informe, id_actividad_economica: item.actividad_economica.id, cantidad: item.cantidad,
                porcentaje: item.porcentaje
            };
            data_actividades_econ.push(org);
        });

        const data_servicios_exist = <any>[];
        informe.informe_servicios_existentes.forEach(item => {
            const org = {
                id_informe: item.id_informe, id_tipo_servicio: item.tipo_servicio.id,
                cantidad: item.cantidad, estado: item.estado
            };
            data_servicios_exist.push(org);
        });

        // INICIO DEL NUEVO MODULO 1 SECCION 5
        const data_inventario_fuente_superficial = <any>[];
        informe.informe_inventario_fuente_superficial.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                fuente: item.fuente,
                id_tipo_fuente: item.tipo_fuente.id,
                id_tipo_obra_captacion: item.tipo_obra_captacion.id,
                id_tipo_sistema: item.tipo_sistema.id,
                coordenada_zona: item.coordenada_zona,
                coordenada_x: item.coordenada_x,
                coordenada_y: item.coordenada_y,
                coordenada_z: item.coordenada_z,
                ancho: item.ancho,
                tirante: item.tirante,
                caudal: item.caudal,
                id_metodo_aforo: item.metodo_aforo.id,
                fecha_aforo: item.fecha_aforo,
            };
            data_inventario_fuente_superficial.push(org);
        });

        const data_inventario_fuente_subterranea = <any>[];
        informe.informe_inventario_fuente_subterranea.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                fuente: item.fuente,
                id_tipo_fuente: item.tipo_fuente.id,
                coordenada_zona: item.coordenada_zona,
                coordenada_x: item.coordenada_x,
                coordenada_y: item.coordenada_y,
                coordenada_z: item.coordenada_z,
                diametro: item.diametro,
                pt: item.pt,
                nea: item.nea,
                columna: item.columna,
                caudal: item.caudal,
                tipo_registro: item.tipo_registro,
                fecha: item.fecha,
                id_tipo_bomba: item.tipo_bomba.id,
                regimen: item.regimen,
            };
            data_inventario_fuente_subterranea.push(org);
        });

        const data_inventario_analisis_preliminar = <any>[];
        informe.informe_inventario_analisis_preliminar.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                fuente: item.fuente,
                tipo_fuente: item.tipo_fuente,
                pH: item.pH,
                temperatura: item.temperatura,
                ce: item.ce,
                sdt: item.sdt,
                turbidez: item.turbidez,
                salinidad: item.salinidad,
                hierro: item.hierro,
                cloro_total: item.cloro_total,
                cloro_residual: item.cloro_residual,
            };
            data_inventario_analisis_preliminar.push(org);
        });

        const data_parametros_organolepticos = <any>[];
        informe.informe_inventario_parametros_organolepticos.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                fuente: item.fuente,
                tipo_fuente: item.tipo_fuente,
                aspecto: item.aspecto,
                color: item.color,
                turbidez: item.turbidez,
            };
            data_parametros_organolepticos.push(org);
        });

        const data_parametros_fisico_quimicios = <any>[];
        informe.informe_inventario_parametros_fisico_quimicios.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                fuente: item.fuente,
                tipo_fuente: item.tipo_fuente,
                bdo: item.bdo,
                temperatura: item.temperatura,
                pH: item.pH,
                cloruros: item.cloruros,
                sulfatos: item.sulfatos,
                calcio: item.calcio,
                magnesio: item.magnesio,
                od: item.od,
                ce: item.ce,
                sodio: item.sodio,
                potasio: item.potasio,
                aluminio: item.aluminio,
                dureza_total: item.dureza_total,
                sdt: item.sdt,
            };
            data_parametros_fisico_quimicios.push(org);
        });

        const data_parametros_indeseables = <any>[];
        informe.informe_inventario_parametros_indeseables.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                fuente: item.fuente,
                tipo_fuente: item.tipo_fuente,
                nitratos: item.nitratos,
                nitritos: item.nitritos,
                amonio: item.amonio,
                hierro_total: item.hierro_total,
                manganeso: item.manganeso,
                cobre: item.cobre,
                fosforo: item.fosforo,
                fluoruros: item.fluoruros,
                zinc: item.zinc,
            };
            data_parametros_indeseables.push(org);
        });


        const data_parametros_metales = <any>[];
        informe.informe_inventario_parametros_metales.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                fuente: item.fuente,
                tipo_fuente: item.tipo_fuente,
                arsenico: item.arsenico,
                cianuro: item.cianuro,
                mercurio: item.mercurio,
                plomo: item.plomo,
            };
            data_parametros_metales.push(org);
        });

        const data_parametro_bacteriologicos = <any>[];
        informe.informe_inventario_parametros_bacteriologicos.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                fuente: item.fuente,
                tipo_fuente: item.tipo_fuente,
                coliformes_totales: item.coliformes_totales,
                e_coli: item.e_coli,
                termotolerantes: item.termotolerantes,
            };
            data_parametro_bacteriologicos.push(org);
        });

        const data_inventario_propuesta_alternativa = <any>[];
        informe.informe_inventario_propuesta_alternativa.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                fuente: item.fuente,
                id_tipo_fuente: item.tipo_fuente.id,
                coordenada_zona: item.coordenada_zona,
                coordenada_x: item.coordenada_x,
                coordenada_y: item.coordenada_y,
                coordenada_z: item.coordenada_z,
                caudal: item.caudal,
                id_tipo_sistema: item.tipo_sistema.id,
                id_tipo_bomba: item.tipo_bomba.id,
                id_tipo_tratamiento: item.tipo_tratamiento.id,
                id_estado_legal_fuente: item.estado_legal_fuente.id,
            };
            data_inventario_propuesta_alternativa.push(org);
        });

        const data_inventario_cobertura_forestal = <any>[];
        informe.informe_inventario_cobertura_forestal.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                fuente: item.fuente,
                id_tipo_fuente: item.tipo_fuente,
                bosque_denso: item.bosque_denso,
                bosque_ralo: item.bosque_ralo,
                bosque_galeria: item.bosque_galeria,
                pinar: item.pinar,
                vegetacion: item.vegetacion,
                agropecuario: item.agropecuario,
                mangle: item.mangle,
                potreros: item.potreros,
            };
            data_inventario_cobertura_forestal.push(org);
        });

        const data_actividad_agricola_proyecto = <any>[];
        informe.infome_actividad_agricola_proyecto.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                fuente: item.fuente,
                id_tipo_fuente: item.tipo_fuente,
                descripcion: item.descripcion,
            };
            data_actividad_agricola_proyecto.push(org);
        });

        const data_control_plagas = <any>[];
        informe.informe_control_plagas.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                fuente: item.fuente,
                id_tipo_fuente: item.tipo_fuente,
                descripcion: item.descripcion,
            };
            data_control_plagas.push(org);
        });

        const data_fertilizantes = <any>[];
        informe.informe_fertilizantes.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                fuente: item.fuente,
                id_tipo_fuente: item.tipo_fuente,
                descripcion: item.descripcion,
            };
            data_fertilizantes.push(org);
        });



        const data_banco_material = <any>[];
        informe.informe_bancos_materiales.forEach(item => {
            const org = {
                id_informe: item.id_informe, nombre: item.nombre, duenno: item.duenno,
                tipo_tenencia: item.tipo_tenencia, estado_via_acceso: item.estado_via_acceso,
                distancia_obra: item.distancia_obra, explotacion: item.explotacion, permiso: item.permiso,
                id_tipo_material: item.tipo_material.id, coordenada_zona: item.coordenada_zona,
                coordenada_x: item.coordenada_x, coordenada_y: item.coordenada_y, coordenada_z: item.coordenada_z
            };
            data_banco_material.push(org);
        });

        const data_geotecnica_suelo = <any>[];
        informe.informe_geotecnica_suelo.forEach(item => {
            const org = {
                id_informe: item.id_informe, id_geotecnica_suelo: item.geotecnica_suelo.id
            };
            data_geotecnica_suelo.push(org);
        });

        const data_instalacion_agua = <any>[];
        informe.informe_instalaciones_agua.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                id_tipo_instalacion: item.tipo_instalacion_agua.id,
                estado: item.estado,
                descripcion_estado: item.descripcion_estado,
                caracteristicas: item.caracteristicas,
                coordenada_zona: item.coordenada_zona,
                coordenada_x: item.coordenada_x,
                coordenada_y: item.coordenada_y,
                coordenada_z: item.coordenada_z
            };
            data_instalacion_agua.push(org);
        });

        const data_distribuciones_redes = <any>[];
        informe.informe_distribuciones_redes.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                id_tipo_red: item.tipo_red.id,
                diametro: item.diametro,
                estado: item.estado,
                longitud: item.longitud,
                caracteristicas: item.caracteristicas,
            };
            data_distribuciones_redes.push(org);
        });

        const data_nivel_servicio = <any>[];
        informe.informe_nivel_servicio.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                id_nivel_servicio: item.nivel_servicio.id,
                estado: item.estado,
                cantidad: item.cantidad,
                poblacion_atendida: item.poblacion_atendida,
                viviendas_atendidas: item.viviendas_atendidas,
                caracteristicas: item.caracteristicas,
            };
            data_nivel_servicio.push(org);
        });

        const data_instalaciones_tratamiento = <any>[];
        informe.informe_instalaciones_tratamiento.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                id_tipo_tratamiento: item.tipo_tratamiento.id,
                tipo: item.tipo,
                estado: item.estado,
            };
            data_instalaciones_tratamiento.push(org);
        });

        const data_instalaciones_saneamiento = <any>[];
        informe.informe_instalaciones_saneamiento.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                id_tipo_instalacion_saneamiento: item.tipo_instalacion_saneamiento.id,
                estado: item.estado,
                cantidad: item.cantidad,
                poblacion_atendida: item.poblacion_atendida,
                viviendas_atendidas: item.viviendas_atendidas,
                caracteristicas: item.caracteristicas,
            };
            data_instalaciones_saneamiento.push(org);
        });

        const data_instalaciones_higiene = <any>[];
        informe.informe_instalaciones_higiene.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                id_tipo_instalacion_higiene: item.tipo_instalacion_higiene.id,
                estado: item.estado,
                cantidad: item.cantidad,
                poblacion_atendida: item.poblacion_atendida,
                viviendas_atendidas: item.viviendas_atendidas,
                caracteristicas: item.caracteristicas,
            };
            data_instalaciones_higiene.push(org);
        });

        const data_organizaciones = <any>[];
        informe.informe_organizacion.forEach(item => {
            const org = { id_informe: item.id_informe, id_tipo_organizacion: item.tipo_organizacion.id, nombre: item.nombre };
            data_organizaciones.push(org);
        });

        // FIN DE MODULO I

        const data_estacion_precipitacion = <any>[];
        informe.informe_estacion_precipitacion.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                periodo: item.periodo,
                estacion: item.estacion,
                coordenada_zona: item.coordenada_zona,
                coordenada_x: item.coordenada_x,
                coordenada_y: item.coordenada_y,
                coordenada_z: item.coordenada_z,
                precipitacion_ene: item.precipitacion_ene,
                precipitacion_feb: item.precipitacion_feb,
                precipitacion_mar: item.precipitacion_mar,
                precipitacion_abr: item.precipitacion_abr,
                precipitacion_may: item.precipitacion_may,
                precipitacion_jun: item.precipitacion_jun,
                precipitacion_jul: item.precipitacion_jul,
                precipitacion_ago: item.precipitacion_ago,
                precipitacion_sep: item.precipitacion_sep,
                precipitacion_oct: item.precipitacion_oct,
                precipitacion_nov: item.precipitacion_nov,
                precipitacion_dic: item.precipitacion_dic,
            };
            data_estacion_precipitacion.push(org);
        });

        const data_medio_hg_superficial = <any>[];
        informe.informe_medio_hg_superficial.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                nombre: item.nombre,
                codigo_pfastetter: item.codigo_pfastetter,
                area: item.area,
                longitud_rio_principal: item.longitud_rio_principal,
                longitud_rio_total: item.longitud_rio_total,
                altura_maxima: item.altura_maxima,
                altura_minima: item.altura_minima,
                tiempo_concentracion_horas: item.tiempo_concentracion_horas,
                tiempo_concentracion_minutos: item.tiempo_concentracion_minutos,
                coeficiente_compacidad: item.coeficiente_compacidad,
                densidad_drenaje: item.densidad_drenaje,
                factor_forma: item.factor_forma,
                pendiente: item.pendiente,
                rios_primer_orden: item.rios_primer_orden,
                rios_total: item.rios_total,
                coeficiente_torrencialidad: item.coeficiente_torrencialidad,
                extension_escurrimiento: item.extension_escurrimiento,
                frecuencia_rios: item.frecuencia_rios,
                cuenca_orden: item.cuenca_orden,
            };
            data_medio_hg_superficial.push(org);
        });

        const data_medio_hg_subterraneo = <any>[];
        informe.informe_medio_hg_subterraneo.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                nombre: item.nombre,
                id_tipo_acuifero: item.tipo_acuifero.id,
                espesor: item.espesor,
                transmisividad: item.transmisividad,
                conductividad: item.conductividad,
                coeficiente_almacenamiento: item.coeficiente_almacenamiento,
            };
            data_medio_hg_subterraneo.push(org);
        });

        const data_potencial_hidrico_superficial = <any>[];
        informe.informe_potencial_hidrico_superficial.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                mes: item.mes,
                dias: item.dias,
                temperatura: item.temperatura,
                etp_sin_corregir: item.etp_sin_corregir,
                ept_corregido: item.ept_corregido,
                precipitacion: item.precipitacion,
                p_etp: item.p_etp,
                etr: item.etr,
                almacenamiento: item.almacenamiento,
                deficit: item.deficit,
                excedentes: item.excedentes,
                exedentes_medio: item.exedentes_medio,
                escorrentia_total: item.escorrentia_total,
                escorrentia_media: item.escorrentia_media,
                caudal_teorico_m3: item.caudal_teorico_m3,
                caudal_teorico_lps: item.caudal_teorico_lps,
                demanda_md: item.demanda_md,
                deficit_exeso: item.deficit_exeso,
            };
            data_potencial_hidrico_superficial.push(org);
        });

        const data_potencial_hidrico_subterraneo = <any>[];
        informe.informe_potencial_hidrico_subterraneo.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                bombeo1_tiempo: item.bombeo1_tiempo,
                bombeo1_descenso: item.bombeo1_descenso,
                bombeo1_capacidad: item.bombeo1_capacidad,
                bombeo2_tiempo: item.bombeo2_tiempo,
                bombeo2_descenso: item.bombeo2_descenso,
                bombeo2_capacidad: item.bombeo2_capacidad,
                bombeo3_tiempo: item.bombeo3_tiempo,
                bombeo3_descenso: item.bombeo3_descenso,
                bombeo3_capacidad: item.bombeo3_capacidad,
                bombeo4_tiempo: item.bombeo4_tiempo,
                bombeo4_descenso: item.bombeo4_descenso,
                bombeo4_capacidad: item.bombeo4_capacidad,
            };
            data_potencial_hidrico_subterraneo.push(org);
        });

        const data_alternativas_fuente = <any>[];
        informe.informe_alternativas_fuente.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                fuente: item.fuente,
                id_tipo_fuente: item.tipo_fuente.id,
                coordenada_zona: item.coordenada_zona,
                coordenada_x: item.coordenada_x,
                coordenada_y: item.coordenada_y,
                coordenada_z: item.coordenada_z,
                caudal: item.caudal,
                id_tipo_sistema: item.tipo_sistema.id,
                id_tipo_tratamiento: item.tipo_tratamiento.id,
                estado_legal: item.estado_legal,
            };
            data_alternativas_fuente.push(org);
        });

        const data_alternativas_fuente_calidad = <any>[];
        informe.informe_alternativas_fuente_calidad.forEach(item => {
            const org = {
                id_informe_alternativa_fuente: item.informe_alternativa_fuente.id_informe,
                id_parametro_calidad: item.parametro_calidad.id,
                valor: item.valor,
            };
            data_alternativas_fuente_calidad.push(org);
        });

        const data_usos_agua = <any>[];
        informe.informe_usos_agua.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                id_usos_agua: item.usos_agua.id,
            };
            data_usos_agua.push(org);
        });

        const data_fuentes_contaminacion = <any>[];
        informe.informe_fuentes_contaminacion.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                id_tipo_contaminacion: item.tipo_contaminacion.id,
                descripcion: item.descripcion,
            };
            data_fuentes_contaminacion.push(org);
        });

        const data_fuentes_amenazas = <any>[];
        informe.informe_fuentes_amenazas.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                id_tipo_amenaza: item.tipo_amenaza.id,
                descripcion: item.descripcion,
            };
            data_fuentes_amenazas.push(org);
        });

        const data_objetivos_medios = <any>[];
        informe.informe_objetivos_medios.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                objetivo_desarrollo: item.objetivo_desarrollo,
                objetivo_especifico: item.objetivo_especifico,
                medios: item.medios,
                proposito: item.proposito,
            };
            data_objetivos_medios.push(org);
        });

        const data_objetivos_metas = <any>[];
        informe.informe_objetivos_metas.forEach(item => {
            const org = {
                id_informe: item.id_informe,
                objetivo_especifico: item.objetivo_especifico,
                metas: item.metas,
                indicador: item.indicador,
                medio_verificacion: item.medio_verificacion,
            };
            data_objetivos_metas.push(org);
        });

        const data = {
            id: informe.id,
            id_usuario: informe.id_usuario,
            estado_informe: informe.estado_informe,
            solicitante: informe.solicitante,
            solicitante_autoridad: informe.solicitante_autoridad,
            solicitante_direccion: informe.solicitante_direccion,
            informe_correos: data_correos,
            solicitante_director: informe.solicitante_director,
            solicitante_umas: informe.solicitante_umas,
            fecha_elaboracion: informe.fecha_elaboracion,
            m1s1_completa: informe.m1s1_completa,

            // tslint:disable-next-line:no-bitwise
            // modulo1.seccion2
            id_departamento: informe.id_departamento,
            id_municipio: informe.id_municipio,
            id_categoria_pobreza: informe.categoria_pobreza == null ? 0 : informe.categoria_pobreza.id,
            informe_comunidades: data_comunidades,
            informe_distancias: data_distancias,
            informe_vias_acceso: data_vias_accesos,
            ubicacion_imagen: informe.ubicacion_imagen,
            ubicacion_micro: informe.ubicacion_micro,
            m1s2_completa: informe.m1s2_completa,

            // modulo1.seccion3
            problema: informe.problema,
            causas: informe.causas,
            m1s3_completa: informe.m1s3_completa,



            // modulo1.seccion4
            poblacion_mujeres: informe.poblacion_mujeres,
            poblacion_hombres: informe.poblacion_hombres,
            poblacion_total: informe.poblacion_total,
            anyo_lineabase: informe.anyo_lineabase,
            poblacion_ninnos: informe.poblacion_ninnos,
            poblacion_ninnas: informe.poblacion_ninnas,
            familias: informe.familias,
            persona_diferentes: informe.persona_diferentes,
            familias_jefas: informe.familias_jefas,
            viviendas: informe.viviendas,
            ingreso: informe.ingreso,
            total_trabajadores: informe.total_trabajadores,
            informe_actividades_economicas: data_actividades_econ,
            informe_servicios_existentes: data_servicios_exist,
            ubicacion_infome_bd: informe.ubicacion_infome_bd,
            ubicacion_bd: informe.ubicacion_bd,
            m1s4_completa: informe.m1s4_completa,

            // INICIO DE LA SECCION 5 DEL MODULO I

            /*  --| modulo1.seccion5.1 ESTIMACION DE LA DEMANDA |--
           -- Analisis de la demanda de la hoja electronica del ing. Yalmar Zamora --
            //--A) Datos Historíco ---- */

            pd_poblacion_historica : informe.pd_poblacion_historica,
            anyo_censal: informe.anyo_censal,

            // --| B) Proyeccion de poblacion |--
            pd_tasa_crecimiento: informe.pd_tasa_crecimiento,
            pd_poblacion_actual : informe.pd_poblacion_actual,
            pd_anno_inicio: informe.pd_anno_inicio,
            pd_poblacion_inicial: informe.pd_poblacion_inicial,
            pd_poblacion_a_veinte: informe.pd_poblacion_a_veinte,
            pd_periodo_disenno: informe.pd_periodo_disenno,

            //  --|C)  Criterios de diseño a 20 años ||-- -------
            pd_dotacion_domiciliar: informe.pd_dotacion_domiciliar,
            pd_demanda_dotdomiciliar: informe.pd_demanda_dotdomiciliar,
            /// --- DEMANDA DE EMERGENCIA
            pd_dotacion_emergencia: informe.pd_dotacion_emergencia,
            pd_demanda_dotemergencia: informe.pd_demanda_dotemergencia,

            // ------MERCADO--- DEMADNA--
            existen_mercado : informe.existen_mercado,
            pd_dotacion_mercado: informe.pd_dotacion_mercado,
            pd_cant_mercado: informe.pd_cant_mercado,
            pd_demanda_mercado : informe.pd_demanda_mercado,
            // DEMANDA ESCUELA----------
            existen_escuelas: informe.existen_escuelas,
            pd_dotacion_escuela: informe.pd_dotacion_escuela,
            cant_escuela: informe.cant_escuela,
            cant_alumno: informe.cant_alumno,
            pd_demanda_dotescuela: informe.pd_demanda_dotescuela,
            // DEMANDA PUESTO DE SALUD....
            existen_psalud:  informe.existen_psalud,
            pd_dotacion_psalud: informe.pd_dotacion_psalud,
            pd_cantidad_psalud: informe.pd_cantidad_psalud,
            pd_demanda_dotpsalud: informe.pd_demanda_dotpsalud,

            // DEMANDA DE CENTRO DE SALUD
            existen_csaslud: informe.existen_csaslud,
            pd_dotacion_csalud: informe.pd_dotacion_csalud,
            pd_csalud: informe.pd_csalud,
            pd_cant_cama: informe.pd_cant_cama,
            pd_demanda_dotcsalud: informe.pd_demanda_dotcsalud,
            // --------------- DEMANDA MATADEROS--------------------
            existe_mataderos: informe.existe_mataderos,
            pd_dotacion_mataderos: informe.pd_dotacion_mataderos,
            pd_cant_mataderos:  informe.pd_cant_mataderos,
            pd_demanda_dotmataderos: informe.pd_demanda_dotmataderos,

            // ------------------ DEMANDA INTITUCIONAL
            existe_institucion: informe.existe_institucion,
            pd_dotacion_institucional: informe.pd_dotacion_institucional,
            pd_demanda_dotinstitucional: informe.pd_demanda_dotinstitucional,

            // COMERCIO DEMANDA
            existe_comercio: informe.existe_comercio,
            pd_dotacion_comercio: informe.pd_dotacion_comercio,
            pd_demanda_dotcomercio: informe.pd_demanda_dotcomercio,

            // DEMANDA INDUSTRIA
            existe_industria: informe.existe_industria,
            pd_dotacion_industria: informe.pd_dotacion_industria,
            pd_demanda_dotindustria: informe.pd_demanda_dotindustria,

            pd_dotacion_extradomiciliar: informe.pd_dotacion_extradomiciliar,
            pd_demanda_dfinal: informe.pd_demanda_dfinal,

            // --| D) Caudales de diseño al final de período|
            pd_caudal_medio: informe.pd_caudal_medio,
            pd_caudal_conduccion: informe.pd_caudal_conduccion,
            pd_caudal_distribucion: informe.pd_caudal_distribucion,
            pd_volumen_almacenamiento: informe.pd_volumen_almacenamiento,
            ubicacion_doc_revisado: informe.ubicacion_doc_revisado,
            m1s5_1_completa: informe.m1s5_1_completa,

            // --| modulo1.SECCION 5.2 INVENTARIO DE RECURSO HIDRICO |--
            existen_f_superficial: informe.existen_f_superficial,
            informe_inventario_fuente_superficial: data_inventario_fuente_superficial,
            existen_f_subterranea: informe.existen_f_subterranea,
            informe_inventario_fuente_subterranea: data_inventario_fuente_subterranea,
            informe_inventario_analisis_preliminar: data_inventario_analisis_preliminar,
            existen_p_organolepticos: informe.existen_p_organolepticos,
            informe_inventario_parametros_organolepticos: data_parametros_organolepticos,
            existen_p_fisicos_quimicios: informe.existen_p_fisicos_quimicios,
            informe_inventario_parametros_fisico_quimicios: data_parametros_fisico_quimicios,
            existen_s_indeseables: informe.existen_s_indeseables,
            informe_inventario_parametros_indeseables: data_parametros_indeseables,
            existen_p_metales: informe.existen_p_metales,
            informe_inventario_parametros_metales: data_parametros_metales,
            existen_p_bacteriologicos: informe.existen_p_bacteriologicos,
            informe_inventario_parametros_bacteriologicos: data_parametro_bacteriologicos,
            informe_inventario_propuesta_alternativa: data_inventario_propuesta_alternativa,
            requiere_estudio_complementario: informe.requiere_estudio_complementario,
            requiere_estudio_geofisico: informe.requiere_estudio_geofisico,
            estudio_geofisico_cantidad: informe.estudio_geofisico_cantidad,
            estudio_geofisico_perfiles: informe.estudio_geofisico_perfiles,
            estudio_geofisico_longitud: informe.estudio_geofisico_longitud,
            estudio_geofisico_profundidad: informe.estudio_geofisico_profundidad,
            requiere_prueba_bombeo: informe.requiere_prueba_bombeo,
            prueba_bombeo_cantidad_escalonada: informe.prueba_bombeo_cantidad_escalonada,
            prueba_bombeo_cantidad_constante: informe.prueba_bombeo_cantidad_constante,
            prueba_bombeo_duracion: informe.prueba_bombeo_duracion,
            requiere_otras_pruebas: informe.requiere_otras_pruebas,
            requiere_transmisividad: informe.requiere_transmisividad,
            requiere_permeabilidad: informe.requiere_permeabilidad,
            requiere_piezometria: informe.requiere_piezometria,
            profundidad_nf_invierno: informe.profundidad_nf_invierno,
            profundidad_nf_verano: informe.profundidad_nf_verano,
            ubicacion_fichahidri: informe.ubicacion_fichahidri,
            m1s5_2_completa: informe.m1s5_2_completa,

            // --| modulo1.SECCION 5.3 AMBIENTAL |--
            // -- | seccion de preguntas |--
            descripcion_area_protegida: informe.descripcion_area_protegida,
            existe_embalses: informe.existe_embalses,
            animales_reportados: informe.animales_reportados,
            // -- seccion Principales usos de suelo en el área de influencia
            bosque : informe.bosque,
            platancion_forestal: informe.platancion_forestal,
            pastos: informe.pastos,
            cultivos: informe.cultivos,
            tacotal: informe.tacotal,
            sin_vegetacion: informe.sin_vegetacion,
            cultivo_perma: informe.cultivo_perma,
            humedales: informe.humedales,
            otros: informe.otros,
            especificar: informe.especificar,
            informe_inventario_cobertura_forestal : data_inventario_cobertura_forestal,
            cobertura_forestal_imagen: informe.cobertura_forestal_imagen,
            nombres_arboles: informe.nombres_arboles,
            infome_actividad_agricola_proyecto: data_actividad_agricola_proyecto,
            informe_control_plagas: data_control_plagas,
            informe_fertilizantes: data_fertilizantes,
            beneficios: informe.beneficios,
            fosiles: informe.fosiles,

            existe_banco_materiales: informe.existe_banco_materiales,
            informe_bancos_materiales: data_banco_material,
            bancos_materiales_imagen: informe.bancos_materiales_imagen,
            bancos_autorizados: informe.bancos_autorizados,
            bancos_materiales_indigenas: informe.bancos_materiales_indigenas,

            botadero_existe: informe.botadero_existe,
            botadero_en_uso: informe.botadero_en_uso,
            botadero_distancia: informe.botadero_distancia,
            botadero_coordenada_zona: informe.botadero_coordenada_zona,
            botadero_coordenada_x: informe.botadero_coordenada_x,
            botadero_coordenada_y: informe.botadero_coordenada_y,
            botadero_coordenada_z: informe.botadero_coordenada_z,
            m1s5_3_completa: informe.m1s5_3_completa,


           // --| modulo1.seccion5.4  COMPONENTE ELECTRICO|--
            red_suministro_electrico: informe.red_suministro_electrico,
            id_concesionario: informe.id_concesionario,
            id_tipo_linea: informe.id_tipo_linea,
            id_tipo_fase: informe.id_tipo_fase,
            existe_banco_transformador: informe.existe_banco_transformador,
            capacidad_banco_transformador: informe.capacidad_banco_transformador,
            voltaje_banco_transformador: informe.voltaje_banco_transformador,
            coordenadas_zona_transformador: informe.coordenadas_zona_transformador,
            coordenadas_x_transformador: informe.coordenadas_x_transformador,
            coordenadas_y_transformador: informe.coordenadas_y_transformador,
            coordenadas_z_transformador: informe.coordenadas_z_transformador,
            distancia_poste: informe.distancia_poste,
            coordenadas_zona_poste: informe.coordenadas_zona_poste,
            coordenadas_x_poste: informe.coordenadas_x_poste,
            coordenadas_y_poste: informe.coordenadas_y_poste,
            coordenadas_z_poste: informe.coordenadas_z_poste,
            ubicacion_img_energia: informe.ubicacion_img_energia,
            ubicacion_fich_tecnica: informe.ubicacion_fich_tecnica,
            m1s5_4_completa: informe.m1s5_4_completa,


            // |MODULO1 Seccion 5.5 SUELOS	|
            id_tipo_relieve: informe.id_tipo_relieve,
            informe_geotecnica_suelo: data_geotecnica_suelo,
            descripcion_drenaje_pluvial: informe.descripcion_drenaje_pluvial,
            ubicacion_terreno_img: informe.ubicacion_terreno_img,
            m1s5_5_completa: informe.m1s5_5_completa,


            // --| modulo1. SECCION 5.6 SITUACION ACTUAL DEL PROYECTO |--
            id_tipo_sistema: informe.id_tipo_sistema,
            informe_instalaciones_agua: data_instalacion_agua,
            informe_distribuciones_redes: data_distribuciones_redes,
            informe_nivel_servicio: data_nivel_servicio,
            servicio_dias_semana: informe.servicio_dias_semana,
            servicio_horas_dia: informe.servicio_horas_dia,
            presion_residual: informe.presion_residual,
            hora_medicion: informe.hora_medicion,
            pago_promedio_mensual: informe.pago_promedio_mensual,
            consumo_promedio_mensual: informe.consumo_promedio_mensual,
            agua_recibe_tratamiento: informe.agua_recibe_tratamiento,
            informe_instalaciones_tratamiento: data_instalaciones_tratamiento,
            recibe_saneamiento: informe.recibe_saneamiento,
            informe_instalaciones_saneamiento: data_instalaciones_saneamiento,
            recibe_higiene: informe.recibe_higiene,
            informe_instalaciones_higiene: data_instalaciones_higiene,
            viviendas_sin_agua: informe.viviendas_sin_agua,
            viviendas_sin_saneamiento: informe.viviendas_sin_saneamiento,
            viviendas_sin_higiene: informe.viviendas_sin_higiene,
            deficit_agua: informe.deficit_agua,
            deficit_saneamiento: informe.deficit_saneamiento,
            deficit_higiene: informe.deficit_higiene,
            cobertura_agua: informe.cobertura_agua,
            cobertura_saneamiento: informe.cobertura_saneamiento,
            cobertura_higiene: informe.cobertura_higiene,
            sin_proyecto_imagen: informe.sin_proyecto_imagen,
            m1s5_6_completa: informe.m1s5_6_completa,
            m1s5_completa: informe.m1s5_completa,

            // --| modulo. SECCION 6 DATOS GENERALES PROYECT SOLICITADO |--
            codigo: informe.codigo,
            id_categorizacion_nivel: informe.categorizacion_nivel == null ? 0 : informe.categorizacion_nivel.id,
            id_categorizacion_tipo_saneamiento: informe.categorizacion_tipo_saneamiento == null ? 0
            : informe.categorizacion_tipo_saneamiento.id,
            nombre_proyecto: informe.nombre_proyecto,
            id_categorizacion_tipo_intervencion: informe.categorizacion_tipo_intervencion == null ? 0 :
            informe.categorizacion_tipo_intervencion.id,
            // id_modalidad: informe.modalidad == null ? 0 : informe.modalidad.id,
            comunidad_organizada: informe.comunidad_organizada,
            informe_organizacion: data_organizaciones,
            descrip_previa: informe.descrip_previa,
            obra_conexion_domiciliar: informe.obra_conexion_domiciliar,
            obra_conexion_extradomiciliar: informe.obra_conexion_extradomiciliar,
            obra_puesto_publico: informe.obra_puesto_publico,
            obra_sanamiento_arrastre: informe.obra_sanamiento_arrastre,
            obra_saneamiento_seco: informe.obra_saneamiento_seco,
            obra_unidad_higiene: informe.obra_unidad_higiene,
            monto_obra_conexion_domiciliar: informe.monto_obra_conexion_domiciliar,
            monto_obra_conexion_extradomiciliar: informe.monto_obra_conexion_extradomiciliar,
            monto_obra_puesto_publico: informe.monto_obra_puesto_publico,
            monto_obra_sanamiento_arrastre: informe.monto_obra_sanamiento_arrastre,
            monto_obra_saneamiento_seco: informe.monto_obra_saneamiento_seco,
            monto_obra_unidad_higiene: informe.monto_obra_unidad_higiene,
            monto_ejecucion: informe.monto_ejecucion,
            monto_formulacion: informe.monto_formulacion,
            monto_supervision: informe.monto_supervision,
            monto_social: informe.monto_social,
            monto_ambiental: informe.monto_ambiental,
            monto_total: informe.monto_total,
            m1s6_completa: informe.m1s6_completa,

            // modulo2.seccion1
            id_tipo_clima: informe.id_tipo_clima,
            precipitacion_anual: informe.precipitacion_anual,
            temperatura_anual: informe.temperatura_anual,
            evapotranspiracion_anual: informe.evapotranspiracion_anual,
            humedad_relativa_anual: informe.humedad_relativa_anual,
            informe_estacion_precipitacion: data_estacion_precipitacion,
            informe_medio_hg_superficial: data_medio_hg_superficial,
            informe_medio_hg_subterraneo: data_medio_hg_subterraneo,
            informe_potencial_hidrico_superficial: data_potencial_hidrico_superficial,
            informe_potencial_hidrico_subterraneo: data_potencial_hidrico_subterraneo,
            informe_alternativas_fuente: data_alternativas_fuente,
            informe_alternativas_fuente_calidad: data_alternativas_fuente_calidad,
            m2s1_completa: informe.m2s1_completa,

            // modulo2.seccion2
            dentro_area_protegida: informe.dentro_area_protegida,
            id_area_protegida: informe.id_area_protegida,
            informe_usos_agua: data_usos_agua,
            id_tipo_cobertura_vegetal_recarga: informe.id_tipo_cobertura_vegetal_recarga,
            pendiente_recarga: informe.pendiente_recarga,
            id_tipo_suelo: informe.id_tipo_suelo,
            vulnerabilidad: informe.vulnerabilidad,
            id_tipo_obertura_vegetal_fuente: informe.id_tipo_obertura_vegetal_fuente,
            pendiente_fuente: informe.pendiente_fuente,
            requisitos_ambientales: informe.requisitos_ambientales,
            informe_fuentes_contaminacion: data_fuentes_contaminacion,
            informe_fuentes_amenazas: data_fuentes_amenazas,
            m2s2_completa: informe.m2s2_completa,

            // modulo3.seccion1
            objetivo_central: informe.objetivo_central,
            objetivos_especificos: informe.objetivos_especificos,
            proposito: informe.proposito,
            informe_objetivos_medios: data_objetivos_medios,
            informe_objetivos_metas: data_objetivos_metas,
            m3s1_completa: informe.m3s1_completa,

            m3s2_completa: informe.m3s2_completa,
            m6s1_completa: informe.m6s1_completa,
            participacion_ciclo: informe.participacion_ciclo,
            experiencia: informe.experiencia,
            participacion_mujer: informe.participacion_mujer,
            m7s1_completa: informe.m7s1_completa,

            changed: informe.changed,

        };
        return this._http.put(this.url + 'informe/' + data.id, data, { headers: headers });
    }
    get(token, id: number): Observable<any> {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', 'Bearer ' + token);
        return this._http.get(this.url + 'informe/' + id, { headers: headers });
    }
    delete(token, id: number) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', 'Bearer ' + token);
        return this._http.delete(this.url + 'informe/' + id, { headers: headers });
    }



}
