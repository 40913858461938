import { TipoSistema } from '../catalogos/tipo_sistema';
import { TipoTratamiento } from '../catalogos/tipo_tratamiento';
import { TipoFuenteAgua } from '../catalogos/tipo_fuente_agua';

export class InformeAlternativasFuente {
    public id_informe = 0;
    public fuente = '';
    public tipo_fuente: TipoFuenteAgua = null;
    public coordenada_zona = '';
    public coordenada_x = 0;
    public coordenada_y = 0;
    public coordenada_z = 0;
    public caudal = 0;
    public tipo_sistema: TipoSistema = null;
    public tipo_tratamiento: TipoTratamiento = null;
    public estado_legal = '';
}
