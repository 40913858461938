import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, DoCheck } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { InformeN } from 'src/app/models/Informe/informe';
import { InformeOrganizacion } from 'src/app/models/informe/informe_organizacion';
import { UserService } from 'src/app/services/seguridad/user.service';
import { InformeActividadFortalecimiento } from 'src/app/models/informe/informe_actividad_fortalecimiento';
import { InformeActividadEconomica } from 'src/app/models/Informe/informe_actividad_economica';
import { InformeServicioExistente } from 'src/app/models/Informe/informe_servicio_existente';
import { InformeViasAcceso } from 'src/app/models/informe/informe_vias_acceso';

import { DepartamentoService } from 'src/app/services/catalogos/departamento.service';
import { MunicipioService } from 'src/app/services/catalogos/municipio.service';
import { ComunidadService } from 'src/app/services/catalogos/comunidad.service';
import { Departamento } from 'src/app/models/catalogos/departamento';
import { Municipio } from 'src/app/models/catalogos/municipio';
import { Comunidad } from 'src/app/models/catalogos/comunidad';
import { TipoPoblacionService } from 'src/app/services/catalogos/tipo_poblacion.service';
import { TipoPoblacion } from 'src/app/models/catalogos/tipo_poblacion';

import { CategorizacionNivelService } from 'src/app/services/catalogos/categorizacion_nivel.service';
import { CategorizacionNivel } from 'src/app/models/catalogos/cateagorizacion_nivel';
import { CategorizacionTipoSaneamientoService } from 'src/app/services/catalogos/categorizacion_tipo_saneamiento.service';
import { CategorizacionTipoSaneamiento } from 'src/app/models/catalogos/categorizacion_tipo_saneamiento';
import { CategorizacionTipoIntervencionService } from 'src/app/services/catalogos/categorizacion_tipo_intervencion.service';
import { CategorizacionTipoIntervencion } from 'src/app/models/catalogos/categorizacion_tipo_intervencion';
import { ModalidadService } from 'src/app/services/catalogos/modalidad.service';
import { Modalidad } from 'src/app/models/catalogos/modalidad';
import { TipoOrganizacion } from 'src/app/models/catalogos/tipo_organizacion';
import { TipoOrganizacionService } from 'src/app/services/catalogos/tipo_organizacion.service';
import { ActividadEconomicaService } from 'src/app/services/catalogos/actividad_economica.service';
import { ActividadEconomica } from 'src/app/models/catalogos/actividad_economica';
import { TipoServicio } from 'src/app/models/catalogos/tipo_servicio';
import { TipoServicioService } from 'src/app/services/catalogos/tipos_servicio.service';
import { TipoRevestimientoService } from 'src/app/services/catalogos/tipo_revestimiento.service';
import { TipoRevestimiento } from 'src/app/models/catalogos/tipo_revestimiento';
import { InformeService } from 'src/app/services/informe.service';
import { ConcesionarioService } from 'src/app/services/catalogos/concesionario.service';
import { Concesionario } from 'src/app/models/catalogos/concesionario';
import { TipoLineaService } from 'src/app/services/catalogos/tipo_linea.service';
import { TipoLinea } from 'src/app/models/catalogos/tipo_linea';
import { TipoFase } from 'src/app/models/catalogos/tipo_fase';
import { TipoFaseService } from 'src/app/services/catalogos/tipo_fase.service';
import { TipoRelieve } from 'src/app/models/catalogos/tipo_relieve';
import { TipoRelieveService } from 'src/app/services/catalogos/tipo_relieve.service';
import { InformeGeotecnicaSuelo } from 'src/app/models/informe/informe_geotecnica_suelo';
import { GeotectonicaSueloService } from 'src/app/services/catalogos/geotectonica_suelo.service';
import { GeotecnicaSuelo } from 'src/app/models/catalogos/geotecnica_suelo';
import { TipoFuenteAguaService } from 'src/app/services/catalogos/tipo_fuente_agua.service';
import { TipoFuenteAgua } from 'src/app/models/catalogos/tipo_fuente_agua';
import { ParametroCalidadService } from 'src/app/services/catalogos/parametro_calidad.service';
import { ParametroCalidad } from 'src/app/models/catalogos/parametro_calidad';
import { InformeInventarioParametrosCalidad } from 'src/app/models/informe/informe_inventario_parametros_calidad';
import { InformeBancoMaterial } from 'src/app/models/informe/informe_banco_material';
import { TipoMaterialService } from 'src/app/services/catalogos/tipo_material.service';
import { TipoMaterial } from 'src/app/models/catalogos/tipo_material';
import { InformeInstalacionesAgua } from 'src/app/models/informe/informe_instalaciones_agua';
import { TipoInstalacionAgua } from 'src/app/models/catalogos/tipo_instalacion_agua';
import { TipoInstalacionAguaService } from 'src/app/services/catalogos/tipo_instalacion_agua.service';
import { TipoRedService } from 'src/app/services/catalogos/tipo_red.service';
import { InformeDistribucionesRedes } from 'src/app/models/informe/informe_distribuciones_redes';
import { TipoRed } from 'src/app/models/catalogos/tipo_red';
import { InformeNivelServicio } from 'src/app/models/informe/informe_nivel_servicio';
import { NivelServicio } from 'src/app/models/catalogos/nivel_servicio';
import { NivelServicioService } from 'src/app/services/catalogos/nivel_servicio.service';
import { TipoTratamientoService } from 'src/app/services/catalogos/tipo_tratamiento.service';
import { TipoTratamiento } from 'src/app/models/catalogos/tipo_tratamiento';
import { InformeInstalacionesSaneamiento } from 'src/app/models/informe/informe_instalaciones_saneamiento';
import { TipoInstalacionSaneamientoService } from 'src/app/services/catalogos/tipo_instalacion_saneamiento.service';
import { TipoInstalacionSaneamiento } from 'src/app/models/catalogos/tipo_instalacion_saneamiento';
import { InformePdNivelServicio } from 'src/app/models/informe/informe_pd_nivel_servicio';
import { TipoClimaService } from 'src/app/services/catalogos/tipo_clima.service';
import { TipoClima } from 'src/app/models/catalogos/tipo_clima';
import { InformeMedioHgSuperficial } from 'src/app/models/informe/informe_medio_hg_superficial';
import { TipoAcuiferoService } from 'src/app/services/catalogos/tipo_acuifero.service';
import { TipoAcuifero } from 'src/app/models/catalogos/tipo_acuifero';
import { InformeMedioHgSubterraneo } from 'src/app/models/informe/informe_medio_hg_subterraneo';
import { InformeEstacionPrecipitacion } from 'src/app/models/informe/informe_estacion_precipitacion';
import { TipoObraService } from 'src/app/services/catalogos/tipo_obra.service';
import { TipoObra } from 'src/app/models/catalogos/tipo_obra';
import { InformeInventarioFuenteSuperficial } from 'src/app/models/informe/informe_inventario_fuente_superficial';
import { MetodoAforoService } from 'src/app/services/catalogos/metodo_aforo.service';
import { MetodoAforo } from 'src/app/models/catalogos/metodo_aforo';
import { TipoSistema } from 'src/app/models/catalogos/tipo_sistema';
import { TipoSistemaService } from 'src/app/services/catalogos/tipos_sistema.service';
import { InformeInventarioFuenteSubterranea } from 'src/app/models/informe/informe_inventario_fuente_subterranea';
import { TipoBombaService } from 'src/app/services/catalogos/tipo_bomba.service';
import { TipoBomba } from 'src/app/models/catalogos/tipo_bomba';
import { InformePotencialHidricoSuperficial } from 'src/app/models/Informe/informe_potencial_hidrico_superficial';
import { InformePotencialHidricoSubterraneo } from 'src/app/models/informe/informe_potencial_hidrico_subterraneo';
import { InformeCorreos } from 'src/app/models/informe/informe_correos';
import { InformeTelefonos } from 'src/app/models/Informe/informe_telefonos';
import { InformeComunidades } from 'src/app/models/Informe/informe_comunidades';
import { Etnia } from 'src/app/models/catalogos/etnia';
import { EtniaService } from 'src/app/services/catalogos/etnia.service';
import { InformeDistancias } from 'src/app/models/Informe/informe_distancias';
import { InformeInstalacionesTratamiento } from 'src/app/models/Informe/informe_instalaciones_tratamiento';
import { InformeAlternativasFuente } from 'src/app/models/Informe/informe_alternativas_fuente';
import { AreaProtegida } from 'src/app/models/catalogos/area_protegida';
import { AreaProtegidaService } from 'src/app/services/catalogos/area_protegida.service';
import { TipoCoberturaVegetal } from 'src/app/models/catalogos/tipo_cobertura_vegetal';
import { TipoCoberturaVegetalService } from 'src/app/services/catalogos/tipo_cobertura_vegetal.service';
import { TipoSuelo } from 'src/app/models/catalogos/tipo_suelo';
import { TipoSueloService } from 'src/app/services/catalogos/tipo_suelo.service';
import { UsosAgua } from 'src/app/models/catalogos/usos_agua';
import { UsosAguaService } from 'src/app/services/catalogos/usos_agua.service';
import { InformeUsosAgua } from 'src/app/models/Informe/informe_usos_agua';
import { TipoContaminacion } from 'src/app/models/catalogos/tipo_contaminacion';
import { InformeFuentesContaminacion } from 'src/app/models/Informe/informe_fuentes_contaminacion';
import { TipoContaminacionService } from 'src/app/services/catalogos/tipo_contaminacion.service';
import { InformeFuentesAmenazas } from 'src/app/models/Informe/informe_fuentes_amenazas';
import { TipoAmenazas } from 'src/app/models/catalogos/tipo_amenazas';
import { TipoAmenazaService } from 'src/app/services/catalogos/tipo_amenaza.service';
import { InformeMedidasAdaptacion } from 'src/app/models/Informe/informe_medidas_adaptacion';
import { TipoMedidaAdaptacion } from 'src/app/models/catalogos/tipo_medida_adaptacion';
import { TipoMedidaAdaptacionService } from 'src/app/services/catalogos/tipo_medida_adaptacion.service';
import { InformeObjetivosMedios } from 'src/app/models/Informe/informe_objetivos_medios';
import { InformeObjetivosMetas } from 'src/app/models/Informe/informe_objetivos_metas';
import { TipoInstalacionHigieneService } from 'src/app/services/catalogos/tipo_instalacion_higiene_service';
import { TipoInstalacionHigiene } from 'src/app/models/catalogos/tipo_instalacion_higiene';
import { InformeInstalacionesHigiene } from 'src/app/models/Informe/informe_instalaciones_higiene';
import { TipoMediosTransporteService } from 'src/app/services/catalogos/tipo_medios_transporte.service';
import { TipoMediosTransporte } from 'src/app/models/catalogos/tipo_medios_transporte';
import { FileUploadService } from 'src/app/services/file_upload.service';
import { global } from 'src/app/services/global';
// import { formArrayNameProvider } from '@angular/forms/src/directives/reactive_directives/form_group_name';
import { InformeInventarioAnalisisPreliminar } from 'src/app/models/Informe/informe_inventario_analisis_preliminar';
import { InformeInventarioParametroOrganolepticos } from 'src/app/models/Informe/informe_inventario_parametros_organolepticos';
import { InformeInventarioParametrosFisicoQuimicos } from 'src/app/models/Informe/informe_inventario_parametros_fisico_quimicios';
import { InformeInventarioParametrosIndeseables } from 'src/app/models/Informe/informe_inventario_parametros_indeseables';
import { InformeInventarioParametrosMetales } from 'src/app/models/Informe/informe_inventario_parametros_metales';
import { InformeInventarioParametrosBacteriologicos } from 'src/app/models/Informe/informe_inventario_parametros_bacteriologicos';
import { InformeInventarioPropuestaAlternativa } from 'src/app/models/Informe/informe_inventario_propuesta_alternativa';
import { EstadoLegalFuenteService } from 'src/app/services/catalogos/estado_legal_fuente.service';
import { EstadoLegalFuente } from 'src/app/models/catalogos/estado_legal_fuente';
import { InformeInventarioCorberturaForestal } from 'src/app/models/Informe/informe_inventario_cobertura_forestal';
import { InformeActividadAgricolaProyecto } from 'src/app/models/Informe/infome_actividad_agricola_proyecto';
import { InformeControlPlagas } from 'src/app/models/Informe/informe_control_plagas';
import { InformeFertilizantes } from 'src/app/models/Informe/informe_fertilizantes';


@Component({
  selector: 'app-informe-view',
  templateUrl: './informe-view.component.html',
  styleUrls: ['./informe-view.component.css'],
  providers: [
    ToastrService,
    UserService,
    InformeService,
    DepartamentoService,
    MunicipioService,
    ComunidadService,
    TipoPoblacionService,
    CategorizacionNivelService,
    CategorizacionTipoSaneamientoService,
    CategorizacionTipoIntervencionService,
    ModalidadService,
    TipoOrganizacionService,
    ActividadEconomicaService,
    TipoServicioService,
    TipoRevestimientoService,
    TipoMediosTransporteService,
    ConcesionarioService,
    TipoLineaService,
    TipoFaseService,
    TipoRelieveService,
    GeotectonicaSueloService,
    TipoFuenteAguaService,
    ParametroCalidadService,
    TipoMaterialService,
    TipoInstalacionAguaService,
    TipoRedService,
    NivelServicioService,
    TipoTratamientoService,
    TipoInstalacionSaneamientoService,
    TipoInstalacionHigieneService,
    TipoClimaService,
    TipoAcuiferoService,
    TipoObraService,
    MetodoAforoService,
    TipoSistemaService,
    TipoBombaService,
    EtniaService,
    AreaProtegidaService,
    TipoCoberturaVegetalService,
    TipoSueloService,
    UsosAguaService,
    TipoContaminacionService,
    TipoAmenazaService,
    TipoMedidaAdaptacionService,
    FileUploadService,
    TipoMediosTransporte,
    EstadoLegalFuenteService,
  ],
})
export class InformeViewComponent implements OnInit, DoCheck {

  public _last_save = 'Sin guardar';
  public _estado = 'Borrador';
  public _url = '';
  public _api_url: string;
  public dist_total = 0;
  public cantidad = 0;
  public identity;
  public token;
  public informe = new InformeN(1);
  public file_ubicacion_imagen: File;
  public img_ubicacion_imagen: any;
  public file_ubicacion_micro: File;
  public img_ubicacion_micro: any;
  public file_bancos_materiales_imagen: File;
  public img_bancos_materiales_imagen: any;
  public file_sin_proyecto_imagen: File;
  public img_sin_proyecto_imagen: any;
  public file_ubicacion_bd: File;
  public img_ubicacion_bd: any;
  public file_ubicacion_infome_bd: File;
  public img_ubicacion_infome_bd: any;
  public file_ubicacion_doc_revisado: File;
  public img_ubicacion_doc_revisado: any;
  public file_ubicacion_fichahidri: File;
  public img_ubicacion_fichahidri: any;
  public file_ubicacion_img_energia: File;
  public img_ubicacion_img_energia: any;
  public file_cobertura_forestal_imagen: File;
  public img_cobertura_forestal_imagen: any;
  public file_ubicacion_fich_tecnica: File;
  public img_ubicacion_fich_tecnica: any;
  public file_ubicacion_terreno: File;
  public img_ubicacion_terreno: any;

  public _categoria_pobreza = '';
  public _infome_actividad_agricola_proyecto = new InformeActividadAgricolaProyecto;
  public _informe_control_plagas = new InformeControlPlagas;
  public _informe_fertilizantes = new InformeFertilizantes;
  public _informe_correo = new InformeCorreos;
  public _informe_telefono = new InformeTelefonos;
  public _informe_comunidades = new InformeComunidades;
  public _informe_distancias = new InformeDistancias;
  public _informe_vias_acceso = new InformeViasAcceso;
  public _informe_actividad_fortalecimiento = new InformeActividadFortalecimiento;
  public _informe_actividad_economica = new InformeActividadEconomica;
  public _informe_servicio_existente = new InformeServicioExistente;
  public _informe_organizacion = new InformeOrganizacion;
  public _informe_geotecnica_suelo = new InformeGeotecnicaSuelo;
  public _informe_banco_material = new InformeBancoMaterial;
  public _informe_instalacion_agua = new InformeInstalacionesAgua;
  public _informe_distribucion_red = new InformeDistribucionesRedes;
  public _informe_nivel_servicio = new InformeNivelServicio;
  public _informe_instalacion_saneamiento = new InformeInstalacionesSaneamiento;
  public _informe_instalacion_higiene = new InformeInstalacionesHigiene;
  public _informe_instalacion_tratamiento = new InformeInstalacionesTratamiento;
  public _informe_pd_nivel_servicio = new InformePdNivelServicio;
  public _informe_estacion_precipitacion = new InformeEstacionPrecipitacion;
  public _informe_medio_hg_superficial = new InformeMedioHgSuperficial;
  public _informe_medio_hg_subterraneo = new InformeMedioHgSubterraneo;
  public _informe_inventario_cobertura_forestal = new InformeInventarioCorberturaForestal;
  public _informe_inventario_fuente_superficial = new InformeInventarioFuenteSuperficial;
  public _informe_inventario_fuente_subterranea = new InformeInventarioFuenteSubterranea;
  public _informe_inventario_analisis_preliminar = new InformeInventarioAnalisisPreliminar;
  public _informe_inventario_parametros_organolepticos = new InformeInventarioParametroOrganolepticos;
  public _informe_inventario_parametros_fisico_quimicios = new InformeInventarioParametrosFisicoQuimicos;
  public _informe_inventario_parametros_indeseables = new InformeInventarioParametrosIndeseables;
  public _informe_inventario_parametros_metales = new InformeInventarioParametrosMetales;
  public _informe_inventario_parametros_bacteriologicos = new InformeInventarioParametrosBacteriologicos;
  public _informe_inventario_propuesta_alternativa = new InformeInventarioPropuestaAlternativa;
  public _informe_inventario_parametro_calidad = new InformeInventarioParametrosCalidad;
  public _informe_potencial_hidrico_superfical = new InformePotencialHidricoSuperficial;
  public _informe_potencial_hidrico_subterraneo = new InformePotencialHidricoSubterraneo;
  public _informe_alternativas_fuente = new InformeAlternativasFuente;
  public _informe_usos_agua = new InformeUsosAgua;
  public _informe_fuentes_contaminacion = new InformeFuentesContaminacion;
  public _informe_fuentes_amenazas = new InformeFuentesAmenazas;
  public _informe_medida_adaptacion = new InformeMedidasAdaptacion;
  public _informe_objetivos_medios = new InformeObjetivosMedios;
  public _informe_objetivos_metas = new InformeObjetivosMetas;

  public _departamentos: [Departamento] = <any>[];
  public _municipios: [Municipio] = <any>[];
  public _comunidades: [Comunidad] = <any>[];
  public _tipos_poblacion: [TipoPoblacion] = <any>[];
  public _categorizacion_niveles: [CategorizacionNivel] = <any>[];
  public _categorizacion_tipos_saneamiento: [CategorizacionTipoSaneamiento] = <any>[];
  public _categorizacion_tipos_intervencion: [CategorizacionTipoIntervencion] = <any>[];
  public _modalidades: [Modalidad] = <any>[];
  public _etnias: [Etnia] = <any>[];
  public _estado_legal_fuente: [EstadoLegalFuente] = <any>[];
  public _tipos_organizacion: [TipoOrganizacion] = <any>[];
  public _actividades_economicas: [ActividadEconomica] = <any>[];
  public _tipos_servicio: [TipoServicio] = <any>[];
  public _tipos_revestimiento: [TipoRevestimiento] = <any>[];
  public _tipos_medios_transporte: [TipoMediosTransporte] = <any>[];
  public _concesionarios: [Concesionario] = <any>[];
  public _tipos_linea: [TipoLinea] = <any>[];
  public _tipos_fase: [TipoFase] = <any>[];
  public _tipos_relieve: [TipoRelieve] = <any>[];
  public _geotecnicas_suelo: [GeotecnicaSuelo] = <any>[];
  public _tipos_fuente_agua: [TipoFuenteAgua] = <any>[];
  public _parametros_calidad: [ParametroCalidad] = <any>[];
  public _tipos_material: [TipoMaterial] = <any>[];
  public _tipos_instalacion_agua: [TipoInstalacionAgua] = <any>[];
  public _tipos_red: [TipoRed] = <any>[];
  public _niveles_servicio: [NivelServicio] = <any>[];
  public _tipos_tratamiento: [TipoTratamiento] = <any>[];
  public _tipos_instalacion_sanemiento: [TipoInstalacionSaneamiento] = <any>[];
  public _tipos_instalacion_higiene: [TipoInstalacionHigiene] = <any>[];
  public _tipos_clima: [TipoClima] = <any>[];
  public _tipos_acuifero: [TipoAcuifero] = <any>[];
  public _tipos_obra: [TipoObra] = <any>[];
  public _metodos_aforo: [MetodoAforo] = <any>[];
  public _tipos_sistema: [TipoSistema] = <any>[];
  public _tipos_bomba: [TipoBomba] = <any>[];
  public _areas_protegidas: [AreaProtegida] = <any>[];
  public _tipos_cobertura_vegetal: [TipoCoberturaVegetal] = <any>[];
  public _tipos_suelo: [TipoSuelo] = <any>[];
  public _usos_agua: [UsosAgua] = <any>[];
  public _tipos_contaminacion: [TipoContaminacion] = <any>[];
  public _tipos_amenazas: [TipoAmenazas] = <any>[];
  public _tipos_medidas_adaptacion = <any>[];

  constructor(
    private _toastr: ToastrService,
    private _user_service: UserService,
    private _informe_service: InformeService,
    private _departamento_service: DepartamentoService,
    private _municipio_service: MunicipioService,
    private _comunidad_service: ComunidadService,
    private _tipo_poblacion_service: TipoPoblacionService,
    private _categorizacion_nivel_service: CategorizacionNivelService,
    private _categorizacion_tipo_saneamiento: CategorizacionTipoSaneamientoService,
    private _categorizacion_tipo_intervencion: CategorizacionTipoIntervencionService,
    private _modalidad_service: ModalidadService,
    private _tipo_organizacion_service: TipoOrganizacionService,
    private _actividad_economica_service: ActividadEconomicaService,
    private _tipo_servicio_service: TipoServicioService,
    private _tipo_revestimiento_service: TipoRevestimientoService,
    private _tipo_medio_transporte_service: TipoMediosTransporteService,
    private _concesionario_service: ConcesionarioService,
    private _tipo_linea_service: TipoLineaService,
    private _tipo_fase_service: TipoFaseService,
    private _tipo_relieve_service: TipoRelieveService,
    private _geotectonica_suelo_service: GeotectonicaSueloService,
    private _tipo_fuente_agua_service: TipoFuenteAguaService,
    private _parametro_calidad_service: ParametroCalidadService,
    private _tipo_materialservice: TipoMaterialService,
    private _tipo_instalacion_agua_service: TipoInstalacionAguaService,
    private _tipo_red_service: TipoRedService,
    private _nivel_servicio_service: NivelServicioService,
    private _tipo_tratamiento_service: TipoTratamientoService,
    private _tipo_instalacion_saneamiento_service: TipoInstalacionSaneamientoService,
    private _tipo_instalacion_higiene_service: TipoInstalacionHigieneService,
    private _tipo_clima_service: TipoClimaService,
    private _tipo_acuifero_service: TipoAcuiferoService,
    private _tipo_obra_service: TipoObraService,
    private _metodo_aforo_service: MetodoAforoService,
    private _tipo_sistema_service: TipoSistemaService,
    private _tipo_bomba_service: TipoBombaService,
    private _etnia_service: EtniaService,
    private _estado_legal_fuente_service: EstadoLegalFuenteService,
    private _area_protegida_service: AreaProtegidaService,
    private _tipo_cobertura_vegetal_service: TipoCoberturaVegetalService,
    private _tipo_suelo_service: TipoSueloService,
    private _usos_agua_service: UsosAguaService,
    private _tipo_contaminacion_service: TipoContaminacionService,
    private _tipo_amenaza_service: TipoAmenazaService,
    private _tipo_medida_adaptacion: TipoMedidaAdaptacionService,
    private _file_upload_service: FileUploadService,
    private _route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.identity = this._user_service.getIdentity();
    this.token = this._user_service.getToken();
    this.informe.id_usuario = this.identity.id;

    this.load_catalogos();
    this.load_informe(this._route.snapshot.paramMap.get('id'));
    // Seccion 2
    this.img_ubicacion_imagen = this.informe.ubicacion_imagen;
    this.img_ubicacion_micro = this.informe.ubicacion_micro;
    // Seccion 4
    this.img_ubicacion_infome_bd = this.informe.ubicacion_infome_bd; // Seccion 4
    this.img_ubicacion_bd = this.informe.ubicacion_bd;
    // Seccion 5.1
    this.img_ubicacion_doc_revisado = this.informe.ubicacion_doc_revisado;
    // Seccion 5.2
    this.img_ubicacion_fichahidri = this.informe.ubicacion_fichahidri;
    // seccion 5.3
    this.img_cobertura_forestal_imagen = this.informe.cobertura_forestal_imagen;
    this.img_bancos_materiales_imagen = this.informe.bancos_materiales_imagen;
    // Seccion 5.4
    this.img_ubicacion_img_energia = this.informe.ubicacion_img_energia;
    this.img_ubicacion_fich_tecnica = this.informe.ubicacion_fich_tecnica;
    // Seccion 5.5
    this.img_ubicacion_terreno = this.informe.ubicacion_terreno_img;
    // Seccion 5.6
    this.img_sin_proyecto_imagen = this.informe.sin_proyecto_imagen;

    this._url = 'informeView/' + this._route.snapshot.paramMap.get('id');
  }

  async ngDoCheck() {
    if (this.informe.m1s1_completa && this.informe.id === 0) {
      this.save();
    } else {
      this.informe.check_m1e_completa();
      this.informe.check_m2_completa();
      this.informe.check_m3_completa();
      this.estado_change();
    }
  }
  //#region de funtion
  /*get_distanciatotal()
  { if (this._informe_vias_acceso !== null)
         this.dist_total= + this._informe_vias_acceso.distancia;
     else
     this.dist_total = 0;
  }*/


  //#region load catalogs()
  load_informe(id) {
    this._informe_service.get(this.token, id).subscribe(
      response => {

        const _informe = response;
        // Sección 1 Modulo 1
        this.informe.id = _informe.id;
        this.informe.id_usuario = _informe.id_usuario;
        this.informe.estado_informe = _informe.estado_informe;
        this.informe.codigo = _informe.codigo;
        this.informe.solicitante = _informe.solicitante;
        this.informe.solicitante_autoridad = _informe.solicitante_autoridad;
        this.informe.solicitante_direccion = _informe.solicitante_direccion;
        this.informe.solicitante_director = _informe.solicitante_director;
        this.informe.solicitante_umas = _informe.solicitante_umas;
        this.informe.informe_correos = _informe.informe_correos;
        // this.informe.informe_telefonos = _informe.informe_telefonos;
        this.informe.fecha_elaboracion = _informe.fecha_elaboracion;
        this.informe.m1s1_completa = _informe.m1s1_completa;
        // Sección 2 Modulo 1
        this.informe.id_departamento = _informe.id_departamento;
        this.informe.id_municipio = _informe.id_municipio;
        this.informe.categoria_pobreza = _informe.municipio.categoria_pobreza;
        this._categoria_pobreza = this.informe.categoria_pobreza.descripcion;
        this.informe.informe_comunidades = _informe.informe_comunidades;
        this.informe.informe_distancias = _informe.informe_distancias;
        this.informe.informe_vias_acceso = _informe.informe_vias_acceso;
        this.informe.id_categoria_pobreza = _informe.id_categoria_pobreza;
        this.informe.ubicacion_imagen = _informe.ubicacion_imagen;
        this.informe.ubicacion_micro = _informe.ubicacion_micro;
        this.informe.m1s2_completa = _informe.m1s2_completa;
        // Sección 3 Modulo 1
        this.informe.problema = _informe.problema;
        this.informe.causas = _informe.causas;
        this.informe.m1s3_completa = _informe.m1s3_completa;

        // Sección 4 Modulo 1
        this.informe.poblacion_mujeres = _informe.poblacion_mujeres;
        this.informe.poblacion_hombres = _informe.poblacion_hombres;
        this.informe.poblacion_total = _informe.poblacion_total;
        this.informe.poblacion_ninnos = _informe.poblacion_ninnos;
        this.informe.poblacion_ninnas = _informe.poblacion_ninnas;
        this.informe.anyo_lineabase = _informe.anyo_lineabase;
        this.informe.familias = _informe.familias;
        this.informe.persona_diferentes = _informe.persona_diferentes;
        this.informe.familias_jefas = _informe.familias_jefas;
        this.informe.viviendas = _informe.viviendas;
        this.informe.ingreso = _informe.ingreso;
        this.informe.total_trabajadores = _informe.total_trabajadores;
        this.informe.informe_actividades_economicas = _informe.informe_actividades_economicas;
        this.informe.informe_servicios_existentes = _informe.informe_servicios_existentes;
        this.informe.ubicacion_bd = _informe.ubicacion_bd;
        this.informe.ubicacion_infome_bd = _informe.ubicacion_infome_bd;
        this.informe.m1s4_completa = _informe.m1s4_completa;

        /*-- |SECCION INFORMACION TECNICA DEL SITIO DE PROYECTO| --
  
          --| modulo1.seccion5.1 ESTIMACION DE LA DEMANDA |--
          -- Analisis de la demanda de la hoja electronica del ing. Yalmar Zamora --*/

        // Sección 5.1 Modulo 1
        // Dato del censo de población historico nacional
        this.informe.pd_poblacion_historica = _informe.pd_poblacion_historica;
        this.informe.anyo_censal = _informe.anyo_censal;

        // --| B) Proyeccion de poblacion |--
        this.informe.pd_tasa_crecimiento = _informe.pd_tasa_crecimiento;
        this.informe.pd_poblacion_actual = _informe.pd_poblacion_actual;
        this.informe.pd_anno_inicio = _informe.pd_anno_inicio;
        this.informe.pd_poblacion_inicial = _informe.pd_poblacion_inicial;
        this.informe.pd_poblacion_a_veinte = _informe.pd_poblacion_a_veinte;
        this.informe.pd_periodo_disenno = _informe.pd_periodo_disenno;


        // --|C)  Criterios de diseño a 20 años ||-- -------   
        this.informe.pd_dotacion_domiciliar = _informe.pd_dotacion_domiciliar;
        this.informe.pd_demanda_dotdomiciliar = _informe.pd_demanda_dotdomiciliar;


        this.informe.pd_dotacion_emergencia = _informe.pd_dotacion_emergencia;
        this.informe.pd_demanda_dotemergencia = _informe.pd_demanda_dotemergencia;
        // ----------------------------------------------------
        this.informe.existen_escuelas = _informe.existen_escuelas;
        this.informe.pd_dotacion_escuela = _informe.pd_dotacion_escuela;
        this.informe.cant_escuela = _informe.cant_escuela;
        this.informe.cant_alumno = _informe.cant_alumno;
        this.informe.pd_demanda_dotescuela = _informe.pd_demanda_dotescuela;
        // ------------------------------------------------------
        this.informe.existen_mercado = _informe.existen_mercado;
        this.informe.pd_dotacion_mercado = _informe.pd_dotacion_mercado;
        this.informe.pd_cant_mercado = _informe.pd_cant_mercado;
        this.informe.pd_demanda_mercado = _informe.pd_demanda_mercado;
        // _____________________________________________
        this.informe.existen_psalud = _informe.existen_psalud;
        this.informe.pd_dotacion_psalud = _informe.pd_dotacion_psalud;
        this.informe.pd_cantidad_psalud = _informe.pd_cantidad_psalud;
        this.informe.pd_demanda_dotpsalud = _informe.pd_demanda_dotpsalud;
        // ______________________________________________________________
        this.informe.existen_csaslud = _informe.existen_csaslud;
        this.informe.pd_dotacion_csalud = _informe.pd_dotacion_csalud;
        this.informe.pd_csalud = _informe.pd_csalud;
        this.informe.pd_cant_cama = _informe.pd_cant_cama;
        this.informe.pd_demanda_dotcsalud = _informe.pd_demanda_dotcsalud;
        // ---------------------------------------------------------
        this.informe.existe_mataderos = _informe.existe_mataderos;
        this.informe.pd_dotacion_mataderos = _informe.pd_dotacion_mataderos;
        this.informe.pd_cant_mataderos = _informe.pd_cant_mataderos;
        this.informe.pd_demanda_dotmataderos = _informe.pd_demanda_dotmataderos;
        // -----------------------------------------------------------------
        this.informe.existe_institucion = _informe.existe_institucion;
        this.informe.pd_dotacion_institucional = _informe.pd_dotacion_institucional;
        this.informe.pd_demanda_dotinstitucional = _informe.pd_demanda_dotinstitucional;
        // -------------------------------------------------------------------
        this.informe.existe_comercio = _informe.existe_comercio;
        this.informe.pd_dotacion_comercio = _informe.pd_dotacion_comercio;
        this.informe.pd_demanda_dotcomercio = _informe.pd_demanda_dotcomercio;
        // ------------------------------------------------------------
        this.informe.existe_industria = _informe.existe_industria;
        this.informe.pd_dotacion_industria = _informe.pd_dotacion_industria;
        this.informe.pd_demanda_dotindustria = _informe.pd_demanda_dotindustria;

        this.informe.pd_dotacion_extradomiciliar = _informe.pd_dotacion_extradomiciliar;

        this.informe.pd_demanda_dfinal = _informe.pd_demanda_dfinal;


        // --| C) Caudales de diseño al final de período|
        this.informe.pd_caudal_medio = _informe.pd_caudal_medio;
        this.informe.pd_caudal_conduccion = _informe.pd_caudal_conduccion;
        this.informe.pd_caudal_distribucion = _informe.pd_caudal_distribucion;
        this.informe.pd_volumen_almacenamiento = _informe.pd_volumen_almacenamiento;
        this.informe.ubicacion_doc_revisado = _informe.ubicacion_doc_revisado;
        this.informe.m1s5_1_completa = _informe.m1s5_1_completa;

        // --| modulo1.SECCION 5.2 INVENTARIO DE RECURSO HIDRICO |-- 
        // Sección 5.2 Modulo 1
        this.informe.existen_f_superficial = _informe.existen_f_superficial;
        this.informe.informe_inventario_fuente_superficial = _informe.informe_inventario_fuente_superficial;
        this.informe.existen_f_subterranea = _informe.existen_f_subterranea;
        this.informe.informe_inventario_fuente_subterranea = _informe.informe_inventario_fuente_subterranea;
        this.informe.informe_inventario_analisis_preliminar = _informe.informe_inventario_analisis_preliminar;
        this.informe.existen_p_organolepticos = _informe.existen_p_organolepticos;
        this.informe.informe_inventario_parametros_organolepticos = _informe.informe_inventario_parametros_organolepticos;
        this.informe.existen_p_fisicos_quimicios = _informe.existen_p_fisicos_quimicios;
        this.informe.informe_inventario_parametros_fisico_quimicios = _informe.informe_inventario_parametros_fisico_quimicios;
        this.informe.existen_s_indeseables = _informe.existen_s_indeseables;
        this.informe.informe_inventario_parametros_indeseables = _informe.informe_inventario_parametros_indeseables;
        this.informe.existen_p_metales = _informe.existen_p_metales;
        this.informe.informe_inventario_parametros_metales = _informe.informe_inventario_parametros_metales;
        this.informe.existen_p_bacteriologicos = _informe.existen_p_bacteriologicos;
        this.informe.informe_inventario_parametros_bacteriologicos = _informe.informe_inventario_parametros_bacteriologicos;

        this.informe.informe_inventario_propuesta_alternativa = _informe.informe_inventario_propuesta_alternativa;
        this.informe.requiere_estudio_complementario = _informe.requiere_estudio_complementario;
        this.informe.requiere_estudio_geofisico = _informe.requiere_estudio_geofisico;
        this.informe.estudio_geofisico_cantidad = _informe.estudio_geofisico_cantidad;
        this.informe.estudio_geofisico_perfiles = _informe.estudio_geofisico_perfiles;
        this.informe.estudio_geofisico_longitud = _informe.estudio_geofisico_longitud;
        this.informe.estudio_geofisico_profundidad = _informe.estudio_geofisico_profundidad;
        this.informe.requiere_prueba_bombeo = _informe.requiere_prueba_bombeo;
        this.informe.prueba_bombeo_cantidad_escalonada = _informe.prueba_bombeo_cantidad_escalonada;
        this.informe.prueba_bombeo_cantidad_constante = _informe.prueba_bombeo_cantidad_constante;
        this.informe.prueba_bombeo_duracion = _informe.prueba_bombeo_duracion;
        this.informe.requiere_otras_pruebas = _informe.requiere_otras_pruebas;
        this.informe.requiere_transmisividad = _informe.requiere_transmisividad;
        this.informe.requiere_permeabilidad = _informe.requiere_permeabilidad;
        this.informe.requiere_piezometria = _informe.requiere_piezometria;
        this.informe.profundidad_nf_invierno = _informe.profundidad_nf_invierno;
        this.informe.profundidad_nf_verano = _informe.profundidad_nf_verano;
        this.informe.ubicacion_fichahidri = _informe.ubicacion_fichahidri;
        this.informe.m1s5_2_completa = _informe.m1s5_2_completa;

        // Sección 5.3 Modulo 1
        // --| modulo1.SECCION 5.3 AMBIENTAL |--
        // -- | seccion de preguntas |--
        this.informe.descripcion_area_protegida = _informe.descripcion_area_protegida;
        this.informe.existe_embalses = _informe.existe_embalses;
        this.informe.animales_reportados = _informe.animales_reportados;
        // -- seccion Principales usos de suelo en el área de influencia
        this.informe.bosque = _informe.bosque;
        this.informe.platancion_forestal = _informe.platancion_forestal;
        this.informe.pastos = _informe.pastos;
        this.informe.cultivos = _informe.cultivos;
        this.informe.tacotal = _informe.tacotal;
        this.informe.sin_vegetacion = _informe.sin_vegetacion;
        this.informe.cultivo_perma = _informe.cultivo_perma;
        this.informe.humedales = _informe.humedales;
        this.informe.otros = _informe.otros;
        this.informe.especificar = _informe.especificar;
        this.informe.informe_inventario_cobertura_forestal = _informe.informe_inventario_cobertura_forestal;
        this.informe.cobertura_forestal_imagen = _informe.cobertura_forestal_imagen;
        this.informe.nombres_arboles = _informe.nombres_arboles;
        this.informe.infome_actividad_agricola_proyecto = _informe.infome_actividad_agricola_proyecto;
        this.informe.informe_control_plagas = _informe.informe_control_plagas;
        this.informe.informe_fertilizantes = _informe.informe_fertilizantes;
        this.informe.beneficios = _informe.beneficios;
        this.informe.fosiles = _informe.fosiles;
        // Seccion Banco materiales.
        this.informe.existe_banco_materiales = _informe.existe_banco_materiales;
        this.informe.informe_bancos_materiales = _informe.informe_bancos_materiales;
        this.informe.bancos_materiales_imagen = _informe.bancos_materiales_imagen;
        this.informe.bancos_autorizados = _informe.bancos_autorizados;
        this.informe.bancos_materiales_indigenas = _informe.bancos_materiales_indigenas;
        this.informe.botadero_existe = _informe.botadero_existe;
        this.informe.botadero_en_uso = _informe.botadero_en_uso;
        this.informe.botadero_distancia = _informe.botadero_distancia;
        this.informe.botadero_coordenada_zona = _informe.botadero_coordenada_zona;
        this.informe.botadero_coordenada_x = _informe.botadero_coordenada_x;
        this.informe.botadero_coordenada_y = _informe.botadero_coordenada_y;
        this.informe.botadero_coordenada_z = _informe.botadero_coordenada_z;

        this.informe.m1s5_3_completa = _informe.m1s5_3_completa;

        // --| modulo1.seccion5.4  COMPONENTE ELECTRICO|--
        // // Sección 5.4 Modulo 1
        this.informe.red_suministro_electrico = _informe.red_suministro_electrico;
        this.informe.id_concesionario = _informe.id_concesionario;
        this.informe.id_tipo_linea = _informe.id_tipo_linea;
        this.informe.id_tipo_fase = _informe.id_tipo_fase;
        this.informe.existe_banco_transformador = _informe.existe_banco_transformador;
        this.informe.capacidad_banco_transformador = _informe.capacidad_banco_transformador;
        this.informe.voltaje_banco_transformador = _informe.voltaje_banco_transformador;
        this.informe.coordenadas_zona_transformador = _informe.coordenadas_zona_transformador;
        this.informe.coordenadas_x_transformador = _informe.coordenadas_x_transformador;
        this.informe.coordenadas_y_transformador = _informe.coordenadas_y_transformador;
        this.informe.coordenadas_z_transformador = _informe.coordenadas_z_transformador;
        this.informe.distancia_poste = _informe.distancia_poste;
        this.informe.coordenadas_zona_poste = _informe.coordenadas_zona_poste;
        this.informe.coordenadas_x_poste = _informe.coordenadas_x_poste;
        this.informe.coordenadas_y_poste = _informe.coordenadas_y_poste;
        this.informe.coordenadas_z_poste = _informe.coordenadas_z_poste;
        this.informe.ubicacion_img_energia = _informe.ubicacion_img_energia;
        this.informe.ubicacion_fich_tecnica = _informe.ubicacion_fich_tecnica;
        this.informe.m1s5_4_completa = _informe.m1s5_4_completa;


        // -- |MODULO1 Seccion 5.5 SUELOS	|
        this.informe.id_tipo_relieve = _informe.id_tipo_relieve;
        this.informe.informe_geotecnica_suelo = _informe.informe_geotecnica_suelo;
        this.informe.descripcion_drenaje_pluvial = _informe.descripcion_drenaje_pluvial;
        this.informe.ubicacion_terreno_img = _informe.ubicacion_terreno_img;
        this.informe.m1s5_5_completa = _informe.m1s5_5_completa;

        //  --| modulo1. SECCION 5.6 SITUACION ACTUAL DEL PROYECTO |--
        // Sección 5.6 Modulo 1
        this.informe.id_tipo_sistema = _informe.id_tipo_sistema;
        this.informe.informe_instalaciones_agua = _informe.informe_instalaciones_agua;
        this.informe.informe_distribuciones_redes = _informe.informe_distribuciones_redes;
        this.informe.informe_nivel_servicio = _informe.informe_nivel_servicio;
        this.informe.servicio_dias_semana = _informe.servicio_dias_semana;
        this.informe.servicio_horas_dia = _informe.servicio_horas_dia;
        this.informe.presion_residual = _informe.presion_residual;
        this.informe.hora_medicion = _informe.hora_medicion;
        this.informe.pago_promedio_mensual = _informe.pago_promedio_mensual;
        this.informe.consumo_promedio_mensual = _informe.consumo_promedio_mensual;
        this.informe.agua_recibe_tratamiento = _informe.agua_recibe_tratamiento;
        this.informe.informe_instalaciones_tratamiento = _informe.informe_instalaciones_tratamiento;
        this.informe.recibe_saneamiento = _informe.recibe_saneamiento;
        this.informe.informe_instalaciones_saneamiento = _informe.informe_instalaciones_saneamiento;
        this.informe.recibe_higiene = _informe.recibe_higiene;
        this.informe.informe_instalaciones_higiene = _informe.informe_instalaciones_higiene;
        this.informe.viviendas_sin_agua = _informe.viviendas_sin_agua;
        this.informe.viviendas_sin_saneamiento = _informe.viviendas_sin_saneamiento;
        this.informe.viviendas_sin_higiene = _informe.viviendas_sin_higiene;
        this.informe.deficit_agua = _informe.deficit_agua;
        this.informe.deficit_saneamiento = _informe.deficit_saneamiento;
        this.informe.deficit_higiene = _informe.deficit_higiene;
        this.informe.cobertura_agua = _informe.cobertura_agua;
        this.informe.cobertura_saneamiento = _informe.cobertura_saneamiento;
        this.informe.cobertura_higiene = _informe.cobertura_higiene;
        this.informe.sin_proyecto_imagen = _informe.sin_proyecto_imagen;

        this.informe.m1s5_6_completa = _informe.m1s5_6_completa;

        // Fin Seccion 5
        this.informe.m1s5_completa = _informe.m1s5_completa;

        //  Sección 6 Modulo 1
        this.informe.id_categorizacion_nivel = _informe.id_categorizacion_nivel;
        this.informe.categorizacion_nivel = _informe.categorizacion_nivel;
        this.informe.id_categorizacion_tipo_saneamiento = _informe.id_categorizacion_tipo_saneamiento;
        this.informe.categorizacion_tipo_saneamiento = _informe.categorizacion_tipo_saneamiento;
        this.informe.nombre_proyecto = _informe.nombre_proyecto;
        this.informe.id_categorizacion_tipo_intervencion = _informe.id_categorizacion_tipo_intervencion;
        this.informe.categorizacion_tipo_intervencion = _informe.categorizacion_tipo_intervencion;
        // this.informe.id_modalidad = _informe.id_modalidad;
        // this.informe.modalidad = _informe.modalidad;
        this.informe.comunidad_organizada = _informe.comunidad_organizada;
        this.informe.informe_organizacion = _informe.informe_organizacion;
        this.informe.descrip_previa = _informe.descrip_previa;

        //nuevo
        this.informe.obra_conexion_domiciliar = _informe.obra_conexion_domiciliar;
        this.informe.obra_conexion_extradomiciliar = _informe.obra_conexion_extradomiciliar;
        this.informe.obra_puesto_publico = _informe.obra_puesto_publico;
        this.informe.obra_sanamiento_arrastre = _informe.obra_sanamiento_arrastre;
        this.informe.obra_saneamiento_seco = _informe.obra_saneamiento_seco;
        this.informe.obra_unidad_higiene = _informe.obra_unidad_higiene;
        // monto estimado
        this.informe.monto_obra_conexion_domiciliar = _informe.monto_obra_conexion_domiciliar;
        this.informe.monto_obra_conexion_extradomiciliar = _informe.monto_obra_conexion_extradomiciliar;
        this.informe.monto_obra_puesto_publico = _informe.monto_obra_puesto_publico;
        this.informe.monto_obra_sanamiento_arrastre = _informe.monto_obra_sanamiento_arrastre;
        this.informe.monto_obra_saneamiento_seco = _informe.monto_obra_saneamiento_seco;
        this.informe.monto_obra_unidad_higiene = _informe.monto_obra_unidad_higiene;
        // monto actividades
        this.informe.monto_ejecucion = _informe.monto_ejecucion;
        this.informe.monto_formulacion = _informe.monto_formulacion;
        this.informe.monto_supervision = _informe.monto_supervision;
        this.informe.monto_social = _informe.monto_social;
        this.informe.monto_ambiental = _informe.monto_ambiental;
        this.informe.monto_total = _informe.monto_total;
        // this.informe.informe_actividades_fortalecimiento = _informe.informe_actividades_fortalecimiento;
        this.informe.m1s6_completa = _informe.m1s6_completa;

        // Sección 1 Modulo 2
        this.informe.id_tipo_clima = _informe.id_tipo_clima;
        this.informe.precipitacion_anual = _informe.precipitacion_anual;
        this.informe.temperatura_anual = _informe.temperatura_anual;
        this.informe.evapotranspiracion_anual = _informe.evapotranspiracion_anual;
        this.informe.humedad_relativa_anual = _informe.humedad_relativa_anual;
        this.informe.informe_estacion_precipitacion = _informe.informe_estacion_precipitacion;
        this.informe.informe_medio_hg_superficial = _informe.informe_medio_hg_superficial;
        this.informe.informe_medio_hg_subterraneo = _informe.informe_medio_hg_subterraneo;
        this.informe.informe_potencial_hidrico_superficial = _informe.informe_potencial_hidrico_superficial;
        this.informe.informe_potencial_hidrico_subterraneo = _informe.informe_potencial_hidrico_subterraneo;
        this.informe.informe_alternativas_fuente = _informe.informe_alternativas_fuente;
        this.informe.informe_alternativas_fuente_calidad = _informe.informe_alternativas_fuente_calidad;
        this.informe.m2s1_completa = _informe.m2s1_completa;
        // Sección 2 Modulo 2
        this.informe.dentro_area_protegida = _informe.dentro_area_protegida;
        this.informe.id_area_protegida = _informe.id_area_protegida;
        this.informe.informe_usos_agua = _informe.informe_usos_agua;
        this.informe.id_tipo_cobertura_vegetal_recarga = _informe.id_tipo_cobertura_vegetal_recarga;
        this.informe.pendiente_recarga = _informe.pendiente_recarga;
        this.informe.id_tipo_suelo = _informe.id_tipo_suelo;
        this.informe.vulnerabilidad = _informe.vulnerabilidad;
        this.informe.id_tipo_obertura_vegetal_fuente = _informe.id_tipo_obertura_vegetal_fuente;
        this.informe.pendiente_fuente = _informe.pendiente_fuente;
        this.informe.requisitos_ambientales = _informe.requisitos_ambientales;
        this.informe.informe_fuentes_contaminacion = _informe.informe_fuentes_contaminacion;
        this.informe.informe_fuentes_amenazas = _informe.informe_fuentes_amenazas;
        this.informe.m2s2_completa = _informe.m2s2_completa;
        // Sección 1 Modulo 3
        this.informe.objetivo_central = _informe.objetivo_central;
        this.informe.objetivos_especificos = _informe.objetivos_especificos;
        this.informe.proposito = _informe.proposito;
        this.informe.informe_objetivos_medios = _informe.informe_objetivos_medios;
        this.informe.informe_objetivos_metas = _informe.informe_objetivos_metas;
        this.informe.m3s1_completa = _informe.m3s1_completa;
        // Sección 2 Modulo 3
        this.informe.informe_alternativa = _informe.informe_alternativa;
        this.informe.m3s2_completa = _informe.m3s2_completa;
        // Sección 1 Modulo 6
        this.informe.informe_aportes = _informe.informe_aportes;
        this.informe.informe_avales = _informe.informe_avales;
        this.informe.m6s1_completa = _informe.m6s1_completa;
        // Sección 1 Modulo 7
        this.informe.participacion_ciclo = _informe.participacion_ciclo;
        this.informe.experiencia = _informe.experiencia;
        this.informe.participacion_mujer = _informe.participacion_mujer;
        this.informe.informe_organizacion_miembros = _informe.informe_organizacion_miembros;
        this.informe.m7s1_completa = _informe.m7s1_completa;

        this.informe.created = _informe.created;
        this.informe.changed = _informe.changed;

        this.img_ubicacion_imagen = this.informe.ubicacion_imagen;
        this.img_ubicacion_micro = this.informe.ubicacion_micro;
        this.img_bancos_materiales_imagen = this.informe.bancos_materiales_imagen;
        this.img_sin_proyecto_imagen = this.informe.sin_proyecto_imagen;
        this.img_cobertura_forestal_imagen = this.informe.cobertura_forestal_imagen;
        this.img_ubicacion_img_energia = this.informe.ubicacion_img_energia;
        this.img_ubicacion_terreno = this.informe.ubicacion_terreno_img;

        this._estado = this.informe.estado_informe;
        this.load_municipios(this.informe.id_departamento);
        this.load_comunidades(this.informe.id_municipio);
        if (this.informe.municipio !== null) {
          this._categoria_pobreza = this.informe.categoria_pobreza.descripcion;
        }

        const saved = new Date(this.informe.changed);
        this._last_save = 'Guardado por última vez el '
          + saved.toLocaleString();
      }
    );
  }
  load_catalogos() {
    this.load_departamento();
    this.load_tipo_poblacion();
    this.load_categorizacion_nivel();
    this.load_categorizacion_tipo_saneamiento();
    this.load_categorizacion_tipo_intervencion();
    this.load_modalidad();
    this.load_tipo_organizacion();
    this.load_actividad_economica();
    this.load_tipo_servicio();
    this.load_tipo_revestimiento();
    this.load_tipo_medios_transporte();
    this.load_concesionario();
    this.load_tipo_linea();
    this.load_tipo_fase();
    this.load_tipo_relieve();
    this.load_geotectonica_suelo();
    this.load_tipo_fuente_agua();
    this.load_parametro_calidad();
    this.load_tipo_material();
    this.load_tipo_instalacion_agua();
    this.load_tipo_red();
    this.load_nivel_servicio();
    this.load_tipo_tratamiento();
    this.load_tipo_instalacion_saneamiento();
    this.load_tipo_instalacion_higiene();
    this.load_tipo_clima();
    this.load_tipo_acuifero();
    this.load_tipo_obra();
    this.load_metodo_aforo();
    this.load_tipo_sistema();
    this.load_tipo_bomba();
    this.load_etnia();
    this.load_area_protegida();
    this.load_tipo_cobertura_vegetal();
    this.load_tipo_suelo();
    this.load_usos_agua();
    this.load_tipo_contaminacion();
    this.load_tipo_amenaza();
    this.load_tipo_medida_adaptacion();
    this.load_estado_legal_fuente();
  }
  load_departamento() {
    this._departamento_service.list(this.token).subscribe(
      response => {
        this._departamentos = response;
      }
    );
  }
  load_municipios(id_departamento: number) {
    this._municipio_service.list(this.token, id_departamento).subscribe(
      response => {
        this._municipios = response;
      }
    );
  }
  load_comunidades(id_municipio: number) {
    this._comunidad_service.list(this.token, id_municipio).subscribe(
      response => {
        this._comunidades = response;
      }
    );
  }
  load_tipo_poblacion() {
    this._tipo_poblacion_service.list(this.token).subscribe(
      response => {
        this._tipos_poblacion = response;
      }
    );
  }
  load_categorizacion_nivel() {
    this._categorizacion_nivel_service.list(this.token).subscribe(
      response => {
        this._categorizacion_niveles = response;
      }
    );
  }
  load_categorizacion_tipo_saneamiento() {
    this._categorizacion_tipo_saneamiento.list(this.token).subscribe(
      response => {
        this._categorizacion_tipos_saneamiento = response;
      }
    );
  }
  load_categorizacion_tipo_intervencion() {
    this._categorizacion_tipo_intervencion.list(this.token).subscribe(
      response => {
        this._categorizacion_tipos_intervencion = response;
      }
    );
  }
  load_modalidad() {
    this._modalidad_service.list(this.token).subscribe(
      response => {
        this._modalidades = response;
      }
    );
  }
  load_tipo_organizacion() {
    this._tipo_organizacion_service.list(this.token).subscribe(
      response => {
        this._tipos_organizacion = response;
      }
    );
  }
  load_actividad_economica() {
    this._actividad_economica_service.list(this.token).subscribe(
      response => {
        this._actividades_economicas = response;
      }
    );
  }
  load_tipo_servicio() {
    this._tipo_servicio_service.list(this.token).subscribe(
      response => {
        this._tipos_servicio = response;
      }
    );
  }
  load_tipo_revestimiento() {
    this._tipo_revestimiento_service.list(this.token).subscribe(
      response => {
        this._tipos_revestimiento = response;
      }
    );
  }
  load_tipo_medios_transporte() {
    this._tipo_medio_transporte_service.list(this.token).subscribe(
      response => {
        this._tipos_medios_transporte = response;
      }
    );
  }
  load_concesionario() {
    this._concesionario_service.list(this.token).subscribe(
      response => {
        this._concesionarios = response;
      }
    );
  }
  load_tipo_linea() {
    this._tipo_linea_service.list(this.token).subscribe(
      response => {
        this._tipos_linea = response;
      }
    );
  }
  load_tipo_fase() {
    this._tipo_fase_service.list(this.token).subscribe(
      response => {
        this._tipos_fase = response;
      }
    );
  }
  load_tipo_relieve() {
    this._tipo_relieve_service.list(this.token).subscribe(
      response => {
        this._tipos_relieve = response;
      }
    );
  }
  load_geotectonica_suelo() {
    this._geotectonica_suelo_service.list(this.token).subscribe(
      response => {
        this._geotecnicas_suelo = response;
      }
    );
  }
  load_tipo_fuente_agua() {
    this._tipo_fuente_agua_service.list(this.token).subscribe(
      response => {
        this._tipos_fuente_agua = response;
      }
    );
  }
  load_parametro_calidad() {
    this._parametro_calidad_service.list(this.token).subscribe(
      response => {
        this._parametros_calidad = response;
      }
    );
  }
  load_tipo_material() {
    this._tipo_materialservice.list(this.token).subscribe(
      response => {
        this._tipos_material = response;
      }
    );
  }
  load_tipo_instalacion_agua() {
    this._tipo_instalacion_agua_service.list(this.token).subscribe(
      response => {
        this._tipos_instalacion_agua = response;
      }
    );
  }
  load_tipo_red() {
    this._tipo_red_service.list(this.token).subscribe(
      response => {
        this._tipos_red = response;
      }
    );
  }
  load_nivel_servicio() {
    this._nivel_servicio_service.list(this.token).subscribe(
      response => {
        this._niveles_servicio = response;
      }
    );
  }
  load_tipo_tratamiento() {
    this._tipo_tratamiento_service.list(this.token).subscribe(
      response => {
        this._tipos_tratamiento = response;
      }
    );
  }
  load_tipo_instalacion_saneamiento() {
    this._tipo_instalacion_saneamiento_service.list(this.token).subscribe(
      response => {
        this._tipos_instalacion_sanemiento = response;
      }
    );
  }
  load_tipo_instalacion_higiene() {
    this._tipo_instalacion_higiene_service.list(this.token).subscribe(
      response => {
        this._tipos_instalacion_higiene = response;
      }
    );
  }
  load_tipo_clima() {
    this._tipo_clima_service.list(this.token).subscribe(
      response => {
        this._tipos_clima = response;
      }
    );
  }
  load_tipo_acuifero() {
    this._tipo_acuifero_service.list(this.token).subscribe(
      response => {
        this._tipos_acuifero = response;
      }
    );
  }
  load_tipo_obra() {
    this._tipo_obra_service.list(this.token).subscribe(
      response => {
        this._tipos_obra = response;
      }
    );
  }
  load_metodo_aforo() {
    this._metodo_aforo_service.list(this.token).subscribe(
      response => {
        this._metodos_aforo = response;
      }
    );
  }
  load_tipo_sistema() {
    this._tipo_sistema_service.list(this.token).subscribe(
      response => {
        this._tipos_sistema = response;
      }
    );
  }
  load_tipo_bomba() {
    this._tipo_bomba_service.list(this.token).subscribe(
      response => {
        this._tipos_bomba = response;
      }
    );
  }
  load_etnia() {
    this._etnia_service.list(this.token).subscribe(
      response => {
        this._etnias = response;
      }
    );
  }
  load_area_protegida() {
    this._area_protegida_service.list(this.token).subscribe(
      response => {
        this._areas_protegidas = response;
      }
    );
  }
  load_tipo_cobertura_vegetal() {
    this._tipo_cobertura_vegetal_service.list(this.token).subscribe(
      response => {
        this._tipos_cobertura_vegetal = response;
      }
    );
  }
  load_tipo_suelo() {
    this._tipo_suelo_service.list(this.token).subscribe(
      response => {
        this._tipos_suelo = response;
      }
    );
  }
  load_usos_agua() {
    this._usos_agua_service.list(this.token).subscribe(
      response => {
        this._usos_agua = response;
      }
    );
  }
  load_tipo_contaminacion() {
    this._tipo_contaminacion_service.list(this.token).subscribe(
      response => {
        this._tipos_contaminacion = response;
      }
    );
  }
  load_tipo_amenaza() {
    this._tipo_amenaza_service.list(this.token).subscribe(
      response => {
        this._tipos_amenazas = response;
      }
    );
  }
  load_tipo_medida_adaptacion() {
    this._tipo_medida_adaptacion.list(this.token).subscribe(
      response => {
        this._tipos_medidas_adaptacion = Array.from(this.MedidasTipo(response, r => r.tipo));
      }
    );
  }
  load_estado_legal_fuente() {
    this._estado_legal_fuente_service.list(this.token).subscribe(
      response => {
        this._estado_legal_fuente = response;
      }
    );
  }
  load_informes_codigo(id_municipio: number) {
    let count: number;
    this._informe_service.list(this.token).subscribe(
      response => {
        count = response.filter(i => i.id_municipio === id_municipio).length + 1;
        this.informe.codigo = this.informe.municipio.codigo_inec + count;
      });
  }

  //#endregion load

  //#region onchange ()
  departamento_change() {
    if (this.informe.id_departamento !== 0) {
      this.informe.departamento = this._departamentos.find(e => e.id === this.informe.id_departamento);
      this.informe.id_municipio = 0;
      this.load_municipios(this.informe.id_departamento);
    } else {
      this.informe.id_departamento = 0;
      this.informe.id_municipio = 0;
      this._municipios = <any>[];
    }
    this.municipio_change();
  }
  municipio_change() {
    if (this.informe.id_municipio !== 0) {
      // seleccionar el objeto municipio
      this.informe.municipio = this._municipios.find(e => e.id === this.informe.id_municipio);
      // cargar la lista de comunidades
      this.load_comunidades(this.informe.id_municipio);
      // cargar la categoria de pobreza del municipio
      this.informe.categoria_pobreza = this.informe.municipio.categoria_pobreza;
      this._categoria_pobreza = this.informe.categoria_pobreza.descripcion;
      this.load_informes_codigo(this.informe.id_municipio);
    } else {
      this.informe.id_municipio = 0;
      this.informe.municipio = null;
      this.informe.categoria_pobreza = null;
      this.informe.codigo = '';
      this._categoria_pobreza = '';
      this._comunidades = <any>[];
    }

  }
  categorizacion_nivel_change() {
    if (this.informe.id_categorizacion_nivel !== 0) {
      this.informe.categorizacion_nivel =
        this._categorizacion_niveles.find(e => e.id === this.informe.id_categorizacion_nivel);
    } else {
      this.informe.categorizacion_nivel = null;
    }
  }
  categorizacion_tipo_saneamiento_change() {
    if (this.informe.id_categorizacion_tipo_saneamiento !== 0) {
      this.informe.categorizacion_tipo_saneamiento =
        this._categorizacion_tipos_saneamiento.find(e => e.id === this.informe.id_categorizacion_tipo_saneamiento);
    } else {
      this.informe.categorizacion_tipo_saneamiento = null;
    }
  }
  categorizacion_tipo_intervencion_change() {
    if (this.informe.id_categorizacion_tipo_intervencion !== 0) {
      this.informe.categorizacion_tipo_intervencion =
        this._categorizacion_tipos_intervencion.find(e => e.id === this.informe.id_categorizacion_tipo_intervencion);
    } else {
      this.informe.categorizacion_tipo_intervencion = null;
    }
  }
  /* modalidad_change() {
     if (this.informe.id_modalidad !== 0) {
       this.informe.modalidad =
         this._modalidades.find(e => e.id === this.informe.id_modalidad);
     } else {
       this.informe.modalidad = null;
     }
   }*/
  concesionario_change() {
    if (this.informe.id_concesionario !== 0) {
      this.informe.concesionario =
        this._concesionarios.find(e => e.id === this.informe.id_concesionario);
    } else {
      this.informe.concesionario = null;
    }
  }
  tipo_linea_change() {
    if (this.informe.id_tipo_linea !== 0) {
      this.informe.tipo_linea =
        this._tipos_linea.find(e => e.id === this.informe.id_tipo_linea);
    } else {
      this.informe.tipo_linea = null;
    }
  }
  tipo_fase_change() {
    if (this.informe.id_tipo_fase !== 0) {
      this.informe.tipo_fase =
        this._tipos_fase.find(e => e.id === this.informe.id_tipo_fase);
    } else {
      this.informe.tipo_fase = null;
    }
  }
  estado_change() {

    if (this.informe.m1s1_completa
      && this.informe.m1s2_completa
      && this.informe.m1s3_completa
      && this.informe.m1s4_completa
      && this.informe.m1s5_1_completa
      && this.informe.m1s5_2_completa
      && this.informe.m1s5_3_completa
      && this.informe.m1s5_4_completa
      && this.informe.m1s5_5_completa
      && this.informe.m1s5_6_completa
      && this.informe.m1s5_completa
      && this.informe.m1s6_completa) {
      this._estado = 'Perfil de Proyecto';
      this.informe.estado_informe = this._estado;
    }
    else {
      this._estado = 'Borrador';
      this.informe.estado_informe = this._estado;
    }
  }
  tipo_sistema_change() {
    if (this.informe.id_tipo_sistema !== 0) {
      this.informe.tipo_sistema =
        this._tipos_sistema.find(e => e.id === this.informe.id_tipo_sistema);
    } else {
      this.informe.tipo_sistema = null;
    }
  }
  tipo_relieve_change() {
    if (this.informe.id_tipo_relieve !== 0) {
      this.informe.tipo_relieve =
        this._tipos_relieve.find(e => e.id === this.informe.id_tipo_relieve);
    } else {
      this.informe.tipo_relieve = null;
    }
  }
  tipo_clima_change() {
    if (this.informe.id_tipo_clima !== 0) {
      this.informe.tipo_clima =
        this._tipos_clima.find(e => e.id === this.informe.id_tipo_clima);
    } else {
      this.informe.tipo_clima = null;
    }
  }
  //#endregion onchange


  //#region handle images
  handleUbicacionImagen(files: FileList) {
    if (files.length > 0) {
      this.file_ubicacion_imagen = files.item(0);
      const reader = new FileReader();
      reader.readAsDataURL(files.item(0));
      reader.onload = (_event) => {
        this.img_ubicacion_imagen = reader.result;
      };
    }
  }
  uploadUbicacionImage() {
    if (this.file_ubicacion_imagen !== null && this.file_ubicacion_imagen !== undefined) {
      const filename = this.informe.id + 'ubicacion_imagen' +
        this.file_ubicacion_imagen.name.substr(this.file_ubicacion_imagen.name.lastIndexOf('.'));

      this._file_upload_service.upload(this.token, this.file_ubicacion_imagen, filename).toPromise().then(
        response => {
          this.informe.ubicacion_imagen = global.url + 'downloadfile?image=' + filename;
        }
      );
    }
  }
  handleUbicacionImagenmicro(files: FileList) {
    if (files.length > 0) {
      this.file_ubicacion_micro = files.item(0);
      const reader = new FileReader();
      reader.readAsDataURL(files.item(0));
      reader.onload = (_event) => {
        this.img_ubicacion_micro = reader.result;
      };
    }
  }
  uploadUbicacionImagenmicro() {
    if (this.file_ubicacion_micro !== null && this.file_ubicacion_micro !== undefined) {
      const filename = this.informe.id + 'ubicacion_micro' +
        this.file_ubicacion_micro.name.substr(this.file_ubicacion_micro.name.lastIndexOf('.'));

      this._file_upload_service.upload(this.token, this.file_ubicacion_micro, filename).toPromise().then(
        response => {
          this.informe.ubicacion_micro = global.url + 'downloadfile?image=' + filename;
        }
      );
    }
  }
  handleUbicacionImagenBancoMaterial(files: FileList) {
    if (files.length > 0) {
      this.file_bancos_materiales_imagen = files.item(0);
      const reader = new FileReader();
      reader.readAsDataURL(files.item(0));
      reader.onload = (_event) => {
        this.img_bancos_materiales_imagen = reader.result;
      };
    }
  }
  uploadUbicacionImagenBancoMaterial() {
    if (this.file_bancos_materiales_imagen !== null && this.file_bancos_materiales_imagen !== undefined) {
      const filename = this.informe.id + 'bancos_materiales_imagen' +
        this.file_bancos_materiales_imagen.name.substr(this.file_bancos_materiales_imagen.name.lastIndexOf('.'));

      this._file_upload_service.upload(this.token, this.file_bancos_materiales_imagen, filename).toPromise().then(
        response => {
          this.informe.bancos_materiales_imagen = global.url + 'downloadfile?image=' + filename;
        }
      );
    }
  }
  handleSinProyectoImagen(files: FileList) {
    if (files.length > 0) {
      this.file_sin_proyecto_imagen = files.item(0);
      const reader = new FileReader();
      reader.readAsDataURL(files.item(0));
      reader.onload = (_event) => {
        this.img_sin_proyecto_imagen = reader.result;
      };
    }
  }
  uploadSinProyectoImagen() {
    if (this.file_sin_proyecto_imagen !== null && this.file_sin_proyecto_imagen !== undefined) {
      const filename = this.informe.id + 'sin_proyecto_imagen' +
        this.file_sin_proyecto_imagen.name.substr(this.file_sin_proyecto_imagen.name.lastIndexOf('.'));

      this._file_upload_service.upload(this.token, this.file_sin_proyecto_imagen, filename).toPromise().then(
        response => {
          this.informe.sin_proyecto_imagen = global.url + 'downloadfile?image=' + filename;
        }
      );
    }
  }
  // -----------------------------------
  handleUbicacionImagenEnergia(files: FileList) {
    if (files.length > 0) {
      this.file_ubicacion_img_energia = files.item(0);
      const reader = new FileReader();
      reader.readAsDataURL(files.item(0));
      reader.onload = (_event) => {
        this.img_ubicacion_img_energia = reader.result;
      };
    }
  }
  uploadUbicacionImageEnergia() {
    if (this.file_ubicacion_img_energia !== null && this.file_ubicacion_img_energia !== undefined) {
      const filename = this.informe.id + 'ubicacion_img_energia' +
        this.file_ubicacion_img_energia.name.substr(this.file_ubicacion_img_energia.name.lastIndexOf('.'));

      this._file_upload_service.upload(this.token, this.file_ubicacion_img_energia, filename).toPromise().then(
        response => {
          this.informe.ubicacion_img_energia = global.url + 'downloadfile?image=' + filename;
        }
      );
    }
  }

  handleUbicacionFichaTecnica(files: FileList) {
    if (files.length > 0) {
      this.file_ubicacion_fich_tecnica = files.item(0);
      const reader = new FileReader();
      reader.readAsDataURL(files.item(0));
      reader.onload = (_event) => {
        this.img_ubicacion_fich_tecnica = reader.result;
      };
    }
  }
  uploadUbicacionFichaTecnica() {
    if (this.file_ubicacion_fich_tecnica !== null && this.file_ubicacion_fich_tecnica !== undefined) {
      const filename = this.informe.id + 'ubicacion_fich_tecnica' +
        this.file_ubicacion_fich_tecnica.name.substr(this.file_ubicacion_fich_tecnica.name.lastIndexOf('.'));

      this._file_upload_service.uploadArch(this.token, this.file_ubicacion_fich_tecnica, filename).toPromise().then(
        response => {
          this.informe.ubicacion_fich_tecnica = global.url + 'downloadarch?arch=' + filename;
        }
      );
    }
  }
  // -----------------------------------
  handleUbicacionImagenForestal(files: FileList) {
    if (files.length > 0) {
      this.file_cobertura_forestal_imagen = files.item(0);
      const reader = new FileReader();
      reader.readAsDataURL(files.item(0));
      reader.onload = (_event) => {
        this.img_cobertura_forestal_imagen = reader.result;
      };
    }
  }
  uploadUbicacionImageForestal() {
    if (this.file_cobertura_forestal_imagen !== null && this.file_cobertura_forestal_imagen !== undefined) {
      const filename = this.informe.id + 'cobertura_forestal_imagen' +
        this.file_cobertura_forestal_imagen.name.substr(this.file_cobertura_forestal_imagen.name.lastIndexOf('.'));
      this._file_upload_service.upload(this.token, this.file_cobertura_forestal_imagen, filename).toPromise().then(
        response => {
          this.informe.cobertura_forestal_imagen = global.url + 'downloadfile?image=' + filename;
        }
      );
    }
  }

  //
  handleUbicacionDocRevisado(files: FileList) {
    if (files.length > 0) {
      this.file_ubicacion_doc_revisado = files.item(0);
      const reader = new FileReader();
      reader.readAsDataURL(files.item(0));
      reader.onload = (_event) => {
        this.img_ubicacion_doc_revisado = reader.result;
      };
    }
  }
  uploadUbicacionDocRevisado() {
    if (this.file_ubicacion_doc_revisado !== null && this.file_ubicacion_doc_revisado !== undefined) {
      const filename = this.informe.id + 'ubicacion_doc_revisado' +
        this.file_ubicacion_doc_revisado.name.substr(this.file_ubicacion_doc_revisado.name.lastIndexOf('.'));
      this._file_upload_service.uploadArch(this.token, this.file_ubicacion_doc_revisado, filename).toPromise().then(
        response => {
          this.informe.ubicacion_doc_revisado = global.url + 'downloadarch?arch=' + filename;
        }
      );
    }
  }


  handleUbicacionLineaBas(files: FileList) {
    if (files.length > 0) {
      this.file_ubicacion_bd = files.item(0);
      const reader = new FileReader();
      reader.readAsDataURL(files.item(0));
      reader.onload = (_event) => {
        this.img_ubicacion_bd = reader.result;
      };
    }
  }

  uploadUbicacionLineaBas() {
    if (this.file_ubicacion_bd !== null && this.file_ubicacion_bd !== undefined) {
      const filename = this.informe.id + 'ubicacion_bd' +
        this.file_ubicacion_bd.name.substr(this.file_ubicacion_bd.name.lastIndexOf('.'));
      this._file_upload_service.uploadArch(this.token, this.file_ubicacion_bd, filename).toPromise().then(
        response => {
          this.informe.ubicacion_bd = global.url + 'downloadexcel?excel=' + filename;
        }
      );
    }
  }

  handleUbicacionInformeLBD(files: FileList) {
    if (files.length > 0) {
      this.file_ubicacion_infome_bd = files.item(0);
      const reader = new FileReader();
      reader.readAsDataURL(files.item(0));
      reader.onload = (_event) => {
        this.img_ubicacion_infome_bd = reader.result;
      };
    }
  }

  uploadUbicacionInformeLBD() {
    if (this.file_ubicacion_infome_bd !== null && this.file_ubicacion_infome_bd !== undefined) {
      const filename = this.informe.id + 'ubicacion_infome_bd' +
        this.file_ubicacion_infome_bd.name.substr(this.file_ubicacion_infome_bd.name.lastIndexOf('.'));
      this._file_upload_service.uploadArch(this.token, this.file_ubicacion_infome_bd, filename).toPromise().then(
        response => {
          this.informe.ubicacion_infome_bd = global.url + 'downloadarch?arch=' + filename;
        }
      );
    }
  }

  handleUbicacionFichaHidrica(files: FileList) {
    if (files.length > 0) {
      this.file_ubicacion_fichahidri = files.item(0);
      const reader = new FileReader();
      reader.readAsDataURL(files.item(0));
      reader.onload = (_event) => {
        this.img_ubicacion_fichahidri = reader.result;
      };
    }
  }

  uploadUbicacionFichaHidirica() {
    if (this.file_ubicacion_fichahidri !== null && this.file_ubicacion_fichahidri !== undefined) {
      const filename = this.informe.id + 'ubicacion_fichahidri' +
        this.file_ubicacion_fichahidri.name.substr(this.file_ubicacion_fichahidri.name.lastIndexOf('.'));
      this._file_upload_service.uploadArch(this.token, this.file_ubicacion_fichahidri, filename).toPromise().then(
        response => {
          this.informe.ubicacion_fichahidri = global.url + 'downloadarch?arch=' + filename;
        }
      );
    }
  }

  andleUbicacionTerreno(files: FileList) {
    if (files.length > 0) {
      this.file_ubicacion_terreno = files.item(0);
      const reader = new FileReader();
      reader.readAsDataURL(files.item(0));
      reader.onload = (_event) => {
        this.img_ubicacion_terreno = reader.result;
      };
    }
  }

  uploadUbicacionTerreno() {
    if (this.file_ubicacion_terreno !== null && this.file_ubicacion_terreno !== undefined) {
      const filename = this.informe.id + 'ubicacion_terreno_img' +
        this.file_ubicacion_terreno.name.substr(this.file_ubicacion_terreno.name.lastIndexOf('.'));
      this._file_upload_service.upload(this.token, this.file_ubicacion_terreno, filename).toPromise().then(
        response => {
          this.informe.ubicacion_terreno_img = global.url + 'downloadfile?image=' + filename;
        }
      );
    }
  }
  //#endregion handle images
  // file_ubicacion_doc_revisado
  //#endregion handle images

  //#region save informe
  save() {
    const now = new Date();
    this.uploadUbicacionImage();
    this.uploadUbicacionImagenmicro();
    this.uploadUbicacionImagenBancoMaterial();
    this.uploadSinProyectoImagen();
    this.uploadUbicacionImageEnergia();
    this.uploadUbicacionImageForestal();
    this.uploadUbicacionDocRevisado();
    this.uploadUbicacionLineaBas();
    this.uploadUbicacionFichaHidirica();
    this.uploadUbicacionInformeLBD();
    this.uploadUbicacionFichaTecnica();
    this.uploadUbicacionTerreno();

    if (this.informe.id === 0) {
      this.informe.created = now;
      this.informe.changed = now;
      this.add();
    } else {
      this.informe.changed = now;
      this.uploadUbicacionImage();
      this.uploadUbicacionImagenmicro();
      this.uploadUbicacionImagenBancoMaterial();
      this.uploadSinProyectoImagen();
      this.uploadUbicacionImageEnergia();
      this.uploadUbicacionImageForestal();
      this.uploadUbicacionDocRevisado();
      this.uploadUbicacionLineaBas();
      this.uploadUbicacionFichaHidirica();
      this.uploadUbicacionInformeLBD();
      this.uploadUbicacionFichaTecnica();
      this.uploadUbicacionTerreno();
      this.edit();

    }

    this._last_save = 'Guardado por última vez el ' + now.toLocaleDateString() + ' ' + now.toLocaleTimeString();
  }
  add() {
    this._informe_service.addN(this.token, this.informe).subscribe(
      response => {
        this.informe.id = response.id;

        this._toastr.success('Se han guardado los cambios.', 'Informe Guardado', {
          timeOut: 3000,
          enableHtml: true,
          closeButton: true,
        });

      }
    );
  }
  edit() {
    this._informe_service.editN(this.token, this.informe).subscribe(response => {
      console.log(response);
      this._toastr.success('Se han guardado los cambios.', 'Informe Guardado', {
        timeOut: 3000,
        enableHtml: true,
        closeButton: true,
      });
    });
  }
  //#endregion save informe

  //#region utilities
  MedidasTipo(array: Array<any>, column) {
    const map = new Map();
    array.forEach((item) => {
      const key = column(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
  //#endregion
}
