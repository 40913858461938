import { TipoInstalacionSaneamiento } from '../catalogos/tipo_instalacion_saneamiento';

export class InformeInstalacionesSaneamiento {
    public id_informe = 0;
    public tipo_instalacion_saneamiento: TipoInstalacionSaneamiento = null;
    public estado = '';
    public cantidad = 0;
    public poblacion_atendida = 0;
    public viviendas_atendidas = 0;
    public caracteristicas = '';
}
