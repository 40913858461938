import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { global } from '../global';


@Injectable()
export class TipoRelieveService {
    public url: string;

    constructor(
        private _http: HttpClient

    ) {
        this.url = global.url;
    }

    list(token): Observable<any> {
        const headers = new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', 'Bearer ' + token);
        return this._http.get(this.url + 'tiporelieve', {headers: headers});
    }
}
