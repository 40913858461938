import { TipoRevestimiento } from '../catalogos/tipo_revestimiento';
import { Comunidad } from '../catalogos/comunidad';
import { TipoMediosTransporte } from '../catalogos/tipo_medios_transporte';

export class InformeViasAcceso {
    public id_informe = 0;
    public comunidad: Comunidad = null;
    public tipo_revestimiento: TipoRevestimiento = null;
    public tipo_medios_transporte: TipoMediosTransporte = null;
    public origen = '';
    public destino = '';
    public distancia = 0;
    public tiempo = 0;
}
