import { TipoInstalacionAgua } from '../catalogos/tipo_instalacion_agua';

export class InformeInstalacionesAgua {
    public id_informe = 0;
    public tipo_instalacion_agua: TipoInstalacionAgua = null;
    public estado = '';
    public descripcion_estado = '';
    public coordenada_zona = '';
    public coordenada_x = '';
    public coordenada_y = '';
    public coordenada_z = '';
    public caracteristicas = '';
}
