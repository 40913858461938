import { Component, OnInit, DoCheck } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { InformeN } from 'src/app/models/Informe/informe';
import { InformeOrganizacion } from 'src/app/models/informe/informe_organizacion';
import { UserService } from 'src/app/services/seguridad/user.service';
import { InformeActividadFortalecimiento } from 'src/app/models/informe/informe_actividad_fortalecimiento';
import { InformeActividadEconomica } from 'src/app/models/Informe/informe_actividad_economica';
import { InformeServicioExistente } from 'src/app/models/Informe/informe_servicio_existente';
import { InformeViasAcceso } from 'src/app/models/informe/informe_vias_acceso';

import { DepartamentoService } from 'src/app/services/catalogos/departamento.service';
import { MunicipioService } from 'src/app/services/catalogos/municipio.service';
import { ComunidadService } from 'src/app/services/catalogos/comunidad.service';
import { Departamento } from 'src/app/models/catalogos/departamento';
import { Municipio } from 'src/app/models/catalogos/municipio';
import { Comunidad } from 'src/app/models/catalogos/comunidad';
import { TipoPoblacionService } from 'src/app/services/catalogos/tipo_poblacion.service';
import { TipoPoblacion } from 'src/app/models/catalogos/tipo_poblacion';

import { CategorizacionNivelService } from 'src/app/services/catalogos/categorizacion_nivel.service';
import { CategorizacionNivel } from 'src/app/models/catalogos/cateagorizacion_nivel';
import { CategorizacionTipoSaneamientoService } from 'src/app/services/catalogos/categorizacion_tipo_saneamiento.service';
import { CategorizacionTipoSaneamiento } from 'src/app/models/catalogos/categorizacion_tipo_saneamiento';
import { CategorizacionTipoIntervencionService } from 'src/app/services/catalogos/categorizacion_tipo_intervencion.service';
import { CategorizacionTipoIntervencion } from 'src/app/models/catalogos/categorizacion_tipo_intervencion';
import { ModalidadService } from 'src/app/services/catalogos/modalidad.service';
import { Modalidad } from 'src/app/models/catalogos/modalidad';
import { TipoOrganizacion } from 'src/app/models/catalogos/tipo_organizacion';
import { TipoOrganizacionService } from 'src/app/services/catalogos/tipo_organizacion.service';
import { ActividadEconomicaService } from 'src/app/services/catalogos/actividad_economica.service';
import { ActividadEconomica } from 'src/app/models/catalogos/actividad_economica';
import { TipoServicio } from 'src/app/models/catalogos/tipo_servicio';
import { TipoServicioService } from 'src/app/services/catalogos/tipos_servicio.service';
import { TipoRevestimientoService } from 'src/app/services/catalogos/tipo_revestimiento.service';
import { TipoRevestimiento } from 'src/app/models/catalogos/tipo_revestimiento';
import { TipoMediosTransporteService } from 'src/app/services/catalogos/tipo_medios_transporte.service';
import { TipoMediosTransporte } from 'src/app/models/catalogos/tipo_medios_transporte';
import { InformeService } from 'src/app/services/informe.service';
import { ConcesionarioService } from 'src/app/services/catalogos/concesionario.service';
import { Concesionario } from 'src/app/models/catalogos/concesionario';
import { TipoLineaService } from 'src/app/services/catalogos/tipo_linea.service';
import { TipoLinea } from 'src/app/models/catalogos/tipo_linea';
import { TipoFase } from 'src/app/models/catalogos/tipo_fase';
import { TipoFaseService } from 'src/app/services/catalogos/tipo_fase.service';
import { TipoRelieve } from 'src/app/models/catalogos/tipo_relieve';
import { TipoRelieveService } from 'src/app/services/catalogos/tipo_relieve.service';
import { InformeGeotecnicaSuelo } from 'src/app/models/informe/informe_geotecnica_suelo';
import { GeotectonicaSueloService } from 'src/app/services/catalogos/geotectonica_suelo.service';
import { GeotecnicaSuelo } from 'src/app/models/catalogos/geotecnica_suelo';
import { TipoFuenteAguaService } from 'src/app/services/catalogos/tipo_fuente_agua.service';
import { TipoFuenteAgua } from 'src/app/models/catalogos/tipo_fuente_agua';
import { ParametroCalidadService } from 'src/app/services/catalogos/parametro_calidad.service';
import { ParametroCalidad } from 'src/app/models/catalogos/parametro_calidad';
import { InformeInventarioParametrosCalidad } from 'src/app/models/informe/informe_inventario_parametros_calidad';
import { InformeBancoMaterial } from 'src/app/models/informe/informe_banco_material';
import { TipoMaterialService } from 'src/app/services/catalogos/tipo_material.service';
import { TipoMaterial } from 'src/app/models/catalogos/tipo_material';
import { InformeInstalacionesAgua } from 'src/app/models/informe/informe_instalaciones_agua';
import { TipoInstalacionAgua } from 'src/app/models/catalogos/tipo_instalacion_agua';
import {TipoInstalacionHigiene} from 'src/app/models/catalogos/tipo_instalacion_higiene';
import { TipoInstalacionAguaService } from 'src/app/services/catalogos/tipo_instalacion_agua.service';
import { TipoRedService } from 'src/app/services/catalogos/tipo_red.service';
import { InformeDistribucionesRedes } from 'src/app/models/informe/informe_distribuciones_redes';
import { TipoRed } from 'src/app/models/catalogos/tipo_red';
import { InformeNivelServicio } from 'src/app/models/informe/informe_nivel_servicio';
import { NivelServicio } from 'src/app/models/catalogos/nivel_servicio';
import { NivelServicioService } from 'src/app/services/catalogos/nivel_servicio.service';
import { TipoTratamientoService } from 'src/app/services/catalogos/tipo_tratamiento.service';
import { TipoTratamiento } from 'src/app/models/catalogos/tipo_tratamiento';
import { InformeInstalacionesSaneamiento } from 'src/app/models/informe/informe_instalaciones_saneamiento';
import { TipoInstalacionSaneamientoService } from 'src/app/services/catalogos/tipo_instalacion_saneamiento.service';
import { TipoInstalacionSaneamiento } from 'src/app/models/catalogos/tipo_instalacion_saneamiento';
import { InformePdNivelServicio } from 'src/app/models/informe/informe_pd_nivel_servicio';
import { TipoClimaService } from 'src/app/services/catalogos/tipo_clima.service';
import { TipoClima } from 'src/app/models/catalogos/tipo_clima';
import { InformeMedioHgSuperficial } from 'src/app/models/informe/informe_medio_hg_superficial';
import { TipoAcuiferoService } from 'src/app/services/catalogos/tipo_acuifero.service';
import { TipoAcuifero } from 'src/app/models/catalogos/tipo_acuifero';
import { InformeMedioHgSubterraneo } from 'src/app/models/informe/informe_medio_hg_subterraneo';
import { InformeEstacionPrecipitacion } from 'src/app/models/informe/informe_estacion_precipitacion';
import { TipoObraService } from 'src/app/services/catalogos/tipo_obra.service';
import { TipoObra } from 'src/app/models/catalogos/tipo_obra';
import { InformeInventarioFuenteSuperficial } from 'src/app/models/informe/informe_inventario_fuente_superficial';
import { MetodoAforoService } from 'src/app/services/catalogos/metodo_aforo.service';
import { MetodoAforo } from 'src/app/models/catalogos/metodo_aforo';
import { TipoSistema } from 'src/app/models/catalogos/tipo_sistema';
import { TipoSistemaService } from 'src/app/services/catalogos/tipos_sistema.service';
import { InformeInventarioFuenteSubterranea } from 'src/app/models/informe/informe_inventario_fuente_subterranea';
import { TipoBombaService } from 'src/app/services/catalogos/tipo_bomba.service';
import { TipoBomba } from 'src/app/models/catalogos/tipo_bomba';
import { InformePotencialHidricoSuperficial } from 'src/app/models/Informe/informe_potencial_hidrico_superficial';
import { InformePotencialHidricoSubterraneo } from 'src/app/models/informe/informe_potencial_hidrico_subterraneo';
import { InformeCorreos } from 'src/app/models/informe/informe_correos';
import { InformeTelefonos } from 'src/app/models/Informe/informe_telefonos';
import { InformeComunidades } from 'src/app/models/Informe/informe_comunidades';
import { Etnia } from 'src/app/models/catalogos/etnia';
import { EtniaService } from 'src/app/services/catalogos/etnia.service';
import { InformeDistancias } from 'src/app/models/Informe/informe_distancias';
import { InformeInstalacionesTratamiento } from 'src/app/models/Informe/informe_instalaciones_tratamiento';
import { InformeAlternativasFuente } from 'src/app/models/Informe/informe_alternativas_fuente';
import { AreaProtegida } from 'src/app/models/catalogos/area_protegida';
import { AreaProtegidaService } from 'src/app/services/catalogos/area_protegida.service';
import { TipoCoberturaVegetal } from 'src/app/models/catalogos/tipo_cobertura_vegetal';
import { TipoCoberturaVegetalService } from 'src/app/services/catalogos/tipo_cobertura_vegetal.service';
import { TipoSuelo } from 'src/app/models/catalogos/tipo_suelo';
import { TipoSueloService } from 'src/app/services/catalogos/tipo_suelo.service';
import { UsosAgua } from 'src/app/models/catalogos/usos_agua';
import { UsosAguaService } from 'src/app/services/catalogos/usos_agua.service';
import { InformeUsosAgua } from 'src/app/models/Informe/informe_usos_agua';
import { TipoContaminacion } from 'src/app/models/catalogos/tipo_contaminacion';
import { InformeFuentesContaminacion } from 'src/app/models/Informe/informe_fuentes_contaminacion';
import { TipoContaminacionService } from 'src/app/services/catalogos/tipo_contaminacion.service';
import { InformeFuentesAmenazas } from 'src/app/models/Informe/informe_fuentes_amenazas';
import { TipoAmenazas } from 'src/app/models/catalogos/tipo_amenazas';
import { TipoAmenazaService } from 'src/app/services/catalogos/tipo_amenaza.service';
import { InformeMedidasAdaptacion } from 'src/app/models/Informe/informe_medidas_adaptacion';
import { TipoMedidaAdaptacion } from 'src/app/models/catalogos/tipo_medida_adaptacion';
import { TipoMedidaAdaptacionService } from 'src/app/services/catalogos/tipo_medida_adaptacion.service';
import { element } from '@angular/core/src/render3/instructions';
import { InformeObjetivosMedios } from 'src/app/models/Informe/informe_objetivos_medios';
import { InformeObjetivosMetas } from 'src/app/models/Informe/informe_objetivos_metas';
import { TipoInstalacionHigieneService } from 'src/app/services/catalogos/tipo_instalacion_higiene_service';
import { InformeInstalacionesHigiene } from 'src/app/models/Informe/informe_instalaciones_higiene';


import { FileUploadService } from 'src/app/services/file_upload.service';
import { global } from 'src/app/services/global';
import { InformeInventarioAnalisisPreliminar } from 'src/app/models/Informe/informe_inventario_analisis_preliminar';
import { InformeInventarioParametroOrganolepticos } from 'src/app/models/Informe/informe_inventario_parametros_organolepticos';
import { InformeInventarioParametrosFisicoQuimicos } from 'src/app/models/Informe/informe_inventario_parametros_fisico_quimicios';
import { InformeInventarioParametrosIndeseables } from 'src/app/models/Informe/informe_inventario_parametros_indeseables';
import { InformeInventarioParametrosMetales } from 'src/app/models/Informe/informe_inventario_parametros_metales';
import { InformeInventarioParametrosBacteriologicos } from 'src/app/models/Informe/informe_inventario_parametros_bacteriologicos';
import { InformeInventarioPropuestaAlternativa } from 'src/app/models/Informe/informe_inventario_propuesta_alternativa';
import { EstadoLegalFuenteService } from 'src/app/services/catalogos/estado_legal_fuente.service';
import { EstadoLegalFuente} from 'src/app/models/catalogos/estado_legal_fuente';
import { InformeInventarioCorberturaForestal } from 'src/app/models/Informe/informe_inventario_cobertura_forestal';
import { InformeActividadAgricolaProyecto } from 'src/app/models/Informe/infome_actividad_agricola_proyecto';
import { InformeControlPlagas } from 'src/app/models/Informe/informe_control_plagas';
import { InformeFertilizantes } from 'src/app/models/Informe/informe_fertilizantes';




@Component({
  selector: 'app-informe-n',
  templateUrl: './informe-n.component.html',
  styleUrls: ['./informe-n.component.css'],
  providers: [
    ToastrService,
    UserService,
    InformeService,
    DepartamentoService,
    MunicipioService,
    ComunidadService,
    TipoPoblacionService,
    CategorizacionNivelService,
    CategorizacionTipoSaneamientoService,
    CategorizacionTipoIntervencionService,
    ModalidadService,
    TipoOrganizacionService,
    ActividadEconomicaService,
    TipoServicioService,
    TipoRevestimientoService,
    TipoMediosTransporteService,
    ConcesionarioService,
    TipoLineaService,
    TipoFaseService,
    TipoRelieveService,
    GeotectonicaSueloService,
    TipoFuenteAguaService,
    ParametroCalidadService,
    TipoMaterialService,
    TipoInstalacionAguaService,
    TipoRedService,
    NivelServicioService,
    TipoTratamientoService,
    TipoInstalacionSaneamientoService,
    TipoInstalacionHigieneService,
    TipoClimaService,
    TipoAcuiferoService,
    TipoObraService,
    MetodoAforoService,
    TipoSistemaService,
    TipoBombaService,
    EtniaService,
    AreaProtegidaService,
    TipoCoberturaVegetalService,
    TipoSueloService,
    UsosAguaService,
    TipoContaminacionService,
    TipoAmenazaService,
    TipoMedidaAdaptacionService,
    FileUploadService,
    EstadoLegalFuenteService,
    TipoMediosTransporte,
  ],
})
export class InformeNComponent implements OnInit, DoCheck {

  public _last_save = 'Sin guardar';
  public _estado = 'Borrador';
  public _url = '/informeN';
  public dist_total = 0 ;
  public identity;
  public token;
  public informe = new InformeN(1);
  public file_ubicacion_imagen: File;
  public img_ubicacion_imagen: any;
  public file_ubicacion_micro: File;
  public img_ubicacion_micro: any;
  public file_bancos_materiales_imagen: File;
  public img_bancos_materiales_imagen: any;
  public file_sin_proyecto_imagen: File;
  public img_sin_proyecto_imagen: any;
  public file_ubicacion_bd: File;
  public img_ubicacion_bd: any;
  public file_ubicacion_doc_revisado: File;
  public img_ubicacion_doc_revisado: any;
  public file_ubicacion_fichahidri: File;
  public img_ubicacion_fichahidri: any;
  public file_ubicacion_img_energia: File;
  public img_ubicacion_img_energia: any;
  public file_cobertura_forestal_imagen: File;
  public img_cobertura_forestal_imagen: any;
  public file_ubicacion_terreno: File;
  public img_ubicacion_terreno: any;
  public file_ubicacion_fich_tecnica: File;
  public img_ubicacion_fich_tecnica: any;
  public file_ubicacion_infome_bd: File;
  public img_ubicacion_infome_bd: any;



  public _categoria_pobreza = '';
  public _infome_actividad_agricola_proyecto = new InformeActividadAgricolaProyecto;
  public _informe_control_plagas = new InformeControlPlagas;
  public _informe_fertilizantes = new InformeFertilizantes;
  public _informe_correo = new InformeCorreos;
  public _informe_telefono = new InformeTelefonos;
  public _informe_comunidades = new InformeComunidades;
  public _informe_distancias = new InformeDistancias;
  public _informe_vias_acceso = new InformeViasAcceso;
  public _informe_actividad_fortalecimiento = new InformeActividadFortalecimiento;
  public _informe_actividad_economica = new InformeActividadEconomica;
  public _informe_servicio_existente = new InformeServicioExistente;
  public _informe_organizacion = new InformeOrganizacion;
  public _informe_geotecnica_suelo = new InformeGeotecnicaSuelo;
  public _informe_banco_material = new InformeBancoMaterial;
  public _informe_instalacion_agua = new InformeInstalacionesAgua;
  public _informe_distribucion_red = new InformeDistribucionesRedes;
  public _informe_nivel_servicio = new InformeNivelServicio;
  public _informe_instalacion_saneamiento = new InformeInstalacionesSaneamiento;
  public _informe_instalacion_higiene = new InformeInstalacionesHigiene;
  public _informe_instalacion_tratamiento = new InformeInstalacionesTratamiento;
  public _informe_pd_nivel_servicio = new InformePdNivelServicio;
  public _informe_estacion_precipitacion = new InformeEstacionPrecipitacion;
  public _informe_medio_hg_superficial = new InformeMedioHgSuperficial;
  public _informe_medio_hg_subterraneo = new InformeMedioHgSubterraneo;
  public _informe_inventario_cobertura_forestal = new InformeInventarioCorberturaForestal;
  public _informe_inventario_fuente_superficial = new InformeInventarioFuenteSuperficial;
  public _informe_inventario_fuente_subterranea = new InformeInventarioFuenteSubterranea;
  public _informe_inventario_analisis_preliminar = new InformeInventarioAnalisisPreliminar;
  public _informe_inventario_parametros_organolepticos = new InformeInventarioParametroOrganolepticos;
  public _informe_inventario_parametros_fisico_quimicios = new InformeInventarioParametrosFisicoQuimicos;
  public _informe_inventario_parametros_indeseables = new InformeInventarioParametrosIndeseables;
  public _informe_inventario_parametros_metales = new InformeInventarioParametrosMetales;
  public _informe_inventario_parametros_bacteriologicos = new InformeInventarioParametrosBacteriologicos;
  public _informe_inventario_propuesta_alternativa = new InformeInventarioPropuestaAlternativa;
  public _informe_potencial_hidrico_superfical = new InformePotencialHidricoSuperficial;
  public _informe_potencial_hidrico_subterraneo = new InformePotencialHidricoSubterraneo;
  public _informe_alternativas_fuente = new InformeAlternativasFuente;
  public _informe_usos_agua = new InformeUsosAgua;
  public _informe_fuentes_contaminacion = new InformeFuentesContaminacion;
  public _informe_fuentes_amenazas = new InformeFuentesAmenazas;
  public _informe_medida_adaptacion = new InformeMedidasAdaptacion;
  public _informe_objetivos_medios = new InformeObjetivosMedios;
  public _informe_objetivos_metas = new InformeObjetivosMetas;

  public _departamentos: [Departamento] = <any>[];
  public _municipios: [Municipio] = <any>[];
  public _comunidades: [Comunidad] = <any>[];
  public _tipos_poblacion: [TipoPoblacion] = <any>[];
  public _categorizacion_niveles: [CategorizacionNivel] = <any>[];
  public _categorizacion_tipos_saneamiento: [CategorizacionTipoSaneamiento] = <any>[];
  public _categorizacion_tipos_intervencion: [CategorizacionTipoIntervencion] = <any>[];
  public _modalidades: [Modalidad] = <any>[];
  public _etnias: [Etnia] = <any>[];
  public _estado_legal_fuente: [EstadoLegalFuente] = <any>[];
  public _tipos_organizacion: [TipoOrganizacion] = <any>[];
  public _actividades_economicas: [ActividadEconomica] = <any>[];
  public _tipos_servicio: [TipoServicio] = <any>[];
  public _tipos_revestimiento: [TipoRevestimiento] = <any>[];
  public _tipos_medios_transporte: [TipoMediosTransporte] = <any>[];
  public _concesionarios: [Concesionario] = <any>[];
  public _tipos_linea: [TipoLinea] = <any>[];
  public _tipos_fase: [TipoFase] = <any>[];
  public _tipos_relieve: [TipoRelieve] = <any>[];
  public _geotecnicas_suelo: [GeotecnicaSuelo] = <any>[];
  public _tipos_fuente_agua: [TipoFuenteAgua] = <any>[];
  public _parametros_calidad: [ParametroCalidad] = <any>[];
  public _tipos_material: [TipoMaterial] = <any>[];
  public _tipos_instalacion_agua: [TipoInstalacionAgua] = <any>[];
  public _tipos_red: [TipoRed] = <any>[];
  public _niveles_servicio: [NivelServicio] = <any>[];
  public _tipos_tratamiento: [TipoTratamiento] = <any>[];
  public _tipos_instalacion_sanemiento: [TipoInstalacionSaneamiento] = <any>[];
  public _tipos_instalacion_higiene: [TipoInstalacionHigiene] = <any>[];
  public _tipos_clima: [TipoClima] = <any>[];
  public _tipos_acuifero: [TipoAcuifero] = <any>[];
  public _tipos_obra: [TipoObra] = <any>[];
  public _metodos_aforo: [MetodoAforo] = <any>[];
  public _tipos_sistema: [TipoSistema] = <any>[];
  public _tipos_bomba: [TipoBomba] = <any>[];
  public _areas_protegidas: [AreaProtegida] = <any>[];
  public _tipos_cobertura_vegetal: [TipoCoberturaVegetal] = <any>[];
  public _tipos_suelo: [TipoSuelo] = <any>[];
  public _usos_agua: [UsosAgua] = <any>[];
  public _tipos_contaminacion: [TipoContaminacion] = <any>[];
  public _tipos_amenazas: [TipoAmenazas] = <any>[];
  public _tipos_medidas_adaptacion = <any>[];

  constructor(
    private _toastr: ToastrService,
    private _user_service: UserService,
    private _informe_service: InformeService,
    private _departamento_service: DepartamentoService,
    private _municipio_service: MunicipioService,
    private _comunidad_service: ComunidadService,
    private _tipo_poblacion_service: TipoPoblacionService,
    private _categorizacion_nivel_service: CategorizacionNivelService,
    private _categorizacion_tipo_saneamiento: CategorizacionTipoSaneamientoService,
    private _categorizacion_tipo_intervencion: CategorizacionTipoIntervencionService,
    private _modalidad_service: ModalidadService,
    private _tipo_organizacion_service: TipoOrganizacionService,
    private _actividad_economica_service: ActividadEconomicaService,
    private _tipo_servicio_service: TipoServicioService,
    private _tipo_revestimiento_service: TipoRevestimientoService,
    private _tipo_medio_transporte_service: TipoMediosTransporteService,
    private _concesionario_service: ConcesionarioService,
    private _tipo_linea_service: TipoLineaService,
    private _tipo_fase_service: TipoFaseService,
    private _tipo_relieve_service: TipoRelieveService,
    private _geotectonica_suelo_service: GeotectonicaSueloService,
    private _tipo_fuente_agua_service: TipoFuenteAguaService,
    private _parametro_calidad_service: ParametroCalidadService,
    private _tipo_materialservice: TipoMaterialService,
    private _tipo_instalacion_agua_service: TipoInstalacionAguaService,
    private _tipo_red_service: TipoRedService,
    private _nivel_servicio_service: NivelServicioService,
    private _tipo_tratamiento_service: TipoTratamientoService,
    private _tipo_instalacion_saneamiento_service: TipoInstalacionSaneamientoService,
    private _tipo_instalacion_higiene_service: TipoInstalacionHigieneService,
    private _tipo_clima_service: TipoClimaService,
    private _tipo_acuifero_service: TipoAcuiferoService,
    private _tipo_obra_service: TipoObraService,
    private _metodo_aforo_service: MetodoAforoService,
    private _tipo_sistema_service: TipoSistemaService,
    private _tipo_bomba_service: TipoBombaService,
    private _etnia_service: EtniaService,
    private _estado_legal_fuente_service: EstadoLegalFuenteService,
    private _area_protegida_service: AreaProtegidaService,
    private _tipo_cobertura_vegetal_service: TipoCoberturaVegetalService,
    private _tipo_suelo_service: TipoSueloService,
    private _usos_agua_service: UsosAguaService,
    private _tipo_contaminacion_service: TipoContaminacionService,
    private _tipo_amenaza_service: TipoAmenazaService,
    private _tipo_medida_adaptacion: TipoMedidaAdaptacionService,
    private _file_upload_service: FileUploadService,
  ) { }

  ngOnInit() {
    this.identity = this._user_service.getIdentity();
    this.token = this._user_service.getToken();
    this.informe.id_usuario = this.identity.id;
     // Seccion 2
     this.img_ubicacion_imagen = this.informe.ubicacion_imagen;
     this.img_ubicacion_micro = this.informe.ubicacion_micro;
     // Seccion 4
     this.img_ubicacion_infome_bd = this.informe.ubicacion_infome_bd; // Seccion 4
     this.img_ubicacion_bd = this.informe.ubicacion_bd;
     // Seccion 5.1
     this.img_ubicacion_doc_revisado = this.informe.ubicacion_doc_revisado;
     // Seccion 5.2
     this.img_ubicacion_fichahidri = this.informe.ubicacion_fichahidri;
     // seccion 5.3
     this.img_cobertura_forestal_imagen = this.informe.cobertura_forestal_imagen;
     this.img_bancos_materiales_imagen = this.informe.bancos_materiales_imagen;
     // Seccion 5.4
     this.img_ubicacion_img_energia = this.informe.ubicacion_img_energia;
     this.img_ubicacion_fich_tecnica = this.informe.ubicacion_fich_tecnica;
     // Seccion 5.5
     this.img_ubicacion_terreno = this.informe.ubicacion_terreno_img;
     // Seccion 5.6
     this.img_sin_proyecto_imagen = this.informe.sin_proyecto_imagen;
    this.load_catalogos();
  }
  async ngDoCheck() {
    if (this.informe.m1s1_completa && this.informe.id === 0) {
      this.save();
    } else {
      this.informe.check_m1_completa();
      this.informe.check_m2_completa();
      this.informe.check_m3_completa();
      this.estado_change();
    }
  }
  //#region add ()
  addActividadAgricolaProyecto(){
    if(this._infome_actividad_agricola_proyecto.fuente.trim() !== ''
    && this._infome_actividad_agricola_proyecto.tipo_fuente != null
    && this._infome_actividad_agricola_proyecto.descripcion.trim() !== '')
    {
      this._infome_actividad_agricola_proyecto.id_informe = this.informe.id;
      this.informe.infome_actividad_agricola_proyecto.push(this._infome_actividad_agricola_proyecto);
      this._infome_actividad_agricola_proyecto = new InformeActividadAgricolaProyecto();
    }
  }
  addControlPlagas(){
    if(this._informe_control_plagas.fuente.trim() !== ''
    && this._informe_control_plagas.tipo_fuente != null
    && this._informe_control_plagas.descripcion.trim() !== '')
    {
      this._informe_control_plagas.id_informe = this.informe.id;
      this.informe.informe_control_plagas.push(this._informe_control_plagas);
      this._informe_control_plagas = new InformeControlPlagas();
    }
  }
  addFertilizantes(){
    if(this._informe_fertilizantes.fuente.trim() !== ''
    && this._informe_fertilizantes.tipo_fuente != null
    && this._informe_fertilizantes.descripcion.trim() !== '')
    {
      this._informe_fertilizantes.id_informe = this.informe.id;
      this.informe.informe_fertilizantes.push(this._informe_fertilizantes);
      this._informe_fertilizantes = new InformeFertilizantes();
    }
  }
  addInformeCorreo() {
    if (this._informe_correo.correo !== '') {
      this._informe_correo.id_informe = this.informe.id;
      this.informe.informe_correos.push(this._informe_correo);
      this._informe_correo = new InformeCorreos;

    }
  }
 /* addInformeTelefono() {
    if (this._informe_telefono.telefono !== '') {
      this._informe_telefono.id_informe = this.informe.id;
      this.informe.informe_telefonos.push(this._informe_telefono);
      this._informe_telefono = new InformeTelefonos;

    }
  }*/
  addInformeComunidad() {
    if (this._informe_comunidades.comunidad !== null
      && this._informe_comunidades.tipo_poblacion !== null
      && this._informe_comunidades.etnia !== null
      && this._informe_comunidades.coordenada_zona !== ''
      && this._informe_comunidades.coordenada_x !== ''
      && this._informe_comunidades.coordenada_y !== ''
      && this._informe_comunidades.coordenada_z !== '') {
      this._informe_comunidades.id_informe = this.informe.id;
      this.informe.informe_comunidades.push(this._informe_comunidades);
      this._informe_comunidades = new InformeComunidades;
    }
  }
  addInformeDistancia() {
    if (this._informe_distancias.comunidad !== null
      && this._informe_distancias.distancia_departamental > 0
      && this._informe_distancias.distancia_municipal > 0
      && this._informe_distancias.distancia_managua > 0
      && this._informe_distancias.tiempo_vehiculo > 0
      && this._informe_distancias.tiempo_camion > 0
      && this._informe_distancias.distancia_dificultad !== 0) {
      this._informe_distancias.id_informe = this.informe.id;
      this.informe.informe_distancias.push(this._informe_distancias);
      this._informe_distancias = new InformeDistancias;
    }
  }
  addInformeViasAcceso() {
    if (this._informe_vias_acceso.comunidad !== null
      && this._informe_vias_acceso.tipo_revestimiento !== null
      && this._informe_vias_acceso.tipo_medios_transporte !== null
      && this._informe_vias_acceso.origen !== ''
      &&  this._informe_vias_acceso.destino !== ''
      && this._informe_vias_acceso.distancia > 0
      && this._informe_vias_acceso.tiempo > 0) {
      this._informe_vias_acceso.id_informe = this.informe.id;
      this.informe.informe_vias_acceso.push(this._informe_vias_acceso);
      this._informe_vias_acceso = new InformeViasAcceso();
    }
  }
  addInformeOrganizacion() {
    if (this._informe_organizacion.tipo_organizacion != null
      && this._informe_organizacion.nombre.trim().length > 0) {
      this._informe_organizacion.id_informe = this.informe.id;
      this._informe_organizacion.tipo_organizacion = this._informe_organizacion.tipo_organizacion;
      this.informe.informe_organizacion.push(this._informe_organizacion);
      this._informe_organizacion = new InformeOrganizacion;
    }
  }
  /*addInformeActividad() {
    if (this._informe_actividad_fortalecimiento.actividad !== '') {
      this._informe_actividad_fortalecimiento.id_informe = this.informe.id;
      this.informe.informe_actividades_fortalecimiento.push(this._informe_actividad_fortalecimiento);
      this._informe_actividad_fortalecimiento = new InformeActividadFortalecimiento();
    }
  }*/
  addInformeActividadEconomica(obj) {
    if (this._informe_actividad_economica.actividad_economica !== null
      && this._informe_actividad_economica.cantidad !== 0) {
      this._informe_actividad_economica.id_informe = this.informe.id;
      this._informe_actividad_economica.porcentaje = (this._informe_actividad_economica.cantidad / obj ) * 100 ;
      this._informe_actividad_economica.porcentaje.toFixed(2);
      this.informe.informe_actividades_economicas.push(this._informe_actividad_economica);
      this._informe_actividad_economica = new InformeActividadEconomica();
    }
  }
  addInformeServicioExistente() {
    if (this._informe_servicio_existente.tipo_servicio != null
      && this._informe_servicio_existente.cantidad > 0
      && this._informe_servicio_existente.estado !== '') {
      this._informe_servicio_existente.id_informe = this.informe.id;
      this.informe.informe_servicios_existentes.push(this._informe_servicio_existente);
      this._informe_servicio_existente = new InformeServicioExistente();
    }
  }
  addInformeGeotectonicaSuelo() {
    if (this._informe_geotecnica_suelo.geotecnica_suelo != null) {
      this._informe_geotecnica_suelo.id_informe = this.informe.id;
      this.informe.informe_geotecnica_suelo.push(this._informe_geotecnica_suelo);
      this._informe_geotecnica_suelo = new InformeGeotecnicaSuelo();
    }
  }
  addInformeBancoMaterial() {
    if (this._informe_banco_material.nombre.trim() !== ''
      && this._informe_banco_material.duenno.trim() !== ''
      && this._informe_banco_material.tipo_tenencia.trim() !== ''
      && this._informe_banco_material.estado_via_acceso.trim() !== ''
      && this._informe_banco_material.tipo_material != null
      && this._informe_banco_material.coordenada_zona.trim() !== ''
      && this._informe_banco_material.coordenada_x.trim() !== ''
      && this._informe_banco_material.coordenada_y.trim() !== ''
      && this._informe_banco_material.coordenada_z.trim() !== '') {
      this._informe_banco_material.id_informe = this.informe.id;
      this.informe.informe_bancos_materiales.push(this._informe_banco_material);
      this._informe_banco_material = new InformeBancoMaterial();

    }
  }
  addInformeInstalacionAgua() {
    if (this._informe_instalacion_agua.tipo_instalacion_agua != null
      && this._informe_instalacion_agua.estado !== ''
      && this._informe_instalacion_agua.descripcion_estado.trim() !== ''
      && this._informe_instalacion_agua.caracteristicas.trim() !== '') {
      this._informe_instalacion_agua.id_informe = this.informe.id;
      this.informe.informe_instalaciones_agua.push(this._informe_instalacion_agua);
      this._informe_instalacion_agua = new InformeInstalacionesAgua();
    }
  }
  addInformeDistribucionRed() {
    if (this._informe_distribucion_red.tipo_red != null
      && this._informe_distribucion_red.estado.trim() !== ''
      && this._informe_distribucion_red.diametro !== 0
      && this._informe_distribucion_red.longitud !== 0
      && this._informe_distribucion_red.caracteristicas.trim() !== ''
    ) {
      this._informe_distribucion_red.id_informe = this.informe.id;
      this.informe.informe_distribuciones_redes.push(this._informe_distribucion_red);
      this._informe_distribucion_red = new InformeDistribucionesRedes();
    }
  }
  addInformeNivelServicio() {
    if (this._informe_nivel_servicio.nivel_servicio != null
      && this._informe_nivel_servicio.estado.trim() !== ''
      && this._informe_nivel_servicio.cantidad !== 0
      && this._informe_nivel_servicio.poblacion_atendida !== 0
      && this._informe_nivel_servicio.viviendas_atendidas !== 0
      && this._informe_nivel_servicio.caracteristicas.trim() !== '') {
      this._informe_nivel_servicio.id_informe = this.informe.id;
      this.informe.informe_nivel_servicio.push(this._informe_nivel_servicio);
      this._informe_nivel_servicio = new InformeNivelServicio();
    }
  }
  addInformeInstalacionSaneamiento() {
    if (this._informe_instalacion_saneamiento.tipo_instalacion_saneamiento != null
      && this._informe_instalacion_saneamiento.estado.trim() !== ''
      && this._informe_instalacion_saneamiento.caracteristicas.trim() !== ''
      && this._informe_instalacion_saneamiento.cantidad !== 0
      && this._informe_instalacion_saneamiento.poblacion_atendida !== 0
      && this._informe_instalacion_saneamiento.viviendas_atendidas !== 0
    ) {
      this._informe_instalacion_saneamiento.id_informe = this.informe.id;
      this.informe.informe_instalaciones_saneamiento.push(this._informe_instalacion_saneamiento);
      this._informe_instalacion_saneamiento = new InformeInstalacionesSaneamiento();
    }
  }
  addInformeInstalacionHigiene() {
    if (this._informe_instalacion_higiene.tipo_instalacion_higiene != null
      && this._informe_instalacion_higiene.estado.trim() !== ''
      && this._informe_instalacion_higiene.caracteristicas.trim() !== ''
      && this._informe_instalacion_higiene.cantidad !== 0
      && this._informe_instalacion_higiene.poblacion_atendida !== 0
      && this._informe_instalacion_higiene.viviendas_atendidas !== 0
    ) {
      this._informe_instalacion_higiene.id_informe = this.informe.id;
      this.informe.informe_instalaciones_higiene.push(this._informe_instalacion_higiene);
      this._informe_instalacion_higiene = new InformeInstalacionesHigiene();
    }
  }
  AddInformeInstalacionTratamiento() {
    if (this._informe_instalacion_tratamiento.tipo_tratamiento !== null
      && this._informe_instalacion_tratamiento.tipo !== ''
      && this._informe_instalacion_tratamiento.estado !== '') {
        this._informe_instalacion_tratamiento.id_informe = this.informe.id;
        this.informe.informe_instalaciones_tratamiento.push(this._informe_instalacion_tratamiento);
        this._informe_instalacion_tratamiento = new InformeInstalacionesTratamiento;
    }
  }
  addInformePdNivelServicio() {
    if (this._informe_pd_nivel_servicio.servicio != null) {
      this._informe_pd_nivel_servicio.id_informe = this.informe.id;
      this.informe.informe_pd_nivel_servicio.push(this._informe_pd_nivel_servicio);
      this._informe_pd_nivel_servicio = new InformePdNivelServicio();
    }
  }
  addInformeMedioHgSuperficial() {
    if (this._informe_medio_hg_superficial.nombre.trim() !== ''
      && this._informe_medio_hg_superficial.codigo_pfastetter.trim() !== ''
      && this._informe_medio_hg_superficial.area > 0
      && this._informe_medio_hg_superficial.longitud_rio_principal > 0
      && this._informe_medio_hg_superficial.longitud_rio_total > 0
      && this._informe_medio_hg_superficial.altura_maxima > 0
      && this._informe_medio_hg_superficial.altura_minima > 0
      && this._informe_medio_hg_superficial.tiempo_concentracion_minutos > 0
      && this._informe_medio_hg_superficial.tiempo_concentracion_horas > 0
      && this._informe_medio_hg_superficial.factor_forma > 0
      && this._informe_medio_hg_superficial.pendiente > 0
      && this._informe_medio_hg_superficial.coeficiente_compacidad > 0
      && this._informe_medio_hg_superficial.densidad_drenaje > 0
      && this._informe_medio_hg_superficial.rios_primer_orden > 0
      && this._informe_medio_hg_superficial.rios_total > 0
      && this._informe_medio_hg_superficial.coeficiente_torrencialidad > 0
      && this._informe_medio_hg_superficial.extension_escurrimiento > 0
      && this._informe_medio_hg_superficial.frecuencia_rios > 0
      && this._informe_medio_hg_superficial.cuenca_orden > 0) {
      this._informe_medio_hg_superficial.id_informe = this.informe.id;
      this.informe.informe_medio_hg_superficial.push(this._informe_medio_hg_superficial);
      this._informe_medio_hg_superficial = new InformeMedioHgSuperficial();
    }
  }
  addInformeMedioHgSubterraneo() {
    if (this._informe_medio_hg_subterraneo.nombre.trim() !== ''
      && this._informe_medio_hg_subterraneo.tipo_acuifero != null
      && this._informe_medio_hg_subterraneo.espesor > 0
      && this._informe_medio_hg_subterraneo.transmisividad > 0
      && this._informe_medio_hg_subterraneo.conductividad > 0) {
      this._informe_medio_hg_subterraneo.id_informe = this.informe.id;
      this.informe.informe_medio_hg_subterraneo.push(this._informe_medio_hg_subterraneo);
      this._informe_medio_hg_subterraneo = new InformeMedioHgSubterraneo();
    }
  }
  addInformeEstacionPrecipitacion() {
    if (this._informe_estacion_precipitacion.estacion.trim() !== ''
      && this._informe_estacion_precipitacion.periodo.trim() !== ''
      && this._informe_estacion_precipitacion.coordenada_zona.trim() !== ''
      && this._informe_estacion_precipitacion.coordenada_x > 0
      && this._informe_estacion_precipitacion.coordenada_y > 0
      && this._informe_estacion_precipitacion.coordenada_z > 0
      && this._informe_estacion_precipitacion.precipitacion_ene > 0
      && this._informe_estacion_precipitacion.precipitacion_feb > 0
      && this._informe_estacion_precipitacion.precipitacion_mar > 0
      && this._informe_estacion_precipitacion.precipitacion_abr > 0
      && this._informe_estacion_precipitacion.precipitacion_may > 0
      && this._informe_estacion_precipitacion.precipitacion_jun > 0
      && this._informe_estacion_precipitacion.precipitacion_jul > 0
      && this._informe_estacion_precipitacion.precipitacion_ago > 0
      && this._informe_estacion_precipitacion.precipitacion_sep > 0
      && this._informe_estacion_precipitacion.precipitacion_oct > 0
      && this._informe_estacion_precipitacion.precipitacion_nov > 0
      && this._informe_estacion_precipitacion.precipitacion_dic > 0) {
      this._informe_estacion_precipitacion.id_informe = this.informe.id;
      this.informe.informe_estacion_precipitacion.push(this._informe_estacion_precipitacion);
      this._informe_estacion_precipitacion = new InformeEstacionPrecipitacion();
    }
  }
  addInformeInventarioFuenteSuperficial() {
    if (this._informe_inventario_fuente_superficial.fuente.trim() !== ''
      && this._informe_inventario_fuente_superficial.tipo_fuente != null
      && this._informe_inventario_fuente_superficial.tipo_obra_captacion != null
      && this._informe_inventario_fuente_superficial.tipo_sistema != null
      && this._informe_inventario_fuente_superficial.metodo_aforo != null
      && this._informe_inventario_fuente_superficial.coordenada_zona.trim() !== ''
      && this._informe_inventario_fuente_superficial.coordenada_x !== ''
      && this._informe_inventario_fuente_superficial.coordenada_y !== ''
      && this._informe_inventario_fuente_superficial.coordenada_z !== ''
    //  && this._informe_inventario_fuente_superficial.ancho > 0
    //  && this._informe_inventario_fuente_superficial.tirante > 0
      && this._informe_inventario_fuente_superficial.caudal > 0) {
      this._informe_inventario_fuente_superficial.id_informe = this.informe.id;
      this.informe.informe_inventario_fuente_superficial.push(this._informe_inventario_fuente_superficial);
      this._informe_inventario_fuente_superficial = new InformeInventarioFuenteSuperficial();
    }
  }
  addInformeInventarioFuenteSubterranea() {
    if (this._informe_inventario_fuente_subterranea.fuente.trim() !== ''
      && this._informe_inventario_fuente_subterranea.tipo_fuente != null
      && this._informe_inventario_fuente_subterranea.tipo_bomba != null
      && this._informe_inventario_fuente_subterranea.tipo_registro !== ''
      && this._informe_inventario_fuente_subterranea.coordenada_zona !== ''
      && this._informe_inventario_fuente_subterranea.coordenada_x !== '' 
      && this._informe_inventario_fuente_subterranea.coordenada_y !== '' 
      && this._informe_inventario_fuente_subterranea.coordenada_z !== '' 
      && this._informe_inventario_fuente_subterranea.diametro > 0
      && this._informe_inventario_fuente_subterranea.pt >= 0
      && this._informe_inventario_fuente_subterranea.nea >= 0
      && this._informe_inventario_fuente_subterranea.columna >= 0
      && this._informe_inventario_fuente_subterranea.caudal >= 0
      && this._informe_inventario_fuente_subterranea.regimen > 0) {
      this._informe_inventario_fuente_subterranea.id_informe = this.informe.id;
      this.informe.informe_inventario_fuente_subterranea.push(this._informe_inventario_fuente_subterranea);
      this._informe_inventario_fuente_subterranea = new InformeInventarioFuenteSubterranea();

    }
  }
  
  addInformeInventarioAnalisisPreliminar() {
    if (this._informe_inventario_analisis_preliminar.fuente.trim() !== ''
      && this._informe_inventario_analisis_preliminar.tipo_fuente != null
      && this._informe_inventario_analisis_preliminar.pH != null
      && this._informe_inventario_analisis_preliminar.temperatura != null
      && this._informe_inventario_analisis_preliminar.ce != null
      && this._informe_inventario_analisis_preliminar.sdt != null
      && this._informe_inventario_analisis_preliminar.turbidez != null
      && this._informe_inventario_analisis_preliminar.salinidad != null
      && this._informe_inventario_analisis_preliminar.hierro != null
      && this._informe_inventario_analisis_preliminar.cloro_total != null
      && this._informe_inventario_analisis_preliminar.cloro_residual != null) {

      this._informe_inventario_analisis_preliminar.id_informe = this.informe.id;
      this.informe.informe_inventario_analisis_preliminar.push(this._informe_inventario_analisis_preliminar);
      this._informe_inventario_analisis_preliminar = new InformeInventarioAnalisisPreliminar();


    }

  }

  addInformeInventarioOrganolepticos() {
    if (this._informe_inventario_parametros_organolepticos.fuente.trim() !== ''
      && this._informe_inventario_parametros_organolepticos.tipo_fuente != null
      && this._informe_inventario_parametros_organolepticos.aspecto !== ''
      && this._informe_inventario_parametros_organolepticos.color > 0
      && this._informe_inventario_parametros_organolepticos.turbidez != null) {

      this._informe_inventario_analisis_preliminar.id_informe = this.informe.id;
      this.informe.informe_inventario_parametros_organolepticos.push(this._informe_inventario_parametros_organolepticos);
      this._informe_inventario_parametros_organolepticos = new InformeInventarioParametroOrganolepticos();

    }

  }
  
  addInformeInventarioFisicoQuimicos() {
    if (this._informe_inventario_parametros_fisico_quimicios.fuente.trim() !== ''
      && this._informe_inventario_parametros_fisico_quimicios.tipo_fuente != null
      && this._informe_inventario_parametros_fisico_quimicios.bdo != null
      && this._informe_inventario_parametros_fisico_quimicios.temperatura != null
      && this._informe_inventario_parametros_fisico_quimicios.pH != null
      && this._informe_inventario_parametros_fisico_quimicios.cloruros != null
      && this._informe_inventario_parametros_fisico_quimicios.sulfatos != null
      && this._informe_inventario_parametros_fisico_quimicios.calcio != null
      && this._informe_inventario_parametros_fisico_quimicios.magnesio != null
      && this._informe_inventario_parametros_fisico_quimicios.od != null
      && this._informe_inventario_parametros_fisico_quimicios.ce != null
      && this._informe_inventario_parametros_fisico_quimicios.sodio != null
      && this._informe_inventario_parametros_fisico_quimicios.potasio != null
      && this._informe_inventario_parametros_fisico_quimicios.aluminio != null
      && this._informe_inventario_parametros_fisico_quimicios.dureza_total != null 
      && this._informe_inventario_parametros_fisico_quimicios.sdt != null){

      this._informe_inventario_parametros_fisico_quimicios.id_informe = this.informe.id;
      this.informe.informe_inventario_parametros_fisico_quimicios.push(this._informe_inventario_parametros_fisico_quimicios);
      this._informe_inventario_parametros_fisico_quimicios = new InformeInventarioParametrosFisicoQuimicos();


    }

  }

  addInformeInventarioIndeseables() {
    if (this._informe_inventario_parametros_indeseables.fuente.trim() !== ''
      && this._informe_inventario_parametros_indeseables.tipo_fuente != null
      && this._informe_inventario_parametros_indeseables.nitratos != null
      && this._informe_inventario_parametros_indeseables.nitritos != null
      && this._informe_inventario_parametros_indeseables.amonio != null
      && this._informe_inventario_parametros_indeseables.hierro_total != null
      && this._informe_inventario_parametros_indeseables.manganeso != null
      && this._informe_inventario_parametros_indeseables.cobre != null
      && this._informe_inventario_parametros_indeseables.fosforo != null
      && this._informe_inventario_parametros_indeseables.fluoruros != null
      && this._informe_inventario_parametros_indeseables.zinc != null
      ){

      this._informe_inventario_parametros_indeseables.id_informe = this.informe.id;
      this.informe.informe_inventario_parametros_indeseables.push(this._informe_inventario_parametros_indeseables);
      this._informe_inventario_parametros_indeseables = new InformeInventarioParametrosIndeseables();
    }
  }
  
  addInformeInventarioMetales() {
    if (this._informe_inventario_parametros_metales.fuente.trim() !== ''
      && this._informe_inventario_parametros_metales.tipo_fuente != null
      && this._informe_inventario_parametros_metales.arsenico > 0
      && this._informe_inventario_parametros_metales.cianuro > 0
      && this._informe_inventario_parametros_metales.mercurio != null
      && this._informe_inventario_parametros_metales.plomo != null) {

      this._informe_inventario_parametros_metales.id_informe = this.informe.id;
      this.informe.informe_inventario_parametros_metales.push(this._informe_inventario_parametros_metales);
      this._informe_inventario_parametros_metales = new InformeInventarioParametrosMetales();

    }
  }
  
  addInformeInventarioBacteriologicos() {
    if (this._informe_inventario_parametros_bacteriologicos.fuente.trim() !== ''
      && this._informe_inventario_parametros_bacteriologicos.tipo_fuente != null
      && this._informe_inventario_parametros_bacteriologicos.coliformes_totales > 0
      && this._informe_inventario_parametros_bacteriologicos.e_coli > 0
      && this._informe_inventario_parametros_bacteriologicos.termotolerantes != null
     ) {

      this._informe_inventario_parametros_bacteriologicos.id_informe = this.informe.id;
      this.informe.informe_inventario_parametros_bacteriologicos.push(this._informe_inventario_parametros_bacteriologicos);
      this._informe_inventario_parametros_bacteriologicos = new InformeInventarioParametrosBacteriologicos();

    }
  }
  

  addInformeInventarioPropuestaAlternativa() {
    if (this._informe_inventario_propuesta_alternativa.fuente.trim() !== ''
      && this._informe_inventario_propuesta_alternativa.tipo_fuente != null
      && this._informe_inventario_propuesta_alternativa.coordenada_zona.trim() !== ''
      && this._informe_inventario_propuesta_alternativa.coordenada_x.trim() !== ''
      && this._informe_inventario_propuesta_alternativa.coordenada_y.trim() !== '' 
      && this._informe_inventario_propuesta_alternativa.coordenada_z.trim() !== '' 
      && this._informe_inventario_propuesta_alternativa.caudal > 0
      && this._informe_inventario_propuesta_alternativa.tipo_sistema != null
      && this._informe_inventario_propuesta_alternativa.tipo_bomba != null
      && this._informe_inventario_propuesta_alternativa.tipo_tratamiento != null
      && this._informe_inventario_propuesta_alternativa.estado_legal_fuente != null
      ) {
      this._informe_inventario_propuesta_alternativa.id_informe = this.informe.id;
      this.informe.informe_inventario_propuesta_alternativa.push(this._informe_inventario_propuesta_alternativa);
      this._informe_inventario_propuesta_alternativa = new InformeInventarioPropuestaAlternativa();
    }
  }

  addInformeInventarioCoberturaForestal() {
    if (this._informe_inventario_cobertura_forestal.fuente.trim() !== ''
      && this._informe_inventario_cobertura_forestal.tipo_fuente != null
      && this._informe_inventario_cobertura_forestal.bosque_denso != null
      && this._informe_inventario_cobertura_forestal.bosque_ralo != null
      && this._informe_inventario_cobertura_forestal.bosque_galeria != null
      && this._informe_inventario_cobertura_forestal.pinar != null
      && this._informe_inventario_cobertura_forestal.vegetacion != null
      && this._informe_inventario_cobertura_forestal.agropecuario != null
      && this._informe_inventario_cobertura_forestal.mangle != null
      && this._informe_inventario_cobertura_forestal.potreros != null ) 
    {

      this._informe_inventario_cobertura_forestal.id_informe = this.informe.id;
      this.informe.informe_inventario_cobertura_forestal.push(this._informe_inventario_cobertura_forestal);
      this._informe_inventario_cobertura_forestal = new InformeInventarioCorberturaForestal();
    }

  }
  

  addInformePotencialHidricoSuperficial() {
    if (this._informe_potencial_hidrico_superfical.mes.trim() !== ''
      && this._informe_potencial_hidrico_superfical.dias > 0) {
      this._informe_potencial_hidrico_superfical.id_informe = this.informe.id;
      this.informe.informe_potencial_hidrico_superficial.push(this._informe_potencial_hidrico_superfical);
      this._informe_potencial_hidrico_superfical = new InformePotencialHidricoSuperficial();
    }
  }
  AddInformeAlternativaFuente() {
    if (this._informe_alternativas_fuente.fuente.trim().length !== 0
    && this._informe_alternativas_fuente.tipo_fuente !== null
    && this._informe_alternativas_fuente.coordenada_zona !== ''
    && this._informe_alternativas_fuente.coordenada_x > 0
    && this._informe_alternativas_fuente.coordenada_y > 0
    && this._informe_alternativas_fuente.coordenada_z > 0
    && this._informe_alternativas_fuente.tipo_sistema !== null
    && this._informe_alternativas_fuente.tipo_tratamiento !== null
    && this._informe_alternativas_fuente.estado_legal !== '') {
      this._informe_alternativas_fuente.id_informe = this.informe.id;
      this.informe.informe_alternativas_fuente.push(this._informe_alternativas_fuente);
      this._informe_alternativas_fuente = new InformeAlternativasFuente;
    }
  }
  AddInformeUsoAgua() {
    if (this._informe_usos_agua.usos_agua !== null) {
      this._informe_usos_agua.id_informe = this.informe.id;
      this.informe.informe_usos_agua.push(this._informe_usos_agua);
      this._informe_usos_agua = new InformeUsosAgua;
    }
  }
  AddInformeFuenteContaminacion() {
    if (this._informe_fuentes_contaminacion.tipo_contaminacion !== null
      && this._informe_fuentes_contaminacion.descripcion !== '') {
        this._informe_fuentes_contaminacion.id_informe = this.informe.id;
        this.informe.informe_fuentes_contaminacion.push(this._informe_fuentes_contaminacion);
        this._informe_fuentes_contaminacion = new InformeFuentesContaminacion;
    }
  }
  AddInformeFuenteAmenaza() {
    if (this._informe_fuentes_amenazas.tipo_amenaza !== null
      && this._informe_fuentes_amenazas.descripcion !== '') {
        this._informe_fuentes_amenazas.id_informe = this.informe.id;
        this.informe.informe_fuentes_amenazas.push(this._informe_fuentes_amenazas);
        this._informe_fuentes_amenazas = new InformeFuentesAmenazas;
    }
  }
  AddInformeMedidaAdaptacion() {
    if (this._informe_medida_adaptacion.tipo_medida_adaptacion !== null
      && this._informe_medida_adaptacion.costo_estimado > 0) {
        this._informe_medida_adaptacion.id_informe = this.informe.id;
        this.informe.informe_medidas_adaptacion.push(this._informe_medida_adaptacion);
        this._informe_medida_adaptacion = new InformeMedidasAdaptacion;
    }
  }
  addInformeObjetivoMedios() {
    if (this._informe_objetivos_medios.objetivo_desarrollo.trim().length > 0
      && this._informe_objetivos_medios.objetivo_especifico.trim().length > 0
      && this._informe_objetivos_medios.medios.trim().length > 0
      && this._informe_objetivos_medios.proposito.trim().length > 0)  {
        this._informe_objetivos_medios.id_informe = this.informe.id;
        this.informe.informe_objetivos_medios.push(this._informe_objetivos_medios);
        this._informe_objetivos_medios = new InformeObjetivosMedios;
    }
  }
  addInformeObjetivoMetas() {
    if (this._informe_objetivos_metas.objetivo_especifico.trim().length > 0
    && this._informe_objetivos_metas.metas.trim().length > 0
    && this._informe_objetivos_metas.indicador.trim().length > 0
    && this._informe_objetivos_metas.medio_verificacion.trim().length > 0) {
      this._informe_objetivos_metas.id_informe = this.informe.id;
      this.informe.informe_objetivos_metas.push(this._informe_objetivos_metas);
      this._informe_objetivos_metas = new InformeObjetivosMetas;
    }
  }
  //#endregion add

  //#region del ()
  delInformeActividadAgricola(obj)
  {
    this.informe.infome_actividad_agricola_proyecto.forEach((item, index) => {
      if (item === obj) {
        this.informe.infome_actividad_agricola_proyecto.splice(index, 1);
      }
    });
  }
  delInformeControlPlagas(obj)
  {
    this.informe.informe_control_plagas.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_control_plagas.splice(index, 1);
      }
    });
  }
  delInformeFertilizantes(obj)
  {
    this.informe.informe_fertilizantes.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_fertilizantes.splice(index, 1);
      }
    });
  }
  delInformeInventarioCoberturaForestal(obj)
  {
    this.informe.informe_inventario_cobertura_forestal.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_inventario_cobertura_forestal.splice(index, 1);
      }
    });
  }
  delInformeCorreo(obj) {
    this.informe.informe_correos.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_correos.splice(index, 1);
      }
    });
  }
/* delInformeTelefono(obj) {
    this.informe.informe_telefonos.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_telefonos.splice(index, 1);
      }
    });
  }*/
  delInformeComunidad(obj) {
    this.informe.informe_comunidades.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_comunidades.splice(index, 1);
      }
    });
  }
  delInformeDistancia(obj) {
    this.informe.informe_distancias.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_distancias.splice(index, 1);
      }
    });
  }
  delInformeViasAcceso(obj) {
    this.informe.informe_vias_acceso.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_vias_acceso.splice(index, 1);
      }
    });
  }
 /* delInformeActividad(obj) {
    this.informe.informe_actividades_fortalecimiento.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_actividades_fortalecimiento.splice(index, 1);
      }
    });
  }*/
  delInformeOrganizacion(obj) {
    this.informe.informe_organizacion.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_organizacion.splice(index, 1);
      }
    });
  }
  delInformeActividadEconomica(obj) {
    this.informe.informe_actividades_economicas.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_actividades_economicas.splice(index, 1);
      }
    });
  }
  delInformeServicioExistente(obj) {
    this.informe.informe_servicios_existentes.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_servicios_existentes.splice(index, 1);
      }
    });
  }
  delInformeGeotectonicaSuelo(obj) {
    this.informe.informe_geotecnica_suelo.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_geotecnica_suelo.splice(index, 1);
      }
    });
  }
  delInformeBancoMaterial(obj) {
    this.informe.informe_bancos_materiales.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_bancos_materiales.splice(index, 1);
      }
    });
  }
  delInformeInstalacionAgua(obj) {
    this.informe.informe_instalaciones_agua.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_instalaciones_agua.splice(index, 1);
      }
    });
  }
  delInformeDistribucionRed(obj) {
    this.informe.informe_distribuciones_redes.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_distribuciones_redes.splice(index, 1);
      }
    });
  }
  delInformeNivelServicio(obj) {
    this.informe.informe_nivel_servicio.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_nivel_servicio.splice(index, 1);
      }
    });
  }
  delInformeInstalacionSaneamiento(obj) {
    this.informe.informe_instalaciones_saneamiento.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_instalaciones_saneamiento.splice(index, 1);
      }
    });
  }
  delInformeInstalacionHigiene(obj) {
    this.informe.informe_instalaciones_higiene.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_instalaciones_higiene.splice(index, 1);
      }
    });
  }
  delInformeInstalacionTratamiento(obj) {
    this.informe.informe_instalaciones_tratamiento.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_instalaciones_tratamiento.splice(index, 1);
      }
    });
  }
  delInformePdNivelServicio(obj) {
    this.informe.informe_pd_nivel_servicio.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_pd_nivel_servicio.splice(index, 1);
      }
    });
  }
  delInformeMedioHgSuperficial(obj) {
    this.informe.informe_medio_hg_superficial.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_medio_hg_superficial.splice(index, 1);
      }
    });
  }
  delInformeMedioHgSubterraneo(obj) {
    this.informe.informe_medio_hg_subterraneo.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_medio_hg_subterraneo.splice(index, 1);
      }
    });
  }
  delInformeEstacionPrecipitacion(obj) {
    this.informe.informe_estacion_precipitacion.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_estacion_precipitacion.splice(index, 1);
      }
    });
  }
  delInformeInventarioFuenteSuperficial(obj) {
    this.informe.informe_inventario_fuente_superficial.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_inventario_fuente_superficial.splice(index, 1);
      }
    });
  }
  delInformeInventarioFuenteSubterranea(obj) {
    this.informe.informe_inventario_fuente_subterranea.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_inventario_fuente_subterranea.splice(index, 1);
      }
    });
  }
  delInformeAnalisisPreliminar(obj) {
    this.informe.informe_inventario_analisis_preliminar.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_inventario_analisis_preliminar.splice(index, 1);
      }
    });
  }
  delInformeInventarioOrganolepticos(obj) {
    this.informe.informe_inventario_parametros_organolepticos.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_inventario_parametros_organolepticos.splice(index, 1);
      }
    });
  }
  delInformeInventarioFisicoQuimicos(obj) {
    this.informe.informe_inventario_parametros_fisico_quimicios.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_inventario_parametros_fisico_quimicios.splice(index, 1);
      }
    });
  }
  delInformeInventarioIndeseables(obj) {
    this.informe.informe_inventario_parametros_indeseables.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_inventario_parametros_indeseables.splice(index, 1);
      }
    });
  }
  delInformeInventarioMetales(obj) {
    this.informe.informe_inventario_parametros_metales.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_inventario_parametros_metales.splice(index, 1);
      }
    });
  }
  delInformeInventarioBacteriologicos(obj) {
    this.informe.informe_inventario_parametros_bacteriologicos.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_inventario_parametros_bacteriologicos.splice(index, 1);
      }
    });
  }
  delInformeInventarioPropuestaAlternativa(obj) {
    this.informe.informe_inventario_propuesta_alternativa.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_inventario_propuesta_alternativa.splice(index, 1);
      }
    });
  }
  delInformePotencialHidricoSuperficial(obj) {
    this.informe.informe_potencial_hidrico_superficial.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_potencial_hidrico_superficial.splice(index, 1);
      }
    });
  }
  delInformeAlternativaFuente(obj) {
    this.informe.informe_alternativas_fuente.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_alternativas_fuente.splice(index, 1);
      }
    });
  }
  delInformeUsoAgua(obj) {
    this.informe.informe_usos_agua.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_usos_agua.splice(index, 1);
      }
    });
  }
  delInformeFuenteContaminacion(obj) {
    this.informe.informe_fuentes_contaminacion.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_fuentes_contaminacion.splice(index, 1);
      }
    });
  }
  delInformeFuenteAmenaza(obj) {
    this.informe.informe_fuentes_amenazas.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_fuentes_amenazas.splice(index, 1);
      }
    });
  }
  delInformeMedidaAdaptacion(obj) {
    this.informe.informe_medidas_adaptacion.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_medidas_adaptacion.splice(index, 1);
      }
    });
  }
  delInformeObjetivoMedios(obj) {
    this.informe.informe_objetivos_medios.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_objetivos_medios.splice(index, 1);
      }
    });
  }
  delInformeObjetivoMetas(obj) {
    this.informe.informe_objetivos_metas.forEach((item, index) => {
      if (item === obj) {
        this.informe.informe_objetivos_metas.splice(index, 1);
      }
    });
  }
  //#endregion del


  //#region load catalogs()
  load_catalogos() {
    this.load_departamento();
    this.load_tipo_poblacion();
    this.load_categorizacion_nivel();
    this.load_categorizacion_tipo_saneamiento();
    this.load_categorizacion_tipo_intervencion();
    this.load_modalidad();
    this.load_tipo_organizacion();
    this.load_actividad_economica();
    this.load_tipo_servicio();
    this.load_tipo_revestimiento();
    this.load_tipo_medios_transporte();
    this.load_concesionario();
    this.load_tipo_linea();
    this.load_tipo_fase();
    this.load_tipo_relieve();
    this.load_geotectonica_suelo();
    this.load_tipo_fuente_agua();
    this.load_parametro_calidad();
    this.load_tipo_material();
    this.load_tipo_instalacion_agua();
    this.load_tipo_red();
    this.load_nivel_servicio();
    this.load_tipo_tratamiento();
    this.load_tipo_instalacion_saneamiento();
    this.load_tipo_instalacion_higiene();
    this.load_tipo_clima();
    this.load_tipo_acuifero();
    this.load_tipo_obra();
    this.load_metodo_aforo();
    this.load_tipo_sistema();
    this.load_tipo_bomba();
    this.load_etnia();
    this.load_area_protegida();
    this.load_tipo_cobertura_vegetal();
    this.load_tipo_suelo();
    this.load_usos_agua();
    this.load_tipo_contaminacion();
    this.load_tipo_amenaza();
    this.load_tipo_medida_adaptacion();
    this.load_estado_legal_fuente();
    

  }

  load_departamento() {
    this._departamento_service.list(this.token).subscribe(
      response => {
        this._departamentos = response;
      }
    );
  }
  load_municipios(id_departamento: number) {
    this._municipio_service.list(this.token, id_departamento).subscribe(
      response => {
        this._municipios = response;
      }
    );
  }
  load_comunidades(id_municipio: number) {
    this._comunidad_service.list(this.token, id_municipio).subscribe(
      response => {
        this._comunidades = response;
      }
    );
  }
  load_tipo_poblacion() {
    this._tipo_poblacion_service.list(this.token).subscribe(
      response => {
        this._tipos_poblacion = response;
      }
    );
  }
  load_categorizacion_nivel() {
    this._categorizacion_nivel_service.list(this.token).subscribe(
      response => {
        this._categorizacion_niveles = response;
      }
    );
  }
  load_categorizacion_tipo_saneamiento() {
    this._categorizacion_tipo_saneamiento.list(this.token).subscribe(
      response => {
        this._categorizacion_tipos_saneamiento = response;
      }
    );
  }
  load_categorizacion_tipo_intervencion() {
    this._categorizacion_tipo_intervencion.list(this.token).subscribe(
      response => {
        this._categorizacion_tipos_intervencion = response;
      }
    );
  }
  load_modalidad() {
    this._modalidad_service.list(this.token).subscribe(
      response => {
        this._modalidades = response;
      }
    );
  }
  load_tipo_organizacion() {
    this._tipo_organizacion_service.list(this.token).subscribe(
      response => {
        this._tipos_organizacion = response;
      }
    );
  }
  load_actividad_economica() {
    this._actividad_economica_service.list(this.token).subscribe(
      response => {
        this._actividades_economicas = response;
      }
    );
  }
  load_tipo_servicio() {
    this._tipo_servicio_service.list(this.token).subscribe(
      response => {
        this._tipos_servicio = response;
      }
    );
  }
  load_tipo_revestimiento() {
    this._tipo_revestimiento_service.list(this.token).subscribe(
      response => {
        this._tipos_revestimiento = response;
      }
    );
  }
  load_tipo_medios_transporte() {
    this._tipo_medio_transporte_service.list(this.token).subscribe(
      response => {
        this._tipos_medios_transporte = response;
      }
    );
  }
  load_concesionario() {
    this._concesionario_service.list(this.token).subscribe(
      response => {
        this._concesionarios = response;
      }
    );
  }
  load_tipo_linea() {
    this._tipo_linea_service.list(this.token).subscribe(
      response => {
        this._tipos_linea = response;
      }
    );
  }
  load_tipo_fase() {
    this._tipo_fase_service.list(this.token).subscribe(
      response => {
        this._tipos_fase = response;
      }
    );
  }
  load_tipo_relieve() {
    this._tipo_relieve_service.list(this.token).subscribe(
      response => {
        this._tipos_relieve = response;
      }
    );
  }
  load_geotectonica_suelo() {
    this._geotectonica_suelo_service.list(this.token).subscribe(
      response => {
        this._geotecnicas_suelo = response;
      }
    );
  }
  load_tipo_fuente_agua() {
    this._tipo_fuente_agua_service.list(this.token).subscribe(
      response => {
        this._tipos_fuente_agua = response;
      }
    );
  }
  load_parametro_calidad() {
    this._parametro_calidad_service.list(this.token).subscribe(
      response => {
        this._parametros_calidad = response;
      }
    );
  }
  load_tipo_material() {
    this._tipo_materialservice.list(this.token).subscribe(
      response => {
        this._tipos_material = response;
      }
    );
  }
  load_tipo_instalacion_agua() {
    this._tipo_instalacion_agua_service.list(this.token).subscribe(
      response => {
        this._tipos_instalacion_agua = response;
      }
    );
  }
  load_tipo_red() {
    this._tipo_red_service.list(this.token).subscribe(
      response => {
        this._tipos_red = response;
      }
    );
  }
  load_nivel_servicio() {
    this._nivel_servicio_service.list(this.token).subscribe(
      response => {
        this._niveles_servicio = response;
      }
    );
  }
  load_tipo_tratamiento() {
    this._tipo_tratamiento_service.list(this.token).subscribe(
      response => {
        this._tipos_tratamiento = response;
      }
    );
  }
  load_tipo_instalacion_saneamiento() {
    this._tipo_instalacion_saneamiento_service.list(this.token).subscribe(
      response => {
        this._tipos_instalacion_sanemiento = response;
      }
    );
  }
  load_tipo_instalacion_higiene() {
    this._tipo_instalacion_higiene_service.list(this.token).subscribe(
      response => {
        this._tipos_instalacion_higiene = response;
      }
    );
  }
  load_tipo_clima() {
    this._tipo_clima_service.list(this.token).subscribe(
      response => {
        this._tipos_clima = response;
      }
    );
  }
  load_tipo_acuifero() {
    this._tipo_acuifero_service.list(this.token).subscribe(
      response => {
        this._tipos_acuifero = response;
      }
    );
  }
  load_tipo_obra() {
    this._tipo_obra_service.list(this.token).subscribe(
      response => {
        this._tipos_obra = response;
      }
    );
  }
  load_metodo_aforo() {
    this._metodo_aforo_service.list(this.token).subscribe(
      response => {
        this._metodos_aforo = response;
      }
    );
  }
  load_tipo_sistema() {
    this._tipo_sistema_service.list(this.token).subscribe(
      response => {
        this._tipos_sistema = response;
      }
    );
  }
  load_tipo_bomba() {
    this._tipo_bomba_service.list(this.token).subscribe(
      response => {
        this._tipos_bomba = response;
      }
    );
  }
  load_etnia() {
    this._etnia_service.list(this.token).subscribe(
      response => {
        this._etnias = response;
      }
    );
  }
  load_area_protegida() {
    this._area_protegida_service.list(this.token).subscribe(
      response => {
        this._areas_protegidas = response;
      }
    );
  }
  load_tipo_cobertura_vegetal() {
    this._tipo_cobertura_vegetal_service.list(this.token).subscribe(
      response => {
        this._tipos_cobertura_vegetal = response;
      }
    );
  }
  load_tipo_suelo() {
    this._tipo_suelo_service.list(this.token).subscribe(
      response => {
        this._tipos_suelo = response;
      }
    );
  }
  load_usos_agua() {
    this._usos_agua_service.list(this.token).subscribe(
      response => {
        this._usos_agua = response;
      }
    );
  }
  load_tipo_contaminacion() {
    this._tipo_contaminacion_service.list(this.token).subscribe(
      response => {
        this._tipos_contaminacion = response;
      }
    );
  }
  load_tipo_amenaza() {
    this._tipo_amenaza_service.list(this.token).subscribe(
      response => {
        this._tipos_amenazas = response;
      }
    );
  }
  load_tipo_medida_adaptacion() {
    this._tipo_medida_adaptacion.list(this.token).subscribe(
      response => {
        this._tipos_medidas_adaptacion = Array.from(this.MedidasTipo(response, r => r.tipo));
      }
    );
  }
  load_estado_legal_fuente() {
    this._estado_legal_fuente_service.list(this.token).subscribe(
      response => {
        this._estado_legal_fuente = response;
      }
    );
  }
  load_informes_codigo(id_municipio: number) {
    let count: number;
    this._informe_service.list(this.token).subscribe(
      response => {
        count = response.filter(i => i.id_municipio === id_municipio).length + 1;
        this.informe.codigo = this.informe.municipio.codigo_inec + count;
      });
  }

  //#endregion load

  //#region onchange ()
  departamento_change() {
    if (this.informe.id_departamento !== 0) {
      this.informe.departamento = this._departamentos.find(e => e.id === this.informe.id_departamento);
      this.informe.id_municipio = 0;
      this.load_municipios(this.informe.id_departamento);
    } else {
      this.informe.id_departamento = 0;
      this.informe.id_municipio = 0;
      this._municipios = <any>[];
    }
    this.municipio_change();
  }
  municipio_change() {
    if (this.informe.id_municipio !== 0) {
      // seleccionar el objeto municipio
      this.informe.municipio = this._municipios.find(e => e.id === this.informe.id_municipio);
      // cargar la lista de comunidades
      this.load_comunidades(this.informe.id_municipio);
      // cargar la categoria de pobreza del municipio
      this.informe.categoria_pobreza = this.informe.municipio.categoria_pobreza;
      this._categoria_pobreza = this.informe.categoria_pobreza.descripcion;
      this.load_informes_codigo(this.informe.id_municipio);
    } else {
      this.informe.id_municipio = 0;
      this.informe.municipio = null;
      this.informe.categoria_pobreza = null;
      this.informe.codigo = '';
      this._categoria_pobreza = '';
      this._comunidades = <any>[];
    }

  }
  categorizacion_nivel_change() {
    if (this.informe.id_categorizacion_nivel !== 0) {
      this.informe.categorizacion_nivel =
        this._categorizacion_niveles.find(e => e.id === this.informe.id_categorizacion_nivel);
    } else {
      this.informe.categorizacion_nivel = null;
    }
  }
  categorizacion_tipo_saneamiento_change() {
    if (this.informe.id_categorizacion_tipo_saneamiento !== 0) {
      this.informe.categorizacion_tipo_saneamiento =
        this._categorizacion_tipos_saneamiento.find(e => e.id === this.informe.id_categorizacion_tipo_saneamiento);
    } else {
      this.informe.categorizacion_tipo_saneamiento = null;
    }
  }
  categorizacion_tipo_intervencion_change() {
    if (this.informe.id_categorizacion_tipo_intervencion !== 0) {
      this.informe.categorizacion_tipo_intervencion =
        this._categorizacion_tipos_intervencion.find(e => e.id === this.informe.id_categorizacion_tipo_intervencion);
    } else {
      this.informe.categorizacion_tipo_intervencion = null;
    }
  }
 /* modalidad_change() {
    if (this.informe.id_modalidad !== 0) {
      this.informe.modalidad =
        this._modalidades.find(e => e.id === this.informe.id_modalidad);
    } else {
      this.informe.modalidad = null;
    }
  }*/
  concesionario_change() {
    if (this.informe.id_concesionario !== 0) {
      this.informe.concesionario =
        this._concesionarios.find(e => e.id === this.informe.id_concesionario);
    } else {
      this.informe.concesionario = null;
    }
  }
  tipo_linea_change() {
    if (this.informe.id_tipo_linea !== 0) {
      this.informe.tipo_linea =
        this._tipos_linea.find(e => e.id === this.informe.id_tipo_linea);
    } else {
      this.informe.tipo_linea = null;
    }
  }
  tipo_fase_change() {
    if (this.informe.id_tipo_fase !== 0) {
      this.informe.tipo_fase =
        this._tipos_fase.find(e => e.id === this.informe.id_tipo_fase);
    } else {
      this.informe.tipo_fase = null;
    }
  }
  estado_change() {
    if (this.informe.m1s1_completa
      && this.informe.m1s2_completa
      && this.informe.m1s3_completa
      && this.informe.m1s4_completa
      && this.informe.m1s5_1_completa
      && this.informe.m1s5_2_completa
      && this.informe.m1s5_3_completa
      && this.informe.m1s5_4_completa
      && this.informe.m1s5_5_completa
      && this.informe.m1s5_6_completa
      && this.informe.m1s5_completa
      && this.informe.m1s6_completa
     ) {
      this._estado = 'Perfil de Proyecto';
      this.informe.estado_informe = this._estado;
    }else{
      this._estado = 'Borrador';
      this.informe.estado_informe = this._estado;
    } 
  }
  tipo_sistema_change() {
    if (this.informe.id_tipo_sistema !== 0) {
      this.informe.tipo_sistema =
        this._tipos_sistema.find (e => e.id === this.informe.id_tipo_sistema);
    } else {
      this.informe.tipo_sistema = null;
    }
  }
  tipo_relieve_change() {
    if (this.informe.id_tipo_relieve !== 0) {
      this.informe.tipo_relieve =
        this._tipos_relieve.find (e => e.id === this.informe.id_tipo_relieve);
    } else {
      this.informe.tipo_relieve = null;
    }
  }
  tipo_clima_change() {
    if (this.informe.id_tipo_clima !== 0) {
      this.informe.tipo_clima =
        this._tipos_clima.find (e => e.id === this.informe.id_tipo_clima);
    } else {
      this.informe.tipo_clima = null;
    }
  }
  //#endregion onchange

  //#region handle images
  handleUbicacionImagen(files: FileList) {
    if (files.length > 0) {
      this.file_ubicacion_imagen = files.item(0);
      const reader = new FileReader();
      reader.readAsDataURL(files.item(0));
      reader.onload = (_event) => {
        this.img_ubicacion_imagen = reader.result;
      };
    }
  }
  uploadUbicacionImage() {
    if (this.file_ubicacion_imagen !== null && this.file_ubicacion_imagen !== undefined)  {
      const filename = this.informe.id + 'ubicacion_imagen' +
        this.file_ubicacion_imagen.name.substr(this.file_ubicacion_imagen.name.lastIndexOf('.'));

      this._file_upload_service.upload(this.token, this.file_ubicacion_imagen, filename).toPromise().then(
        response => {
          this.informe.ubicacion_imagen = global.url + 'downloadfile?image=' + filename;
        }
      );
    }
  }
  handleUbicacionImagenmicro(files: FileList) {
    if (files.length > 0) {
      this.file_ubicacion_micro = files.item(0);
      const reader = new FileReader();
      reader.readAsDataURL(files.item(0));
      reader.onload = (_event) => {
        this.img_ubicacion_micro = reader.result;
      };
    }
  }
  uploadUbicacionImagenmicro() {
    if (this.file_ubicacion_micro !== null && this.file_ubicacion_micro !== undefined) {
      const filename = this.informe.id + 'ubicacion_micro' +
        this.file_ubicacion_micro.name.substr(this.file_ubicacion_micro.name.lastIndexOf('.'));

      this._file_upload_service.upload(this.token, this.file_ubicacion_micro, filename).toPromise().then(
        response => {
          this.informe.ubicacion_micro = global.url + 'downloadfile?image=' + filename;
        }
      );
    }
  }
  handleUbicacionImagenBancoMaterial(files: FileList) {
    if (files.length > 0) {
      this.file_bancos_materiales_imagen = files.item(0);
      const reader = new FileReader();
      reader.readAsDataURL(files.item(0));
      reader.onload = (_event) => {
        this.img_bancos_materiales_imagen = reader.result;
      };
    }
  }
  uploadUbicacionImagenBancoMaterial() {
    if (this.file_bancos_materiales_imagen !== null && this.file_bancos_materiales_imagen !== undefined) {
      const filename = this.informe.id + 'bancos_materiales_imagen' +
        this.file_bancos_materiales_imagen.name.substr(this.file_bancos_materiales_imagen.name.lastIndexOf('.'));

      this._file_upload_service.upload(this.token, this.file_bancos_materiales_imagen, filename).toPromise().then(
        response => {
          this.informe.bancos_materiales_imagen = global.url + 'downloadfile?image=' + filename;
        }
      );
    }
  }
  handleSinProyectoImagen(files: FileList) {
    if (files.length > 0) {
      this.file_sin_proyecto_imagen = files.item(0);
      const reader = new FileReader();
      reader.readAsDataURL(files.item(0));
      reader.onload = (_event) => {
        this.img_sin_proyecto_imagen = reader.result;
      };
    }
  }
  uploadSinProyectoImagen() {
    if (this.file_sin_proyecto_imagen !== null && this.file_sin_proyecto_imagen !== undefined) {
      const filename = this.informe.id + 'sin_proyecto_imagen' +
        this.file_sin_proyecto_imagen.name.substr(this.file_sin_proyecto_imagen.name.lastIndexOf('.'));

      this._file_upload_service.upload(this.token, this.file_sin_proyecto_imagen, filename).toPromise().then(
        response => {
          this.informe.sin_proyecto_imagen = global.url + 'downloadfile?image=' + filename;
        }
      );
    }
  }

  // -----------------------------------
  handleUbicacionImagenEnergia(files: FileList) {
    if (files.length > 0) {
      this.file_ubicacion_img_energia = files.item(0);
      const reader = new FileReader();
      reader.readAsDataURL(files.item(0));
      reader.onload = (_event) => {
        this.img_ubicacion_img_energia = reader.result;
      };
    }
  }
  uploadUbicacionImageEnergia() {
    if (this.file_ubicacion_img_energia !== null && this.file_ubicacion_img_energia !== undefined)  {
      const filename = this.informe.id + 'ubicacion_img_energia' +
        this.file_ubicacion_img_energia.name.substr(this.file_ubicacion_img_energia.name.lastIndexOf('.'));

      this._file_upload_service.upload(this.token, this.file_ubicacion_img_energia, filename).toPromise().then(
        response => {
          this.informe.ubicacion_img_energia = global.url + 'downloadfile?image=' + filename;
        }
      );
    }
  }
  handleUbicacionFichaTecnica(files: FileList) {
    if (files.length > 0) {
      this.file_ubicacion_fich_tecnica = files.item(0);
      const reader = new FileReader();
      reader.readAsDataURL(files.item(0));
      reader.onload = (_event) => {
        this.img_ubicacion_fich_tecnica = reader.result;
      };
    }
  }
  uploadUbicacionFichaTecnica() {
    if (this.file_ubicacion_fich_tecnica !== null && this.file_ubicacion_fich_tecnica !== undefined) {
      const filename = this.informe.id + 'ubicacion_fich_tecnica' +
        this.file_ubicacion_fich_tecnica.name.substr(this.file_ubicacion_fich_tecnica.name.lastIndexOf('.'));

      this._file_upload_service.uploadArch(this.token, this.file_ubicacion_fich_tecnica, filename).toPromise().then(
        response => {
          this.informe.ubicacion_fich_tecnica = global.url + 'downloadarch?arch=' + filename;
        }
      );
    }
  }
   // -----------------------------------
   handleUbicacionImagenForestal(files: FileList) {
    if (files.length > 0) {
      this.file_cobertura_forestal_imagen = files.item(0);
      const reader = new FileReader();
      reader.readAsDataURL(files.item(0));
      reader.onload = (_event) => {
        this.img_cobertura_forestal_imagen = reader.result;
      };
    }
  }
  uploadUbicacionImageForestal() {
    if (this.file_cobertura_forestal_imagen !== null && this.file_cobertura_forestal_imagen !== undefined)  {
      const filename = this.informe.id + 'cobertura_forestal_imagen' +
        this.file_cobertura_forestal_imagen.name.substr(this.file_cobertura_forestal_imagen.name.lastIndexOf('.'));

      this._file_upload_service.upload(this.token, this.file_cobertura_forestal_imagen, filename).toPromise().then(
        response => {
          this.informe.cobertura_forestal_imagen = global.url + 'downloadfile?image=' + filename;
        }
      );
    }
  }

  handleUbicacionDocRevisado(files: FileList) {
    if (files.length > 0) {
      this.file_ubicacion_doc_revisado = files.item(0);
      const reader = new FileReader();
      reader.readAsDataURL(files.item(0));
      reader.onload = (_event) => {
        this.img_ubicacion_doc_revisado = reader.result;
      };
    }
  }
  uploadUbicacionDocRevisado() {
    if (this.file_ubicacion_doc_revisado !== null && this.file_ubicacion_doc_revisado !== undefined) {
      const filename = this.informe.id + 'ubicacion_doc_revisado' +
        this.file_ubicacion_doc_revisado.name.substr(this.file_ubicacion_doc_revisado.name.lastIndexOf('.'));
      this._file_upload_service.uploadArch(this.token, this.file_ubicacion_doc_revisado, filename).toPromise().then(
        response => {
          this.informe.ubicacion_doc_revisado = global.url + 'downloadarch?arch=' + filename;
        }
      );
    }
  }

  handleUbicacionLineaBas(files: FileList) {
    if (files.length > 0) {
      this.file_ubicacion_bd = files.item(0);
      const reader = new FileReader();
      reader.readAsDataURL(files.item(0));
      reader.onload = (_event) => {
        this.img_ubicacion_bd = reader.result;
      };
    }
  }

  uploadUbicacionLineaBas() {
    if (this.file_ubicacion_bd !== null && this.file_ubicacion_bd !== undefined) {
      const filename = this.informe.id + 'ubicacion_bd' +
        this.file_ubicacion_bd.name.substr(this.file_ubicacion_bd.name.lastIndexOf('.'));
      this._file_upload_service.uploadArch(this.token, this.file_ubicacion_bd, filename).toPromise().then(
        response => {
          this.informe.ubicacion_bd = global.url + 'downloadexcel?excel=' + filename;
        }
      );
    }
  }

  handleUbicacionInformeLBD(files: FileList) {
    if (files.length > 0) {
      this.file_ubicacion_infome_bd = files.item(0);
      const reader = new FileReader();
      reader.readAsDataURL(files.item(0));
      reader.onload = (_event) => {
        this.img_ubicacion_infome_bd = reader.result;
      };
    }
  }

  uploadUbicacionInformeLBD() {
    if (this.file_ubicacion_infome_bd !== null && this.file_ubicacion_infome_bd !== undefined) {
      const filename = this.informe.id + 'ubicacion_infome_bd' +
        this.file_ubicacion_infome_bd.name.substr(this.file_ubicacion_infome_bd.name.lastIndexOf('.'));
      this._file_upload_service.uploadArch(this.token, this.file_ubicacion_infome_bd, filename).toPromise().then(
        response => {
          this.informe.ubicacion_infome_bd = global.url + 'downloadarch?arch=' + filename;
        }
      );
    }
  }

  handleUbicacionFichaHidrica(files: FileList) {
    if (files.length > 0) {
      this.file_ubicacion_fichahidri = files.item(0);
      const reader = new FileReader();
      reader.readAsDataURL(files.item(0));
      reader.onload = (_event) => {
        this.img_ubicacion_fichahidri = reader.result;
      };
    }
  }

  uploadUbicacionFichaHidirica() {
    if (this.file_ubicacion_fichahidri !== null && this.file_ubicacion_fichahidri !== undefined) {
      const filename = this.informe.id + 'ubicacion_fichahidri' +
        this.file_ubicacion_fichahidri.name.substr(this.file_ubicacion_fichahidri.name.lastIndexOf('.'));
      this._file_upload_service.uploadArch(this.token, this.file_ubicacion_fichahidri, filename).toPromise().then(
        response => {
          this.informe.ubicacion_fichahidri = global.url + 'downloadarch?arch=' + filename;
        }
      );
    }
  }

  handleUbicacionImagenTerreno(files: FileList) {
    if (files.length > 0) {
      this.file_ubicacion_terreno = files.item(0);
      const reader = new FileReader();
      reader.readAsDataURL(files.item(0));
      reader.onload = (_event) => {
        this.img_ubicacion_terreno = reader.result;
      };
    }
  }

  uploadUbicacionImageTerreno() {
    if (this.file_ubicacion_terreno !== null && this.file_ubicacion_terreno !== undefined) {
      const filename = this.informe.id + 'ubicacion_terreno_img' +
        this.file_ubicacion_terreno.name.substr(this.file_ubicacion_terreno.name.lastIndexOf('.'));
      this._file_upload_service.upload(this.token, this.file_ubicacion_terreno, filename).toPromise().then(
        response => {
          this.informe.ubicacion_terreno_img = global.url + 'downloadfile?image=' + filename;
        }
      );
    }
  }
  //#endregion handle images

  //#region save informe
  save() {
    const now = new Date();
    this.uploadUbicacionImage();
    this.uploadUbicacionImagenmicro();
    this.uploadUbicacionImagenBancoMaterial();
    this.uploadSinProyectoImagen();
    this.uploadUbicacionImageEnergia();
    this.uploadUbicacionImageForestal();
    this.uploadUbicacionDocRevisado();
    this.uploadUbicacionLineaBas();
    this.uploadUbicacionFichaHidirica();
    this.uploadUbicacionInformeLBD();
    this.uploadUbicacionFichaTecnica();
    this.uploadUbicacionImageTerreno();

    if (this.informe.id === 0) {
      this.informe.created = now;
      this.informe.changed = now;
      this.add();
    } else {
      this.informe.changed = now;
      this.uploadUbicacionImage();
      this.uploadUbicacionImagenmicro();
      this.uploadUbicacionImagenBancoMaterial();
      this.uploadSinProyectoImagen();
      this.uploadUbicacionImageEnergia();
      this.uploadUbicacionImageForestal();
      this.uploadUbicacionDocRevisado();
      this.uploadUbicacionLineaBas();
      this.uploadUbicacionFichaHidirica();
      this.uploadUbicacionInformeLBD();
      this.uploadUbicacionFichaTecnica();
      this.uploadUbicacionImageTerreno();
      this.edit();
    }

    this._last_save = 'Guardado por última vez el ' + now.toLocaleDateString() + ' ' + now.toLocaleTimeString();
  }
  add() {
    this._informe_service.addN(this.token, this.informe).subscribe(
      response => {
        this.informe.id = response.id;

        this._toastr.success('Se han guardado los cambios.', 'Informe Guardado', {
          timeOut: 3000,
          enableHtml: true,
          closeButton: true,
        });

      }
    );
  }
  edit() {
    this._informe_service.editN(this.token, this.informe).subscribe(response => {
      console.log(response);
      this._toastr.success('Se han guardado los cambios.', 'Informe Guardado', {
        timeOut: 3000,
        enableHtml: true,
        closeButton: true,
      });
    });
  }
  //#endregion save informe

  //#region utilities
  MedidasTipo(array: Array<any>, column) {
    const map = new Map();
    array.forEach((item) => {
         const key = column(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
  }
  //#endregion
}
