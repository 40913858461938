import { TipoInstalacionHigiene } from '../catalogos/tipo_instalacion_higiene';

export class InformeInstalacionesHigiene {
    public id_informe = 0;
    public tipo_instalacion_higiene: TipoInstalacionHigiene = null;
    public estado = '';
    public cantidad = 0;
    public poblacion_atendida = 0;
    public viviendas_atendidas = 0;
    public caracteristicas = '';
}
