
export class InformePotencialHidricoSubterraneo {
    public id_informe = 0;
    public bombeo1_tiempo = 0;
    public bombeo1_descenso = 0;
    public bombeo1_capacidad = 0;
    public bombeo2_tiempo = 0;
    public bombeo2_descenso = 0;
    public bombeo2_capacidad = 0;
    public bombeo3_tiempo = 0;
    public bombeo3_descenso = 0;
    public bombeo3_capacidad = 0;
    public bombeo4_tiempo = 0;
    public bombeo4_descenso = 0;
    public bombeo4_capacidad = 0;
}
