import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { routing, appRoutingProviders, routerm } from './app.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { HostListener } from '@angular/core';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { InformesComponent } from './components/informes/informes.component';
import { InformeNComponent } from './components/informe-n/informe-n.component';
// import { InformeEditComponent } from './components/informe-edit/informe-edit.component';
import { UsuarioEditComponent } from './components/usuario-edit/usuario-edit.component';

import { ModalComponent } from './_directives';
import { ModalService } from './_services';

// Guard
import { IdentityGuard} from './services/seguridad/identity.guards';
import { UserService } from './services/seguridad/user.service';
import { InformeEditComponent } from './components/informe-edit/informe-edit.component';
import { UsuarioPasswordComponent } from './components/usuario-password/usuario-password.component';
import { InformeViewComponent } from './components/informe-view/informe-view.component';
import { ReporteComponent } from './components/reporte/reporte.component';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ModalComponent,
    InicioComponent,
    InformesComponent,
    UsuarioEditComponent,
    InformeNComponent,
    InformeEditComponent,
    UsuarioPasswordComponent,
    InformeViewComponent,
    ReporteComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    routing,
    routerm,
    FormsModule,
    ReactiveFormsModule,
    AngularFontAwesomeModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot()
  ],
  providers: [
    appRoutingProviders,
    IdentityGuard,
    UserService,
    ModalService,
  ],
  bootstrap: [AppComponent]
})


export class AppModule { }
