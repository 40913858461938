import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/seguridad/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-usuario-edit',
  templateUrl: './usuario-edit.component.html',
  styleUrls: ['./usuario-edit.component.css'],
  providers: [UserService, ToastrService]
})
export class UsuarioEditComponent implements OnInit {

  public title;
  public user;
  public identity;
  public token;
  public status;

  constructor(
    private _UserService: UserService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.title = 'Edita tus datos';
    this.identity = this._UserService.getIdentity();
    this.token = this._UserService.getToken();
    this.user = this.identity;
  }

  onSubmit(form) {
    this._UserService.update(this.token, this.user).subscribe(
      response => {
        this.identity = response;
        this.user = this.identity;
        localStorage.setItem('user', JSON.stringify(this.identity));

        this.toastr.success('Se han guardado los cambios.', 'Usuario Guardado', {
          timeOut: 3000,
          enableHtml: true,
          closeButton: true,
        });
      },
      error => {
        this.status = 'error';
      }

    );
  }

}
