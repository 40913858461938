export class InformeTelefonos {
    public id: number;
    public id_informe: number;
    public telefono: string;
    constructor() {
        this.id = 0;
        this.id_informe = 0;
        this.telefono = '';
    }
}
