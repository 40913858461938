import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { global } from '../global';


@Injectable()
export class MunicipioService {
    public url: string;

    constructor(
        private _http: HttpClient

    ) {
        this.url = global.url;
    }

    list(token, id: number): Observable<any> {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', 'Bearer ' + token);
        return this._http.get(this.url + 'municipios/' + id, { headers: headers });
    }
}
