import { TipoFuenteAgua } from '../catalogos/tipo_fuente_agua';
import { TipoBomba } from '../catalogos/tipo_bomba';

export class InformeInventarioFuenteSubterranea {
    public id_informe = 0;
    public fuente = '';
    public tipo_fuente: TipoFuenteAgua = null;
    public coordenada_zona = '';
    public coordenada_x = '';
    public coordenada_y = '';
    public coordenada_z = '';
    public diametro = 0;
    public pt = 0;
    public nea = 0;
    public columna = 0;
    public caudal = 0;
    public tipo_registro = '';
    public fecha = '';
    public tipo_bomba: TipoBomba = null;
    public regimen = 0;
}
