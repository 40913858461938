import { Comunidad } from '../catalogos/comunidad';
import { TipoPoblacion } from '../catalogos/tipo_poblacion';
import { Etnia } from '../catalogos/etnia';

export class InformeComunidades {
    public id_informe = 0;
    public comunidad: Comunidad = null;
    public tipo_poblacion: TipoPoblacion = null;
    public etnia: Etnia = null;
    public coordenada_zona = '';
    public coordenada_x = '';
    public coordenada_y = '';
    public coordenada_z = '';
    public punto_referencia = '';
}
