import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { global } from './global';


@Injectable()
export class FileUploadService {

    public url: string;

    constructor(
        private _http: HttpClient

    ) {
        this.url = global.url;
    }

    upload(token, file: File, filename: string): Observable<any> {
        const headers = new HttpHeaders()
        .set('Authorization', 'Bearer ' + token);
        const formData: FormData = new FormData();
        formData.append('files', file, filename);
        return this._http.post(this.url + 'uploadfiles', formData, {headers: headers});
    }
    uploadArch(token, file: File, filename: string): Observable<any> {
        const headers = new HttpHeaders()
        .set('Authorization', 'Bearer ' + token);
        const formData: FormData = new FormData();
        formData.append('files', file, filename);
        return this._http.post(this.url + 'uploadarch', formData, {headers: headers});
    }
}
