// basic imports
import {ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

// components imports
import {InicioComponent} from './components/inicio/inicio.component';
import {LoginComponent} from './components/login/login.component';
import { InformesComponent } from './components/informes/informes.component';
import { UsuarioEditComponent } from './components/usuario-edit/usuario-edit.component';
import { InformeNComponent } from './components/informe-n/informe-n.component';
import { InformeEditComponent } from './components/informe-edit/informe-edit.component';
import { UsuarioPasswordComponent } from './components/usuario-password/usuario-password.component';
import { InformeViewComponent } from './components/informe-view/informe-view.component';
import { ReporteComponent } from './components/reporte/reporte.component';


// Guard
import { IdentityGuard } from './services/seguridad/identity.guards';



// routes definition
const appRoutes: Routes = [
    {path: '', component: InicioComponent},
    {path: 'inicio', component: InicioComponent},
    {path: 'login', component: LoginComponent},
    {path: 'logout/:sure', component: LoginComponent, canActivate: [IdentityGuard]},
    {path: 'informes', component: InformesComponent, canActivate: [IdentityGuard]},
    {path: 'informe/:id', component: InformeEditComponent, canActivate: [IdentityGuard]},
    {path: 'informeN', component: InformeNComponent, canActivate: [IdentityGuard]},
    {path: 'ajustes', component: UsuarioEditComponent, canActivate: [IdentityGuard]},
    {path: 'ajustes/password', component: UsuarioPasswordComponent, canActivate: [IdentityGuard]},
    {path:  'informeView/:id', component: InformeViewComponent, canActivate: [IdentityGuard]},
    {path: 'reports', component: ReporteComponent, canActivate: [IdentityGuard]},

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

// export config
export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);
export const routerm = RouterModule.forRoot(appRoutes);