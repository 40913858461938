import { ActividadEconomica } from '../catalogos/actividad_economica';

export class InformeActividadFortalecimiento {
    public id_informe: number;
    public actividad: string;

    constructor() {
        this.id_informe = 0;
        this.actividad = '';
    }
}
