import { Component, OnInit, DoCheck } from '@angular/core';
import { UserService } from './services/seguridad/user.service';
import { timer } from 'rxjs';

import './_content/modal.less';
// import { HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css', '../app/_content/modal.less'],
  providers: [UserService]
})
export class AppComponent implements OnInit, DoCheck {
  public title = 'IP-APP';
  public identity;
  public token;
  public expires = 0;

  constructor(
    public _userService: UserService,
  ) {
    this.loadUser();
  }

  ngOnInit() {
    console.log('Aplicacion Iniciada Correctamente :)');
  }

  ngDoCheck() {
    this.loadUser();

  }

  loadUser() {
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.expires = this._userService.getExpires();
  }

}
