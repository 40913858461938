import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/seguridad/user.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {
  public identity;
  public token;

  constructor(
    public _userService: UserService
  ) { }

  ngOnInit() {
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
  }

}
