

export class InformeInventarioCorberturaForestal{
    public id_informe = 0;
    public fuente = '';
    public tipo_fuente = '';
    public bosque_denso = false;
    public bosque_ralo = false;
    public bosque_galeria = false;
    public pinar = false;
    public vegetacion = false;
    public agropecuario = false;
    public mangle = false;
    public potreros = false;
  
}
