﻿import { Component, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
//import { global } from '../services/global';

//servicios
//import { InformeService } from '../services/informe.service';
import { ModalService } from '../_services';
//import { UserService } from '../services/seguridad/user.service';

@Component({
    selector: 'jw-modal',
    template: `
    <div class="jw-modal modal fade" id="staticBackdrop" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Mensaje de Confirmación!</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
            <p>¿Está seguro que desea eliminar este informe de forma permanente?</p>
            </div>
            <ng-content></ng-content>
            </div>
        </div>
    </div>
    `
})

export class ModalComponent implements OnInit, OnDestroy {
    @Input() id: string;
  
    private element: any;
    

    constructor(private modalService: ModalService, private el: ElementRef ) {
        this.element = el.nativeElement;
    }

    ngOnInit(): void {
        let modal = this;


        // ensure id attribute exists
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }

        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        document.body.appendChild(this.element);

        // close modal on background click
        this.element.addEventListener('click', function (e: any) {
            if (e.target.className === 'jw-modal') {
                modal.close();
            }
        });

        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
    }


    // remove self from modal service when directive is destroyed
    ngOnDestroy(): void {
        this.modalService.remove(this.id);
        this.element.remove();
    }

    // open modal
    open(): void {
        this.element.style.display = 'block';
        document.body.classList.add('jw-modal-open');
    }

    // close modal
    close(): void {
        this.element.style.display = 'none';
        document.body.classList.remove('jw-modal-open');
    }

   

}