
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { global } from '../../services/global';
declare var $;
// models

// services
import { InformeService } from '../../services/informe.service';
import { UserService } from '../../services/seguridad/user.service';
import { ModalService } from '../../_services';

@Component({
  selector: 'app-informes',
  templateUrl: './informes.component.html',
  styleUrls: ['./informes.component.css', '../../_content/modal.less'],
  providers: [InformeService, ModalService],



})
export class InformesComponent implements OnInit {

  /*@ViewChild('allTable') table: ElementRef;*/
  dtOption: any;
  // token
  public default_pdf = global.url + 'downloadarch?arch=placeholder.pdf';
  public default_excel = global.url + 'downloadexcel?excel=placeholder.xlsx';
  public identity;
  public token;
  public id_i;
  public title = 'Listado de Informes';
  public export_url = global.export_url;
  public download = global.url + 'downloadarch?arch=';
  public excel = global.url + 'downloadexcel?excel=';
  public informes = [];
  public mis_informes = [];
  public mis_informes_pp = [];
  public mis_informes_r1 = [];
  public mis_informes_r2 = [];
  public mis_informes_r3 = [];
  public mis_informes_r4 = [];
  public mis_informes_r5 = [];
  public mis_informes_r6 = [];
  public mis_informes_rn = [];
  public mis_informes_rs = [];
  public file_ubicacion_fichahidirica: File;
  public img_fichahidrica_file: any;


  constructor(
    private modalService: ModalService,
    private _InformeService: InformeService,
    private _UserService: UserService,

  ) { }

  ngOnInit() {

    this.dtOption = {
      "ordering": false,
      "retrieve": true,
      "responsive": true,
      "autoWidth": false,
      "language": {
        "lengthMenu": "Mostar " + `
                        <select>
                        <option value = '5'>5</option>
                        <option value = '10'>10</option>
                        <option value = '25'>25</option>
                        <option value = '50'>50</option>
                        <option value = '100'>100</option>
                        <option value = '-1'>ALL</option>
                        </select>
          `+ " registros por página",
        "zeroRecords": "Nada Encontrado - Disculpa",
        "info": "Mostrando de _START_  a _END_ elementos de un total de _TOTAL_  ",
        "infoEmpty": "No records available",
        "infoFiltered": "( filtrado de _MAX_ registros totales)",
        "search": "Buscar",
        "paginate": {
          "next": "Siguiente",
          "previous": "Anterior"

        }
      }
    }
    this.token = this._UserService.getToken();
    this.identity = this._UserService.getIdentity();
    this.getInformes(this.token);


  }


  getInformes(token) {
    this._InformeService.list(token).subscribe(
      response => {
                    this.mis_informes = [];
                    this.mis_informes_pp = [];
                    this.mis_informes_pp = [];
                    this.mis_informes_r1 = [];
                    this.mis_informes_r2 = [];
                    this.mis_informes_r3 = [];
                    this.mis_informes_r4 = [];
                    this.mis_informes_r5 = [];
                    this.mis_informes_r6 = [];
                    this.mis_informes_rn = [];
                    this.mis_informes_rs = [];
                    this.informes = response;
                    this.informes.forEach((item) => {
                      if (item.id_usuario === this.identity.id) {
                        this.mis_informes.push(item);

                      }
                    });
                    this.informes.forEach((item) => {
                      if (item.estado_informe === 'Perfil de Proyecto') {
                        this.mis_informes_pp.push(item);
                      }

                    });
                    this.mis_informes_pp.forEach((item) => {
                      if (item.id_municipio === 15 || item.id_municipio === 19 || item.id_municipio === 24 || item.id_municipio === 35 ||
                        item.id_municipio === 44 || item.id_municipio === 46 || item.id_municipio === 58 || item.id_municipio === 61
                        || item.id_municipio === 63 || item.id_municipio === 72 || item.id_municipio === 74 || item.id_municipio === 82
                        || item.id_municipio === 84 || item.id_municipio === 88 || item.id_municipio === 92 || item.id_municipio === 99
                        || item.id_municipio === 105 || item.id_municipio === 107 || item.id_municipio === 110 || item.id_municipio === 113
                        || item.id_municipio === 116 || item.id_municipio === 124 || item.id_municipio === 134 || item.id_municipio === 136
                        || item.id_municipio === 143 || item.id_municipio === 150 || item.id_municipio === 151) {
                        this.mis_informes_r1.push(item);
                      }
                    });
                    this.mis_informes_pp.forEach((item) => {
                      if (item.id_municipio === 1 || item.id_municipio === 12 || item.id_municipio === 13 || item.id_municipio === 14 ||
                        item.id_municipio === 20 || item.id_municipio === 34 || item.id_municipio === 37 || item.id_municipio === 39 || item.id_municipio === 42
                        || item.id_municipio === 56 || item.id_municipio === 60 || item.id_municipio === 62 || item.id_municipio === 76 || item.id_municipio === 85
                        || item.id_municipio === 90 || item.id_municipio === 91 || item.id_municipio === 101 || item.id_municipio === 118 || item.id_municipio === 126
                        || item.id_municipio === 130 || item.id_municipio === 133 || item.id_municipio === 135 || item.id_municipio === 145) {
                        this.mis_informes_r2.push(item);
                      }
                    });
                    this.mis_informes_pp.forEach((item) => {
                      if (item.id_municipio === 11 || item.id_municipio === 17 || item.id_municipio === 33 || item.id_municipio === 51 ||
                        item.id_municipio === 64 || item.id_municipio === 65 || item.id_municipio === 66 || item.id_municipio === 68 || item.id_municipio === 78
                        || item.id_municipio === 79 || item.id_municipio === 80 || item.id_municipio === 80 || item.id_municipio === 102 || item.id_municipio === 109
                        || item.id_municipio === 120 || item.id_municipio === 139 || item.id_municipio === 140 || item.id_municipio === 141 || item.id_municipio === 144) {
                        this.mis_informes_r3.push(item);
                      }
                    });
                    this.mis_informes_pp.forEach((item) => {
                      if (item.id_municipio === 3 || item.id_municipio === 4 || item.id_municipio === 8 || item.id_municipio === 10 ||
                        item.id_municipio === 25 || item.id_municipio === 26 || item.id_municipio === 27 || item.id_municipio === 28 || item.id_municipio === 38
                        || item.id_municipio === 45 || item.id_municipio === 48 || item.id_municipio === 53 || item.id_municipio === 57 || item.id_municipio === 71
                        || item.id_municipio === 77 || item.id_municipio === 86 || item.id_municipio === 95 || item.id_municipio === 104 || item.id_municipio === 111
                        || item.id_municipio === 114 || item.id_municipio === 127 || item.id_municipio === 142) {
                        this.mis_informes_r4.push(item);
                      }
                    });
                    this.mis_informes_pp.forEach((item) => {
                      if (item.id_municipio === 2 || item.id_municipio === 6 || item.id_municipio === 9 || item.id_municipio === 18 ||
                        item.id_municipio === 29 || item.id_municipio === 30 || item.id_municipio === 31 || item.id_municipio === 32 || item.id_municipio === 36
                        || item.id_municipio === 49 || item.id_municipio === 55 || item.id_municipio === 70 || item.id_municipio === 73 || item.id_municipio === 81
                        || item.id_municipio === 97 || item.id_municipio === 100 || item.id_municipio === 106 || item.id_municipio === 108 || item.id_municipio === 112
                        || item.id_municipio === 115 || item.id_municipio === 117 || item.id_municipio === 123 || item.id_municipio === 128 || item.id_municipio === 129
                        || item.id_municipio === 138 || item.id_municipio === 146) {
                        this.mis_informes_r5.push(item);
                      }
                    });
                    this.mis_informes_pp.forEach((item) => {
                      if (item.id_municipio === 16 || item.id_municipio === 22 || item.id_municipio === 41 || item.id_municipio === 43 ||
                        item.id_municipio === 47 || item.id_municipio === 52 || item.id_municipio === 67 || item.id_municipio === 69 || item.id_municipio === 75
                        || item.id_municipio === 83 || item.id_municipio === 93 || item.id_municipio === 94 || item.id_municipio === 98 || item.id_municipio === 103
                        || item.id_municipio === 119 || item.id_municipio === 121 || item.id_municipio === 122 || item.id_municipio === 125 || item.id_municipio === 131
                        || item.id_municipio === 137 || item.id_municipio === 147 || item.id_municipio === 149 || item.id_municipio === 152) {
                        this.mis_informes_r6.push(item);
                      }
                    });
                    this.mis_informes_pp.forEach((item) => {
                      if (item.id_municipio === 7 || item.id_municipio === 87 || item.id_municipio === 89 || item.id_municipio === 96 || item.id_municipio === 132
                        || item.id_municipio === 148 || item.id_municipio === 153 || item.id_municipio === 155 || item.id_municipio === 156 || item.id_municipio === 157
                        || item.id_municipio === 158 || item.id_municipio === 159 || item.id_municipio === 160 || item.id_municipio === 161 || item.id_municipio === 162
                        || item.id_municipio === 163 || item.id_municipio === 164 || item.id_municipio === 165 || item.id_municipio === 166 || item.id_municipio === 167
                        || item.id_municipio === 168 || item.id_municipio === 169 || item.id_municipio === 170 || item.id_municipio === 171) {
                        this.mis_informes_rn.push(item);
                      }
                    });
                    this.mis_informes_pp.forEach((item) => {
                      if (item.id_municipio === 5 || item.id_municipio === 21 || item.id_municipio === 23 || item.id_municipio === 40
                        || item.id_municipio === 50 || item.id_municipio === 54 || item.id_municipio === 59) {
                        this.mis_informes_rs.push(item);
                      }
                    });

                    $(() => {
                        $('table.table').DataTable(this.dtOption);
                    });


       
      });

  }

  get_id_informe(id: number) {
    this.id_i = 0;
    this.id_i = id;
  }
  delete() {
    this._InformeService.delete(this.token, this.id_i).subscribe(
      response => {
       var table = $('table.table').DataTable( {
        "retrieve": true
      } );
      table.destroy();
        this.getInformes(this.token);
        console.log("Registro Eliminado " + this.id_i);
      });
  }

  openModal(id: string, code: number) {
    this.modalService.open(id);

  }

  closeModal(id: string) {
    this.modalService.close(id);
  }




}
