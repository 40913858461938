

export class InformeInventarioParametroOrganolepticos{
    public id_informe = 0;
    public fuente = '';
    public tipo_fuente = '';
    public aspecto = '';
    public color = 0;
    public turbidez = 0;
    
}
