

export class InformeInventarioParametrosBacteriologicos{
    public id_informe = 0;
    public fuente = '';
    public tipo_fuente = '';
    public coliformes_totales = 0;
    public e_coli = 0;
    public termotolerantes =0;

}
