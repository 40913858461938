
export class InformeInventarioParametrosFisicoQuimicos{
    public id_informe = 0;
    public fuente = '';
    public tipo_fuente = '';
    public bdo = 0;
    public temperatura = 0;
    public pH = 0;
    public cloruros = 0;
    public sulfatos= 0;
    public calcio = 0;
    public magnesio = 0;
    public od = 0;
    public ce = 0;
    public sodio = 0;
    public potasio = 0;
    public aluminio = 0;
    public dureza_total = 0;
    public sdt = 0;
    
}
