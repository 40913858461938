

export class InformeInventarioParametrosIndeseables{
    public id_informe = 0;
    public fuente = '';
    public tipo_fuente = '';
    public nitratos = 0;
    public nitritos = 0;
    public amonio = 0;
    public hierro_total = 0;
    public manganeso= 0;
    public cobre = 0;
    public fosforo = 0;
    public fluoruros = 0;
    public zinc = 0;

    
}
