import { TipoSistema } from "../catalogos/tipo_sistema";
import { TipoBomba } from "../catalogos/tipo_bomba";
import { TipoTratamiento } from "../catalogos/tipo_tratamiento";
import { EstadoLegalFuente } from "../catalogos/estado_legal_fuente";
import { TipoFuenteAgua } from "../catalogos/tipo_fuente_agua";


export class InformeInventarioPropuestaAlternativa{
    public id_informe = 0;
    public fuente = '';
    public tipo_fuente : TipoFuenteAgua = null;
    public coordenada_zona = '';
    public coordenada_x = '';
    public coordenada_y = '';
    public coordenada_z = '';
    public caudal = 0;
    public tipo_sistema: TipoSistema = null;
    public tipo_bomba : TipoBomba = null;
    public tipo_tratamiento : TipoTratamiento = null;
    public estado_legal_fuente : EstadoLegalFuente = null;

}
