import { GeotecnicaSuelo } from '../catalogos/geotecnica_suelo';

export class InformeGeotecnicaSuelo {
    public id_informe: number;
    public geotecnica_suelo: GeotecnicaSuelo;

    constructor() {
        this.id_informe = 0;
        this.geotecnica_suelo = null;
    }
}
