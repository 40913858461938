import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class IdentityGuard implements CanActivate {

    constructor(
        private _router: Router,
        private _UserService: UserService,
    ) { }

    canActivate() {
        const identity = this._UserService.getIdentity();
        const expires = this._UserService.getExpires();

        if (identity) {
            return true;
        } else {
            this._router.navigate(['/inicio']);
            return false;
        }
    }
}
