

export class InformeInventarioParametrosMetales{
    public id_informe = 0;
    public fuente = '';
    public tipo_fuente = '';
    public arsenico = 0;
    public cianuro = 0;
    public mercurio = 0;
    public plomo= 0;
    
}
