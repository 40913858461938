import { Comunidad } from '../catalogos/comunidad';

export class InformeDistancias {
    public id_informe = 0;
    public comunidad: Comunidad = null;
    public distancia_departamental = 0;
    public distancia_municipal = 0;
    public distancia_managua = 0;
    public tiempo_vehiculo = 0;
    public tiempo_camion = 0;
    public distancia_dificultad = 0;
}
