import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/seguridad/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-usuario-password',
  templateUrl: './usuario-password.component.html',
  styleUrls: ['./usuario-password.component.css']
})
export class UsuarioPasswordComponent implements OnInit {

  public title;
  public identity;
  public token;
  public status;
  public data;
  public confpass = '';

  constructor(
    private _UserService: UserService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.title = 'Cambiar Contraseña';
    this.identity = this._UserService.getIdentity();
    this.token = this._UserService.getToken();
    this.data = { id: 0, oldpass: '', newpass: '' };
    this.data.id = this.identity.id;
  }

  onSubmit(form) {
    if (this.data.id !== 0
      && this.data.oldpass.trim().length !== 0
      && this.data.newpass.trim().length !== 0
      && this.data.newpass === this.confpass) {
      this._UserService.updatePass(this.token, this.data).subscribe(
        response => {
          this.identity = response;
          localStorage.setItem('user', JSON.stringify(this.identity));
          this.toastr.success('Se han guardado los cambios.', this.title, {
            timeOut: 3000,
            enableHtml: true,
            closeButton: true,
          });
        },
        error => {
          this.status = 'error';
          this.toastr.error('No se ha logrado actualizar los datos.', this.title, {
            timeOut: 3000,
            enableHtml: true,
            closeButton: true,
          });
        }
      );
    }
  }

}
