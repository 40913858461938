//#region imports

import { Departamento } from '../catalogos/departamento';
import { Municipio } from '../catalogos/municipio';
import { CategoriaPobreza } from '../catalogos/cateagoria_pobreza';
import { CategorizacionNivel } from '../catalogos/cateagorizacion_nivel';
import { CategorizacionTipoSaneamiento } from '../catalogos/categorizacion_tipo_saneamiento';
import { CategorizacionTipoIntervencion } from '../catalogos/categorizacion_tipo_intervencion';
import { Modalidad } from '../catalogos/modalidad';
import { Concesionario } from '../catalogos/concesionario';
import { TipoLinea } from '../catalogos/tipo_linea';
import { TipoFase } from '../catalogos/tipo_fase';
import { TipoRelieve } from '../catalogos/tipo_relieve';
import { TipoFuenteAgua } from '../catalogos/tipo_fuente_agua';
import { TipoSistema } from '../catalogos/tipo_sistema';
import { TipoClima } from '../catalogos/tipo_clima';
import { AreaProtegida } from '../catalogos/area_protegida';
import { TipoCoberturaVegetal } from '../catalogos/tipo_cobertura_vegetal';
import { TipoSuelo } from '../catalogos/tipo_suelo';
// ----
import { InformeActividadEconomica } from './informe_actividad_economica';
import { InformeActividadFortalecimiento } from './informe_actividad_fortalecimiento';
import { InformeAlternativa } from './informe_alternativa';
import { InformeAlternativasFuente } from './informe_alternativas_fuente';
import { InformeAportes } from './informe_aportes';
import { InformeAvales } from './informe_avales';
import { InformeBancoMaterial } from './informe_banco_material';
import { InformeComunidades } from './informe_comunidades';
import { InformeCorreos } from './informe_correos';
import { InformeDistancias } from './informe_distancias';
import { InformeDistribucionesRedes } from './informe_distribuciones_redes';
import { InformeEstacionPrecipitacion } from './informe_estacion_precipitacion';
import { InformeFuentesAmenazas } from './informe_fuentes_amenazas';
import { InformeFuentesContaminacion } from './informe_fuentes_contaminacion';
import { InformeGeotecnicaSuelo } from './informe_geotecnica_suelo';
import { InformeInstalacionesAgua } from './informe_instalaciones_agua';
import { InformeInstalacionesSaneamiento } from './informe_instalaciones_saneamiento';
import { InformeInstalacionesHigiene } from './informe_instalaciones_higiene';
import { InformeInstalacionesTratamiento } from './informe_instalaciones_tratamiento';
import { InformeInventarioFuenteSubterranea } from './informe_inventario_fuente_subterranea';
import { InformeInventarioFuenteSuperficial } from './informe_inventario_fuente_superficial';
import { InformeInventarioParametrosCalidad } from './informe_inventario_parametros_calidad';
import { InformeMedidasAdaptacion } from './informe_medidas_adaptacion';
import { InformeMedioHgSubterraneo } from './informe_medio_hg_subterraneo';
import { InformeMedioHgSuperficial } from './informe_medio_hg_superficial';
import { InformeNivelServicio } from './informe_nivel_servicio';
import { InformeObjetivosMedios } from './informe_objetivos_medios';
import { InformeObjetivosMetas } from './informe_objetivos_metas';
import { InformeOrganizacion } from './informe_organizacion';
import { InformeOrganizacionMiembros } from './informe_organizacion_miembros';
import { InformePdNivelServicio } from './informe_pd_nivel_servicio';
import { InformePotencialHidricoSubterraneo } from './informe_potencial_hidrico_subterraneo';
import { InformePotencialHidricoSuperficial } from './informe_potencial_hidrico_superficial';
import { InformeServicioExistente } from './informe_servicio_existente';
import { InformeTelefonos } from './informe_telefonos';
import { InformeUsosAgua } from './informe_usos_agua';
import { InformeViasAcceso } from './informe_vias_acceso';
import { InformeAlternativasFuenteCalidad } from './informe_alternativas_fuente_calidad';

import { global } from '../../services/global';
import { forEach } from '@angular/router/src/utils/collection';
import { element } from '@angular/core/src/render3/instructions';
import { ThrowStmt } from '@angular/compiler';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { InformeInventarioAnalisisPreliminar } from './informe_inventario_analisis_preliminar';
import { InformeInventarioParametroOrganolepticos } from './informe_inventario_parametros_organolepticos';
import { InformeInventarioParametrosFisicoQuimicos } from './informe_inventario_parametros_fisico_quimicios';
import { InformeInventarioParametrosIndeseables } from './informe_inventario_parametros_indeseables';
import { InformeInventarioParametrosMetales } from './informe_inventario_parametros_metales';
import { InformeInventarioParametrosBacteriologicos } from './informe_inventario_parametros_bacteriologicos';
import { InformeInventarioPropuestaAlternativa } from './informe_inventario_propuesta_alternativa';
import { InformeInventarioCorberturaForestal } from './informe_inventario_cobertura_forestal';
import { InformeActividadAgricolaProyecto } from './infome_actividad_agricola_proyecto';
import { InformeControlPlagas } from './informe_control_plagas';
import { InformeFertilizantes } from './informe_fertilizantes';
import { isNull } from 'util';


//#endregion imports

export class InformeN {

  //#region fields
  public total_distancia = 0;
  private _default_image = global.url + 'downloadfile?image=placeholder.png';
  private _default_file = global.url + 'downloadarch?arch=placeholder.pdf';
  private _default_excel = global.url + 'downloadexcel?excel=placeholder.xlsx';
  public id = 0;
  public id_usuario = 0;
  public estado_informe = 'Borrador';
  public codigo = '';
  // MODULO I SECCION I
  public solicitante = '';
  public solicitante_autoridad = '';
  public solicitante_direccion = '';
  public solicitante_director = '';
  public solicitante_umas = '';
  public informe_correos: [InformeCorreos] = <any>[];
  public fecha_elaboracion = '';
  public m1s1_completa = false;

  // SECCION II MODULO I
  public id_departamento = 0;
  public departamento: Departamento = null;
  public id_municipio = 0;
  public municipio: Municipio = null;
  public informe_comunidades: [InformeComunidades] = <any>[];
  public informe_distancias: [InformeDistancias] = <any>[];
  public informe_vias_acceso: [InformeViasAcceso] = <any>[];
  public id_categoria_pobreza = 0;
  public categoria_pobreza: CategoriaPobreza = null;
  public ubicacion_imagen = this._default_image;
  public ubicacion_micro = this._default_image;
  public m1s2_completa = false;

  // SECCCION III MODULO I
  public problema = '';
  public causas = '';
  public m1s3_completa = false;

  // SECCION IV MODULO I
  public poblacion_mujeres = 0;
  public poblacion_hombres = 0;
  public poblacion_total = 0;
  public anyo_lineabase = 0;
  public poblacion_ninnos = 0;
  public poblacion_ninnas = 0;
  public familias = 0;
  public persona_diferentes = 0;
  public familias_jefas = 0;
  public viviendas = 0;
  public ingreso = 0;
  public total_trabajadores = 0;
  public informe_actividades_economicas: [InformeActividadEconomica] = <any>[];
  public informe_servicios_existentes: [InformeServicioExistente] = <any>[];
  public ubicacion_infome_bd = this._default_file;
  public ubicacion_bd = this._default_excel;
  public m1s4_completa = false;

  // -- |SECCION INFORMACION TECNICA DEL SITIO DE PROYECTO| --
  // --| SECCION 5.1 ESTIMACION DE LA DEMANDA |--

  // --A) Datos Historíco ----
  public pd_poblacion_historica = 0;
  public anyo_censal = 0;

  // -- --| B) Proyeccion de poblacion |--

  public pd_tasa_crecimiento = 0;
  public pd_poblacion_actual = 0;
  public pd_anno_inicio = 0;
  public pd_poblacion_inicial = 0;

  public pd_poblacion_a_veinte = 0;
  public pd_periodo_disenno = 0;


  // --|C)  Criterios de diseño a 20 años ||-- ------- 
  public pd_dotacion_domiciliar = 0;
  public pd_demanda_dotdomiciliar = 0;
  // --------------------EMERGENCIA-----------------------
  public pd_dotacion_emergencia = 0;
  public pd_demanda_dotemergencia = 0;
  // ---------------------MERCADO----------------
  public existen_mercado = false;
  public pd_dotacion_mercado = 0;
  public pd_cant_mercado = 0;
  public pd_demanda_mercado = 0;
  // ------------------------ESCUELAS----------------
  public existen_escuelas = false;
  public pd_dotacion_escuela = 0;
  public cant_escuela = 0;
  public cant_alumno = 0;
  public pd_demanda_dotescuela = 0;

  // ---------------------PUESTO DE SALUDD----------------------
  public existen_psalud = false;
  public pd_dotacion_psalud = 0;
  public pd_cantidad_psalud = 0;
  public pd_demanda_dotpsalud = 0;
  // ------------------------- CENTRO DE SALUD -------------------
  public existen_csaslud = 0;
  public pd_dotacion_csalud = 0;
  public pd_csalud = 0;
  public pd_cant_cama = 0;
  public pd_demanda_dotcsalud = 0;
  // ----------------------MATADEROS RASTOS --------
  public existe_mataderos = false;
  public pd_dotacion_mataderos = 0;
  public pd_cant_mataderos = 0;
  public pd_demanda_dotmataderos = 0;


  // ---------------------INSTITUCIONES ----------
  public existe_institucion = false;
  public pd_dotacion_institucional = 0;
  public pd_demanda_dotinstitucional = 0;
  // -----------------COMERCIO --------
  public existe_comercio = false;
  public pd_dotacion_comercio = 0;
  public pd_demanda_dotcomercio = 0;
  // ----------------- INDUSTRIA -------------
  public existe_industria = false;
  public pd_dotacion_industria = 0;
  public pd_demanda_dotindustria = 0;


  public pd_dotacion_extradomiciliar = 0;
  public pd_demanda_dfinal = 0;

  // --| D) Caudales de diseño al final de período|
  public pd_caudal_medio = 0;
  public pd_caudal_conduccion = 0;
  public pd_caudal_distribucion = 0;
  public pd_volumen_almacenamiento = 0;
  public ubicacion_doc_revisado = this._default_file;
  public m1s5_1_completa = false;

  // --| modulo1.SECCION 5.2 INVENTARIO DE RECURSO HIDRICO |-- 
  public existen_f_superficial = false;
  public informe_inventario_fuente_superficial: [InformeInventarioFuenteSuperficial] = <any>[];
  public existen_f_subterranea = false;
  public informe_inventario_fuente_subterranea: [InformeInventarioFuenteSubterranea] = <any>[];
  public informe_inventario_analisis_preliminar: [InformeInventarioAnalisisPreliminar] = <any>[];
  public existen_p_organolepticos = false;
  public informe_inventario_parametros_organolepticos: [InformeInventarioParametroOrganolepticos] = <any>[];
  public existen_p_fisicos_quimicios = false;
  public informe_inventario_parametros_fisico_quimicios: [InformeInventarioParametrosFisicoQuimicos] = <any>[];
  public existen_s_indeseables = false;
  public informe_inventario_parametros_indeseables: [InformeInventarioParametrosIndeseables] = <any>[];
  public existen_p_metales = false;
  public informe_inventario_parametros_metales: [InformeInventarioParametrosMetales] = <any>[];
  public existen_p_bacteriologicos = false;
  public informe_inventario_parametros_bacteriologicos: [InformeInventarioParametrosBacteriologicos] = <any>[];
  public informe_inventario_propuesta_alternativa: [InformeInventarioPropuestaAlternativa] = <any>[];

  public requiere_estudio_complementario = false;
  public requiere_estudio_geofisico = false;
  public estudio_geofisico_cantidad = 0;
  public estudio_geofisico_perfiles = 0;
  public estudio_geofisico_longitud = 0;
  public estudio_geofisico_profundidad = 0;
  public requiere_prueba_bombeo = false;
  public prueba_bombeo_cantidad_escalonada = 0;
  public prueba_bombeo_cantidad_constante = 0;
  public prueba_bombeo_duracion = 0;
  public requiere_otras_pruebas = false;
  public requiere_transmisividad = false;
  public requiere_permeabilidad = false;
  public requiere_piezometria = false;
  public profundidad_nf_invierno = 0;
  public profundidad_nf_verano = 0;
  public ubicacion_fichahidri = this._default_file;
  public m1s5_2_completa = false;

  //  --| modulo1.SECCION 5.3 AMBIENTAL |--
  // -- | seccion de preguntas |--
  public descripcion_area_protegida = '';
  public existe_embalses = '';
  public animales_reportados = '';
  // -- seccion Principales usos de suelo en el área de influencia
  public bosque = false;
  public platancion_forestal = false;
  public pastos = false;
  public cultivos = false;
  public tacotal = false;
  public sin_vegetacion = false;
  public cultivo_perma = false;
  public humedales = false;
  public otros = false;
  public especificar = '';
  public informe_inventario_cobertura_forestal: [InformeInventarioCorberturaForestal] = <any>[];
  public cobertura_forestal_imagen = this._default_image;
  public nombres_arboles = '';
  public infome_actividad_agricola_proyecto: [InformeActividadAgricolaProyecto] = <any>[];
  public informe_control_plagas: [InformeControlPlagas] = <any>[];
  public informe_fertilizantes: [InformeFertilizantes] = <any>[];
  public beneficios = '';
  public fosiles = '';
  // seccion de banco mateial
  public existe_banco_materiales = false;
  public informe_bancos_materiales: [InformeBancoMaterial] = <any>[];
  public bancos_materiales_imagen = this._default_image;
  public bancos_autorizados = '';
  public bancos_materiales_indigenas = '';
  public botadero_existe = false;
  public botadero_en_uso = false;
  public botadero_distancia = 0;
  public botadero_coordenada_zona = '';
  public botadero_coordenada_x = '';
  public botadero_coordenada_y = '';
  public botadero_coordenada_z = '';


  public m1s5_3_completa = false;


  // --| modulo1.seccion5.4  COMPONENTE ELECTRICO|--
  public red_suministro_electrico = false;
  public id_concesionario = 0;
  public concesionario: Concesionario = null;
  public id_tipo_linea = 0;
  public tipo_linea: TipoLinea = null;
  public id_tipo_fase = 0;
  public tipo_fase: TipoFase = null;
  public existe_banco_transformador = false;
  public capacidad_banco_transformador = 0;
  public voltaje_banco_transformador = 0;
  public coordenadas_zona_transformador = '';
  public coordenadas_x_transformador = '';
  public coordenadas_y_transformador = '';
  public coordenadas_z_transformador = '';
  public distancia_poste = 0;
  public coordenadas_zona_poste = '';
  public coordenadas_x_poste = '';
  public coordenadas_y_poste = '';
  public coordenadas_z_poste = '';
  public ubicacion_img_energia = this._default_image;
  public ubicacion_fich_tecnica = this._default_file;
  public m1s5_4_completa = false;

  //  -- |MODULO1 Seccion 5.5 SUELOS	|
  public id_tipo_relieve = 0;
  public tipo_relieve: TipoRelieve = null;
  public informe_geotecnica_suelo: [InformeGeotecnicaSuelo] = <any>[];
  public descripcion_drenaje_pluvial = '';
  public ubicacion_terreno_img = this._default_image;
  public m1s5_5_completa = false;

  // --| modulo1. SECCION 5.6 SITUACION ACTUAL DEL PROYECTO |--
  public id_tipo_sistema = 0;
  public tipo_sistema: TipoSistema = null;
  public informe_instalaciones_agua: [InformeInstalacionesAgua] = <any>[];
  public informe_distribuciones_redes: [InformeDistribucionesRedes] = <any>[];
  public informe_nivel_servicio: [InformeNivelServicio] = <any>[];
  public servicio_dias_semana = 0;
  public servicio_horas_dia = 0;
  public presion_residual = 0;
  public hora_medicion = new Date().toLocaleTimeString();
  public pago_promedio_mensual = 0;
  public consumo_promedio_mensual = 0;
  public agua_recibe_tratamiento = false;
  public informe_instalaciones_tratamiento: [InformeInstalacionesTratamiento] = <any>[];
  public recibe_saneamiento = false;
  public informe_instalaciones_saneamiento: [InformeInstalacionesSaneamiento] = <any>[];
  public recibe_higiene = false;
  public informe_instalaciones_higiene: [InformeInstalacionesHigiene] = <any>[];
  public viviendas_sin_agua = 0;
  public viviendas_sin_saneamiento = 0;
  public viviendas_sin_higiene = 0;
  public deficit_agua = 0;
  public deficit_saneamiento = 0;
  public deficit_higiene = 0;
  public cobertura_agua = 0;
  public cobertura_saneamiento = 0;
  public cobertura_higiene = 0;
  public sin_proyecto_imagen = this._default_image;
  public m1s5_6_completa = false;

  public m1s5_completa = false;

  //  --| modulo. SECCION 6 DATOS GENERALES PROYECT SOLICITADO |--
  public id_categorizacion_nivel = 0;
  public categorizacion_nivel: CategorizacionNivel = null;
  public id_categorizacion_tipo_saneamiento = 0;
  public categorizacion_tipo_saneamiento: CategorizacionTipoSaneamiento = null;
  public nombre_proyecto = '';
  public id_categorizacion_tipo_intervencion = 0;
  public categorizacion_tipo_intervencion: CategorizacionTipoIntervencion = null;
  // public id_modalidad = 0;
  // public modalidad: Modalidad = null;
  public comunidad_organizada = false;
  public informe_organizacion: [InformeOrganizacion] = <any>[];
  public descrip_previa = '';
  public obra_conexion_domiciliar = 0;
  public obra_conexion_extradomiciliar = 0;
  public obra_puesto_publico = 0;
  public obra_sanamiento_arrastre = 0;
  public obra_saneamiento_seco = 0;
  public obra_unidad_higiene = 0;


  public monto_obra_conexion_domiciliar = 0;
  public monto_obra_conexion_extradomiciliar = 0;
  public monto_obra_puesto_publico = 0;
  public monto_obra_sanamiento_arrastre = 0;
  public monto_obra_saneamiento_seco = 0;
  public monto_obra_unidad_higiene = 0;

  public monto_ejecucion = 0;
  public monto_formulacion = 0;
  public monto_supervision = 0;
  public monto_social = 0;
  public monto_ambiental = 0;
  public monto_total = 0;

  public m1s6_completa = false;
  //#endregion MODULO I

  public id_tipo_clima = 0;
  public tipo_clima: TipoClima = null;
  public precipitacion_anual = 0;
  public temperatura_anual = 0;
  public evapotranspiracion_anual = 0;
  public humedad_relativa_anual = 0;
  public m2s1_completa = false;

  public dentro_area_protegida = false;
  public id_area_protegida = 0;
  public id_tipo_cobertura_vegetal_recarga = 0;
  public pendiente_recarga = 0;
  public id_tipo_suelo = 0;
  public vulnerabilidad = '';
  public id_tipo_obertura_vegetal_fuente = 0;
  public pendiente_fuente = 0;
  public requisitos_ambientales = '';
  public m2s2_completa = false;

  public objetivo_central = '';
  public objetivos_especificos = '';
  public proposito = '';
  public m3s1_completa = false;

  public m3s2_completa = false;

  public m6s1_completa = false;

  public participacion_ciclo = '';
  public experiencia = '';
  public participacion_mujer = '';
  public m7s1_completa = false;

  public created = new Date();
  public changed = new Date();
  //#endregion fields

  //#region tables

  public informe_alternativa: [InformeAlternativa] = <any>[];
  public informe_alternativas_fuente: [InformeAlternativasFuente] = <any>[];
  public informe_alternativas_fuente_calidad: [InformeAlternativasFuenteCalidad] = <any>[];
  public informe_aportes: [InformeAportes] = <any>[];
  public informe_avales: [InformeAvales] = <any>[];
  public informe_estacion_precipitacion: [InformeEstacionPrecipitacion] = <any>[];
  public informe_fuentes_amenazas: [InformeFuentesAmenazas] = <any>[];
  public informe_fuentes_contaminacion: [InformeFuentesContaminacion] = <any>[];
  public informe_medidas_adaptacion: [InformeMedidasAdaptacion] = <any>[];
  public informe_medio_hg_subterraneo: [InformeMedioHgSubterraneo] = <any>[];
  public informe_medio_hg_superficial: [InformeMedioHgSuperficial] = <any>[];
  public informe_objetivos_medios: [InformeObjetivosMedios] = <any>[];
  public informe_objetivos_metas: [InformeObjetivosMetas] = <any>[];
  public informe_organizacion_miembros: [InformeOrganizacionMiembros] = <any>[];
  public informe_pd_nivel_servicio: [InformePdNivelServicio] = <any>[];
  public informe_potencial_hidrico_subterraneo: [InformePotencialHidricoSubterraneo] = <any>[];
  public informe_potencial_hidrico_superficial: [InformePotencialHidricoSuperficial] = <any>[];
  public informe_usos_agua: [InformeUsosAgua] = <any>[];

  //#endregion tables
  public get_deficit() {
    this.cobertura_agua = 100 - this.deficit_agua;
    this.cobertura_higiene = 100 - this.deficit_higiene;
    this.cobertura_saneamiento = 100 - this.deficit_saneamiento;
  }
  //#region metodos modulo1
  public get_poblacion_total() {
    this.poblacion_total = this.poblacion_hombres + this.poblacion_mujeres;

  }
  public get_poblacion_actual() {
    this.pd_poblacion_actual = this.poblacion_total;
    this.pd_periodo_disenno = 20;
  }

  public get_obra_propuestas()
  {
    this.obra_conexion_domiciliar = this.viviendas_sin_agua;
    let extradomici = 0;
    if (this.existen_escuelas) 
      {
         extradomici +=  this.cant_escuela;
      }
      if (this.existen_mercado){
        extradomici +=  this.pd_cant_mercado;
      }
      if (this.existen_psalud){
        extradomici += this.pd_cantidad_psalud;
      }
      if (this.existen_csaslud) {
        extradomici += this.pd_csalud;
      }
      if (this.existe_mataderos) {
        extradomici += this.pd_cant_mataderos;
      }
      if (this.existe_industria)
      {
        extradomici += 1;
      }
      if (this.existe_institucion) {
        extradomici += 1;
      }
      if (this.existe_comercio) {
        extradomici += 1;
      }
      this.obra_conexion_extradomiciliar = extradomici;
      this.obra_unidad_higiene = this.viviendas_sin_higiene;
      
  }

  public get_tasacrecimiento() {
    let n = this.anyo_lineabase - this.anyo_censal;
    this.pd_tasa_crecimiento = ((((this.poblacion_total / this.pd_poblacion_historica)) ** (1 / n)) - 1) * 100;
    this.pd_tasa_crecimiento = parseFloat(this.pd_tasa_crecimiento.toFixed(2));
    if (this.pd_tasa_crecimiento < 2.50) {
      this.pd_tasa_crecimiento = 2.50;
    }
  }
  get_poblaicon_20_años() {
    // CALCULAR LA POBLACION ACTUAL DE AÑO DE INCIO
    let n = this.pd_anno_inicio - this.anyo_lineabase;
    let r = this.pd_tasa_crecimiento / 100;
    this.pd_poblacion_inicial = this.poblacion_total * ((1 + r) ** n)
    if (this.pd_poblacion_inicial % 1 === 0) {
      this.pd_poblacion_a_veinte += 0;
    }
    else {
      this.pd_poblacion_inicial = Math.floor(this.pd_poblacion_inicial);   // Se redondea al entero mas cercano 
      this.pd_poblacion_inicial = this.pd_poblacion_inicial + 1;
    }
    /* aca se calcula la poblacion al año de inicio del proyecto , ala que se le denomina poblacion inicial */
    this.pd_poblacion_a_veinte = this.pd_poblacion_inicial * ((1 + r) ** 20) // PF = POBLACIÓN FUTURA.
    if (this.pd_poblacion_a_veinte % 1 === 0) {
      this.pd_poblacion_a_veinte += 0;
    } else {
      this.pd_poblacion_a_veinte = Math.floor(this.pd_poblacion_a_veinte); // Se redondea al entero mas cercano 
      this.pd_poblacion_a_veinte = this.pd_poblacion_a_veinte + 1;
    }

  }
  get_criterioDiseño() {
    // --------------------- CALCULAR DEMANDA ----------------------------
    this.pd_demanda_dotdomiciliar = this.pd_poblacion_a_veinte * this.pd_dotacion_domiciliar;
    this.pd_demanda_dotdomiciliar = parseFloat(this.pd_demanda_dotdomiciliar.toFixed(2));

    // .........EMERGENCIA DEMANDA --------------
    this.pd_demanda_dotemergencia = this.pd_poblacion_a_veinte * this.pd_dotacion_emergencia;
    this.pd_demanda_dotemergencia = parseFloat(this.pd_demanda_dotemergencia.toFixed(2));

    // .......ESCUELA DEMANDA-....................
    if (this.existen_escuelas) {
      this.pd_demanda_dotescuela = this.cant_alumno * this.pd_dotacion_escuela;
      this.pd_demanda_dotescuela = parseFloat(this.pd_demanda_dotescuela.toFixed(2));
    } else {
      this.cant_alumno = 0; this.pd_demanda_dotescuela = 0; this.cant_escuela = 0; this.pd_dotacion_escuela = null;
    }
    // ............... MERCADO DEMANDA ..................
    if (this.existen_mercado) {
      this.pd_demanda_mercado = this.pd_cant_mercado * this.pd_dotacion_mercado
      this.pd_demanda_mercado = parseFloat(this.pd_demanda_mercado.toFixed(2));
    } else {
      this.pd_cant_mercado = 0; this.pd_dotacion_mercado = null; this.pd_demanda_mercado = 0;
    }
    // .............. PUESTO DE SALUDA DEMANDA ..............-.
    if (this.existen_psalud) {
      this.pd_demanda_dotpsalud = this.pd_cantidad_psalud * this.pd_dotacion_psalud;
      this.pd_demanda_dotpsalud = parseFloat(this.pd_demanda_dotpsalud.toFixed(2));
    } else {
      this.pd_dotacion_psalud = null; this.pd_cantidad_psalud = 0; this.pd_demanda_dotpsalud = 0;
    }
    // ----------------- CENTRO DE SALUD DEMNADA..............
    if (this.existen_csaslud) {
      this.pd_demanda_dotcsalud = ((this.pd_csalud * this.pd_dotacion_csalud) + (this.pd_cant_cama * 50));
      this.pd_demanda_dotcsalud = parseFloat(this.pd_demanda_dotcsalud.toFixed(2));

    } else {
      this.pd_demanda_dotcsalud = 0; this.pd_csalud = 0; this.pd_dotacion_csalud = null; this.pd_cant_cama = 0;
    }
    // ............... MATADEROS DEMANDA ----------------------
    if (this.existe_mataderos) {
      this.pd_demanda_dotmataderos = this.pd_cant_mataderos * this.pd_dotacion_mataderos;
      this.pd_demanda_dotmataderos = parseFloat(this.pd_demanda_dotmataderos.toFixed(2));

    } else {
      this.pd_dotacion_mataderos = null; this.pd_cant_mataderos = 0; this.pd_demanda_dotmataderos = 0;
    }

    // ............... PUBLICA / INSTITUCIONAL  DEMANDA .....................
    if (this.existe_institucion) {
      this.pd_dotacion_institucional = (this.pd_dotacion_domiciliar * 0.07);
      this.pd_dotacion_institucional = parseFloat(this.pd_dotacion_institucional.toFixed(2));
      this.pd_demanda_dotinstitucional = (this.pd_poblacion_a_veinte * this.pd_dotacion_institucional);
      this.pd_demanda_dotinstitucional = parseFloat(this.pd_demanda_dotinstitucional.toFixed(2));
    } else {
      this.pd_demanda_dotinstitucional = 0; this.pd_dotacion_institucional = 0;
    }
    // -------------------- COMERCIO DEMANDA----------------------------
    if (this.existe_comercio) {
      this.pd_dotacion_comercio = (this.pd_dotacion_domiciliar * 0.07);
      this.pd_dotacion_comercio = parseFloat(this.pd_dotacion_comercio.toFixed(2));
      this.pd_demanda_dotcomercio = (this.pd_poblacion_a_veinte * this.pd_dotacion_comercio);
      this.pd_demanda_dotcomercio = parseFloat(this.pd_demanda_dotcomercio.toFixed(2));
    } else {
      this.pd_dotacion_comercio = 0; this.pd_demanda_dotcomercio = 0;
    }
    // --------------------- INDUSTRIA DEMANDA.........................
    if (this.existe_industria) {
      this.pd_dotacion_industria = (this.pd_dotacion_domiciliar * 0.02);
      this.pd_dotacion_industria = parseFloat(this.pd_dotacion_industria.toFixed(2));
      this.pd_demanda_dotindustria = (this.pd_poblacion_a_veinte * this.pd_dotacion_industria);
      this.pd_demanda_dotindustria = parseFloat(this.pd_demanda_dotindustria.toFixed(2));
    } else {
      this.pd_dotacion_industria = 0; this.pd_demanda_dotindustria = 0;
    }
    // .................. DEMANDA EXTRADOMICILIAR.............................
    this.pd_dotacion_extradomiciliar = (this.pd_demanda_dotemergencia + this.pd_demanda_dotescuela + this.pd_demanda_mercado + this.pd_demanda_dotpsalud + this.pd_demanda_dotcsalud + this.pd_demanda_dotmataderos + this.pd_demanda_dotinstitucional + this.pd_demanda_dotcomercio + this.pd_demanda_dotindustria);
    this.pd_dotacion_extradomiciliar = parseFloat(this.pd_dotacion_extradomiciliar.toFixed(2));


    // ---------------------------- CDTP --------------------------------
    this.pd_demanda_dfinal = (this.pd_demanda_dotdomiciliar + this.pd_dotacion_extradomiciliar) * 1.20;  // CONSUMO PROMEDIO TOTAL DIARIO
    this.pd_demanda_dfinal = parseFloat(this.pd_demanda_dfinal.toFixed(2));

    // ----------------- QMed.......
    this.pd_caudal_medio = this.pd_demanda_dfinal / 86400;
    this.pd_caudal_medio = parseFloat(this.pd_caudal_medio.toFixed(2));

    // ................ Qmax día ...........................
    this.pd_caudal_conduccion = this.pd_caudal_medio * 1.5;
    this.pd_caudal_conduccion = parseFloat(this.pd_caudal_conduccion.toFixed(2));

    // ..................Qmax Hora --------------------
    this.pd_caudal_distribucion = this.pd_caudal_medio * 2.5;
    this.pd_caudal_distribucion = parseFloat(this.pd_caudal_distribucion.toFixed(2));

    // ............... VOLUMENN
    this.pd_volumen_almacenamiento = this.pd_caudal_medio * 0.40 * 86.4;
    this.pd_volumen_almacenamiento = parseFloat(this.pd_volumen_almacenamiento.toFixed(2));

  }
  // #validadores check cuando se crea el informe
  public check_m1_completa() {
    this.get_m1s1_completa();
    this.get_m1s2_completa();
    this.get_m1s3_completa();
    this.get_m1s4_completa();
    this.get_m1s5_1_completa();
    this.get_m1s5_2_completa();
    this.get_m1s5_3_completa();
    this.get_m1s5_4_completa();
    this.get_m1s5_5_completa();
    this.get_m1s5_6_completa();
    this.get_m1s5_completa();
    this.get_m1s6_completa();

  }
  // #validadores check cuando se edita el informe
  public check_m1e_completa() {
    this.get_m1s1_completa();
    this.get_m1s2e_completa();
    this.get_m1s3_completa();
    this.get_m1s4_completa();
    this.get_m1s5_1_completa();
    this.get_m1s5_2_completa();
    this.get_m1s5_3_completa();
    this.get_m1s5_4E_completa();
    this.get_m1s5_5E_completa();
    this.get_m1s5_6E_completa();
    this.get_m1s5_completa();
    this.get_m1s6E_completa();
  }


  public get_m1s1_completa(): boolean {
    const completa = (this.fecha_elaboracion != null &&
      this.solicitante.trim().length > 12 &&
      this.solicitante_autoridad.trim().length > 12 &&
      this.solicitante_direccion.trim().length > 12 &&
      this.solicitante_director.trim().length > 12 &&
      this.solicitante_umas.trim().length > 12 &&
      this.informe_correos.length > 2 &&
      this.fecha_elaboracion.trim().length > 7);

    this.m1s1_completa = completa;
    return completa;
  }

  public get_m1s2_completa(): boolean {
    // this.get_distanciaskm();
    const completa = (this.departamento !== null
      && this.municipio !== null
      && this.informe_comunidades.length > 0
      && this.informe_distancias.length > 0
      && this.informe_vias_acceso.length > 0
      && this.categoria_pobreza !== null
      && this.ubicacion_imagen !== this._default_image
      && this.ubicacion_micro !== this._default_image
    );
    this.m1s2_completa = completa;
    return completa;
  }

  public get_m1s2e_completa(): boolean {
    //  this.get_distanciaskm();
    const completa = (this.id_departamento !== 0
      && this.id_municipio !== 0
      && this.informe_comunidades.length > 0
      && this.informe_distancias.length > 0
      && this.informe_vias_acceso.length > 0
      && this.id_categoria_pobreza !== 0
      && this.ubicacion_imagen !== this._default_image
      && this.ubicacion_micro !== this._default_image
    );
    this.m1s2_completa = completa;
    return completa;
  }


  public get_m1s3_completa(): boolean {
    const completa = (this.problema.length >= 1500
      && this.causas.length >= 300);
    this.m1s3_completa = completa;
    return completa;
  }


  public get_m1s4_completa(): boolean {
    this.get_poblacion_total();
    const completa = (this.poblacion_mujeres > 0
      && this.poblacion_hombres > 0
      && this.poblacion_total > 0
      && this.poblacion_ninnos >= 0 && this.poblacion_ninnos < this.poblacion_hombres
      && this.poblacion_ninnas >= 0 && this.poblacion_ninnas < this.poblacion_mujeres
      && this.familias > 0
      && this.persona_diferentes >= 0
      && this.familias_jefas >= 0
      && this.viviendas > 0
      && this.ingreso > 0
      && this.total_trabajadores >= 0
      && this.informe_actividades_economicas.length > 0
      && this.anyo_lineabase >= 2015 && this.anyo_lineabase <= 2050
      && this.informe_servicios_existentes.length > 0
      && this.ubicacion_bd !== this._default_excel
      && this.ubicacion_infome_bd !== this._default_file

    );
    this.m1s4_completa = completa;
    return completa;
  }

  public get_m1s5_1_completa(): boolean {
    this.get_poblacion_actual();
    this.get_tasacrecimiento();
    this.get_poblaicon_20_años();
    this.get_criterioDiseño();
    const completa = (
      this.pd_tasa_crecimiento >= 2.50
      && this.pd_periodo_disenno > 0
      && this.pd_anno_inicio > this.anyo_lineabase
      // && this.pd_dotacion_inicial > 0
      && this.pd_dotacion_emergencia >= 0
      && this.pd_dotacion_mercado >= 0
      && this.pd_dotacion_escuela >= 0
      && this.pd_dotacion_psalud >= 0
      && this.pd_dotacion_csalud >= 0
      && this.pd_dotacion_mataderos >= 0
      && this.pd_dotacion_institucional >= 0
      && this.pd_dotacion_comercio >= 0
      && this.pd_dotacion_industria >= 0
      && this.pd_dotacion_extradomiciliar >= 0
      && this.pd_dotacion_domiciliar > 0

      && this.pd_poblacion_actual > 0
      && this.pd_poblacion_a_veinte > 0
      && this.pd_anno_inicio > 2018

      && this.pd_caudal_medio > 0
      && this.pd_caudal_conduccion > 0
      && this.pd_caudal_distribucion > 0
      && this.pd_volumen_almacenamiento > 0
      && this.ubicacion_doc_revisado !== this._default_file

    );
    this.m1s5_1_completa = completa;
    return completa;
  }

  public get_m1s5_2_completa(): boolean {
    let completa = (
      this.profundidad_nf_invierno >= 0
      && this.profundidad_nf_verano >= 0
      && this.ubicacion_fichahidri !== this._default_file
      && this.informe_inventario_propuesta_alternativa.length >= 2 // SER MINIMO 2 ALTERNATIVASA
    );
    if (this.existen_f_superficial) {
      completa = completa && (
        this.informe_inventario_fuente_superficial.length > 0
        && this.informe_inventario_analisis_preliminar.length > 0);
    }
    if (this.existen_f_subterranea) {
      completa = completa && (
        this.informe_inventario_fuente_subterranea.length > 0
        && this.informe_inventario_analisis_preliminar.length > 0);
    }
    if (this.existen_p_organolepticos) {
      completa = completa && (
        this.informe_inventario_parametros_organolepticos.length > 0);
    }
    if (this.existen_p_fisicos_quimicios) {
      completa = completa && (
        this.informe_inventario_parametros_fisico_quimicios.length > 0);
    }
    if (this.existen_s_indeseables) {
      completa = completa && (
        this.informe_inventario_parametros_indeseables.length > 0);
    }
    if (this.existen_p_metales) {
      completa = completa && (
        this.informe_inventario_parametros_metales.length > 0);
    }
    if (this.existen_p_bacteriologicos) {
      completa = completa && (
        this.informe_inventario_parametros_bacteriologicos.length > 0);
    }
    if (this.requiere_estudio_complementario) {
      if (this.requiere_estudio_geofisico) {
        completa = completa && (
          this.estudio_geofisico_cantidad > 0 &&
          this.estudio_geofisico_perfiles > 0 &&
          this.estudio_geofisico_longitud > 0 &&
          this.estudio_geofisico_profundidad > 0);
      }
      if (this.requiere_prueba_bombeo) {
        completa = completa && (
          (this.prueba_bombeo_cantidad_escalonada > 0 && this.prueba_bombeo_duracion > 0)
          || (this.prueba_bombeo_cantidad_constante > 0 && this.prueba_bombeo_duracion > 0));
      }
      if (this.requiere_otras_pruebas) {
        completa = (completa
          && (this.requiere_transmisividad || this.requiere_permeabilidad || this.requiere_piezometria));
      }

    }
    this.m1s5_2_completa = completa;
    return completa;
  }


  public get_m1s5_3_completa(): boolean {
    let completa = (
      this.descripcion_area_protegida.trim().length > 19
      && this.existe_embalses.trim().length > 19
      && this.animales_reportados.trim().length > 19
      && this.bosque !== null
      && this.pastos !== null
      && this.cultivos !== null
      && this.tacotal !== null
      && this.sin_vegetacion !== null
      && this.cultivo_perma !== null
      && this.cobertura_forestal_imagen !== this._default_image
      && this.nombres_arboles.trim().length > 19
      && this.beneficios.trim().length > 19
      && this.fosiles.trim().length > 19
    );
    if (this.otros) {
      completa = (completa &&
        (this.especificar.trim().length > 5))
    }
    if (this.botadero_existe) {
      if (this.botadero_en_uso) {
        completa = (completa &&
          (this.botadero_distancia > 0
            && this.botadero_coordenada_zona !== ''
            && this.botadero_coordenada_x !== ''
            && this.botadero_coordenada_y !== ''
            && this.botadero_coordenada_z !== ''));
      }
    }
    if (this.existe_banco_materiales) {
      completa = (completa &&
        (this.informe_bancos_materiales.length > 0
          && this.bancos_autorizados.trim().length > 19
          && this.bancos_materiales_imagen !== this._default_image
          && this.bancos_materiales_indigenas.trim().length > 19))
    }
    this.m1s5_3_completa = completa;
    return completa;
  }


  public get_m1s5_4_completa(): boolean {
       let completa = false;
    if (this.red_suministro_electrico) {
       completa = (
        this.concesionario !== null
        && this.tipo_linea !== null
        && this.ubicacion_img_energia !== this._default_image
        && this.tipo_fase !== null);
      if (this.existe_banco_transformador) {
        completa = (completa
          && this.capacidad_banco_transformador > 0
          && this.voltaje_banco_transformador > 0
          && this.coordenadas_zona_transformador !== ''
          && this.coordenadas_x_transformador.trim().length > 5
          && this.coordenadas_y_transformador.trim().length > 6
          && this.coordenadas_z_transformador.trim().length > 0
        );
      } else {
        completa = (completa
          && this.distancia_poste > 0
          && this.coordenadas_zona_poste !== ''
          && this.coordenadas_x_poste.trim().length > 5
          && this.coordenadas_y_poste.trim().length > 6
          && this.coordenadas_z_poste.trim().length > 0
        );
      }

      this.m1s5_4_completa = completa;
      return completa;
    }else{
      completa = (completa
        && this.distancia_poste > 0
        && this.coordenadas_zona_poste !== ''
        && this.coordenadas_x_poste.trim().length > 5
        && this.coordenadas_y_poste.trim().length > 6
        && this.coordenadas_z_poste.trim().length > 0);

        this.m1s5_4_completa = completa;
        return completa;

    }

}

  public get_m1s5_4E_completa(): boolean {
    let completa = false;
    if (this.red_suministro_electrico !== null) {
      completa = (
        this.id_concesionario !== 0
        && this.ubicacion_img_energia !== this._default_image
        && this.id_tipo_linea !== 0
        && this.id_tipo_fase !== 0);
      if (this.existe_banco_transformador) {
        completa = (completa
          && this.capacidad_banco_transformador > 0
          && this.voltaje_banco_transformador > 0
          && this.coordenadas_zona_transformador !== ''
          && this.coordenadas_x_transformador.trim().length > 5
          && this.coordenadas_y_transformador.trim().length > 6
          && this.coordenadas_z_transformador.trim().length > 0
        );
      } else {
        completa = (completa
          && this.distancia_poste > 0
          && this.coordenadas_zona_poste !== ''
          && this.coordenadas_x_poste.trim().length > 5
          && this.coordenadas_y_poste.trim().length > 6
          && this.coordenadas_z_poste.trim().length > 0
        );
      }

      this.m1s5_4_completa = completa;
      return completa;
    }else{
        completa = (completa
        && this.distancia_poste > 0
        && this.coordenadas_zona_poste !== ''
        && this.coordenadas_x_poste.trim().length > 5
        && this.coordenadas_y_poste.trim().length > 6
        && this.coordenadas_z_poste.trim().length > 0);

        this.m1s5_4_completa = completa;
        return completa;

    }
  }





  public get_m1s5_5_completa(): boolean {
    const completa = (this.informe_geotecnica_suelo.length > 0
      && this.descripcion_drenaje_pluvial.trim().length > 10
      && this.tipo_relieve != null
    );

    this.m1s5_5_completa = completa;
    return completa;
  }

  public get_m1s5_5E_completa(): boolean {
    const completa = (this.informe_geotecnica_suelo.length > 0
      && this.descripcion_drenaje_pluvial.trim().length > 10
      && this.id_tipo_relieve !== 0
    );

    this.m1s5_5_completa = completa;
    return completa;
  }





  public get_m1s5_6_completa(): boolean {
    this.get_deficit();
    let completa = (
      this.deficit_agua >= 0 && this.deficit_agua <= 100
      && this.sin_proyecto_imagen !== this._default_image
      && this.deficit_higiene >= 0 && this.deficit_higiene <= 100
      && this.deficit_saneamiento >= 0 && this.deficit_saneamiento <= 100
    );

    if (this.agua_recibe_tratamiento) {
      completa = (completa
        && this.informe_instalaciones_tratamiento.length > 0)
    }
    if (this.recibe_saneamiento) {
      completa = (completa
        && this.informe_instalaciones_saneamiento.length > 0)
    }
    if (this.recibe_higiene) {
      completa = (completa
        && this.informe_instalaciones_higiene.length > 0)
    }
    if (this.tipo_sistema !== null) {
      completa = (
        this.informe_instalaciones_agua.length > 0
        && this.informe_distribuciones_redes.length >= 0
        && this.informe_nivel_servicio.length >= 0
        && this.servicio_dias_semana > 0
        && this.servicio_horas_dia > 0
        && this.presion_residual >= 0
        && this.pago_promedio_mensual >= 0
        && this.consumo_promedio_mensual > 0);

    }

    this.m1s5_6_completa = completa;
    return completa;

  }

  public get_m1s5_6E_completa(): boolean {
    this.get_deficit();
    let completa = (
      this.deficit_agua >= 0 && this.deficit_agua <= 100
      && this.sin_proyecto_imagen !== this._default_image
      && this.deficit_higiene >= 0 && this.deficit_higiene <= 100
      && this.deficit_saneamiento >= 0 && this.deficit_saneamiento <= 100
    );

    if (this.agua_recibe_tratamiento) {
      completa = (completa
        && this.informe_instalaciones_tratamiento.length > 0)
    }
    if (this.recibe_saneamiento) {
      completa = (completa
        && this.informe_instalaciones_saneamiento.length > 0)
    }
    if (this.recibe_higiene) {
      completa = (completa
        && this.informe_instalaciones_higiene.length > 0)
    }
    if (this.id_tipo_sistema !== 17) {
      completa = (
        this.informe_instalaciones_agua.length > 0
        && this.informe_distribuciones_redes.length >= 0
        && this.informe_nivel_servicio.length >= 0
        && this.servicio_dias_semana > 0
        && this.servicio_horas_dia > 0
        && this.presion_residual >= 0
        && this.pago_promedio_mensual >= 0
        && this.consumo_promedio_mensual > 0
      );

    }

    this.m1s5_6_completa = completa;
    return completa;

  }


  public get_m1s5_completa(): boolean {
    const completa = (this.m1s5_1_completa
      && this.m1s5_2_completa
      && this.m1s5_4_completa
      && this.m1s5_3_completa
      && this.m1s5_6_completa
      && this.m1s5_5_completa

    );
    this.m1s5_completa = completa;
    return completa;
  }


  public get_m1s6_completa(): boolean {
   
    let completa = (
      this.categorizacion_nivel !== null
      && this.categorizacion_tipo_saneamiento !== null
      && this.categorizacion_tipo_intervencion !== null
      && this.nombre_proyecto.trim().length > 15
      && this.descrip_previa.trim().length > 299
      && this.obra_conexion_domiciliar > 0
      && this.obra_conexion_extradomiciliar >= 0
      && this.obra_puesto_publico >=0
      && this.obra_sanamiento_arrastre >= 0
      && this.obra_saneamiento_seco >= 0
      && this.obra_unidad_higiene > 0
      // && this.modalidad !== null
    );
    if (this.comunidad_organizada) {
      completa = (completa
        && this.informe_organizacion.length > 0);
    }
    this.m1s6_completa = completa;
    return completa;
  }

  public get_m1s6E_completa(): boolean {
  
    let completa = (
      this.id_categorizacion_nivel !== 0
      && this.id_categorizacion_tipo_saneamiento !== 0
      && this.id_categorizacion_tipo_intervencion !== 0
      && this.nombre_proyecto.length >= 15
      && this.descrip_previa.length > 299
      && this.obra_conexion_domiciliar > 0
      && this.obra_conexion_extradomiciliar >= 0
      && this.obra_puesto_publico >=0
      && this.obra_sanamiento_arrastre >= 0
      && this.obra_saneamiento_seco >= 0
      && this.obra_unidad_higiene > 0
      // && this.id_modalidad !== 0
    );
    if (this.comunidad_organizada) {
      completa = (completa
        && this.informe_organizacion.length > 0
      );
    }
    this.m1s6_completa = completa;
    return completa;
  }


  // # ENDREGION metodos Modulo I
  // # region metodos modulo 2

  check_m2_completa() {

    this.get_m2s1_completa();
    // this.get_m2s2_completa();
  }

  get_m2s1_completa(): boolean {
    const completa = false;
    this.m2s1_completa = completa;
    return completa;
  }

  /*get_m2s2_completa(): boolean {
    let completa = false;
    completa = (
      this.pd_tasa_crecimiento > 0 &&
      this.pd_periodo_disenno > 0 &&
      this.pd_dotacion_inicial > 0 &&
      this.pd_dotacion_final > 0 &&
      this.pd_poblacion_actual > 0 &&
      this.pd_poblacion_a_cinco > 0 &&
      this.pd_poblacion_a_diez > 0 &&
      this.pd_poblacion_a_quince > 0 &&
      this.pd_poblacion_a_veinte > 0 &&
      this.pd_anno_inicio > 0 &&
      this.pd_hab_viv > 0 &&
      this.pd_viviendas_inicio > 0 &&
      this.pd_dotacion_extradomiciliar > 0 &&
      this.pd_consumo_promedio_diario > 0 &&
      this.pd_consumo_total_diario > 0 &&
      this.pd_consumo_maximo_diario > 0 &&
      this.pd_consumo_maximo_horario > 0 &&
      this.pd_requerimiento_percapita > 0 &&
      this.pd_caudal_medio > 0 &&
      this.pd_caudal_conduccion > 0 &&
      this.pd_caudal_distribucion > 0 &&
      this.informe_pd_nivel_servicio.length > 0
    );
    this.m2s2_completa = completa;
    return completa;
  } */

  //#endregion metodos modulo 2

  //#region metodos modulo 3

  check_m3_completa() {

    this.get_m3s1_completa();

  }

  get_m3s1_completa(): boolean {
    let completa = false;
    if (this.objetivo_central.trim().length > 0
      && this.objetivos_especificos.trim().length > 0
      && this.proposito.trim().length > 0
      && this.informe_objetivos_medios.length > 0
      && this.informe_objetivos_metas.length > 0
    ) {
      completa = true;
    }
    this.m3s1_completa = completa;
    return completa;
  }


  //#endregion metodos modulo 3

  constructor(UserId: number) {
    this.id_usuario = UserId;
  }
}
