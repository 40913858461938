
export class InformeEstacionPrecipitacion {
    public id_informe = 0;
    public periodo = '';
    public estacion = '';
    public coordenada_zona = '';
    public coordenada_x = 0;
    public coordenada_y = 0;
    public coordenada_z = 0;
    public precipitacion_ene = 0;
    public precipitacion_feb = 0;
    public precipitacion_mar = 0;
    public precipitacion_abr = 0;
    public precipitacion_may = 0;
    public precipitacion_jun = 0;
    public precipitacion_jul = 0;
    public precipitacion_ago = 0;
    public precipitacion_sep = 0;
    public precipitacion_oct = 0;
    public precipitacion_nov = 0;
    public precipitacion_dic = 0;
}
