import { TipoRed } from '../catalogos/tipo_red';

export class InformeDistribucionesRedes {
    public id_informe = 0;
    public tipo_red: TipoRed = null;
    public diametro = 0;
    public estado = '';
    public longitud = 0;
    public caracteristicas = '';
}
