import { NivelServicio } from '../catalogos/nivel_servicio';

export class InformeNivelServicio {
    public id_informe = 0;
    public nivel_servicio: NivelServicio = null;
    public estado = '';
    public cantidad = 0;
    public poblacion_atendida = 0;
    public viviendas_atendidas = 0;
    public caracteristicas = '';
}
