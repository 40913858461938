import { TipoMaterial } from '../catalogos/tipo_material';

export class InformeBancoMaterial {
    public id_informe = 0;
    public nombre = '';
    public tipo_tenencia = '';
    public duenno = '';
    public estado_via_acceso = '';
    public distancia_obra = 0;
    public explotacion = false;
    public tipo_material: TipoMaterial = null;
    public permiso = false;
    public coordenada_zona = '';
    public coordenada_x = '';
    public coordenada_y = '';
    public coordenada_z = '';
}

