
export class InformePotencialHidricoSuperficial {
    public id_informe = 0;
    public mes = '';
    public dias = 0;
    public temperatura = 0;
    public etp_sin_corregir = 0;
    public ept_corregido = 0;
    public precipitacion = 0;
    public p_etp = 0;
    public etr = 0;
    public almacenamiento = 0;
    public deficit = 0;
    public excedentes = 0;
    public exedentes_medio = 0;
    public escorrentia_total = 0;
    public escorrentia_media = 0;
    public caudal_teorico_m3 = 0;
    public caudal_teorico_lps = 0;
    public demanda_md = 0;
    public deficit_exeso = 0;
}
