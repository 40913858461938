import { Component, OnInit } from '@angular/core';
import { global } from '../../services/global';
declare var $;
import { InformeService } from '../../services/informe.service';
import { UserService } from '../../services/seguridad/user.service';
import { ModalService } from '../../_services';

@Component({
  selector: 'app-reporte',
  templateUrl: './reporte.component.html',
  styleUrls: ['./reporte.component.css'],
  providers: [InformeService, ModalService],
})

export class ReporteComponent implements OnInit {

  dtOption: any;
  public identity;
  public token;
  public data = [];
  public datta = [];

  constructor(
    private modalService: ModalService,
    private _InformeService: InformeService,
    private _UserService: UserService,
  ) { }

  ngOnInit() {
         this.dtOption = {
          "scrollY": 330,
          "scrollX": true,
          dom: 'Bfrtip',
          buttons: [
              'excelHtml5',
          ],
          initComplete: function () {
            this.api().columns().every( function () {
                var column = this;
                var select = $('<select class="form-control"><option value=""></option></select>')
                    .appendTo( $(column.footer()).empty() )
                    .on( 'change', function () {
                        var val = $.fn.dataTable.util.escapeRegex(
                            $(this).val()
                        );
 
                        column
                            .search( val ? '^'+val+'$' : '', true, false )
                            .draw();
                    } );
 
                column.data().unique().sort().each( function ( d, j ) {
                    select.append( '<option value="'+d+'">'+d+'</option>' )
                } );
            } );
        },
        "pageLength":"50",

           "language": {
        "lengthMenu": "Mostar " + `
                        <option value = '100'>100</option>
                        <option value = '-1'>ALL</option>
                        </select>
          `+ " registros por página",
        "zeroRecords": "Nada Encontrado - Disculpa",
        "info": "Mostrando de _START_  a _END_ elementos de un total de _TOTAL_  ",
        "infoEmpty": "No records available",
        "infoFiltered": "( filtrado de _MAX_ registros totales)",
        "search": "Buscar",
        "paginate": {
          "next": "Siguiente",
          "previous": "Anterior"

        }
      }
       
      }
      this.token = this._UserService.getToken();
      this.identity = this._UserService.getIdentity();
      this.getData(this.token);
  }

  getData(token){
    this._InformeService.report(token).subscribe(
      response => {   this.data = response;
        this.data.forEach((item) => {
          if (item.estado === 'Perfil de Proyecto') {
            this.datta.push(item);
          }

        });

        $(() => {
          $('#example').DataTable(this.dtOption);
         });

      });

 }


}
