

export class InformeInventarioAnalisisPreliminar {
    public id_informe = 0;
    public fuente = '';
    public tipo_fuente = '';
    public pH = 0;
    public temperatura = 0;
    public ce = 0;
    public sdt =0;
    public turbidez = 0;
    public salinidad = 0;
    public hierro = 0;
    public cloro_total =0;
    public cloro_residual = 0;

}
