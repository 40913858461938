export class InformeMedioHgSuperficial {
    public id_informe = 0;
    public nombre = '';
    public codigo_pfastetter = '';
    public area = 0;
    public longitud_rio_principal = 0;
    public longitud_rio_total = 0;
    public altura_maxima = 0;
    public altura_minima = 0;
    public tiempo_concentracion_horas = 0;
    public tiempo_concentracion_minutos = 0;
    public coeficiente_compacidad = 0;
    public densidad_drenaje = 0;
    public factor_forma = 0;
    public pendiente = 0;
    public rios_primer_orden = 0;
    public rios_total = 0;
    public coeficiente_torrencialidad = 0;
    public extension_escurrimiento = 0;
    public frecuencia_rios = 0;
    public cuenca_orden = 0;
    public perimetro = 0;
}
