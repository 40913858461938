import { TipoAcuifero } from '../catalogos/tipo_acuifero';

export class InformeMedioHgSubterraneo {
    public id_informe = 0;
    public nombre = '';
    public tipo_acuifero: TipoAcuifero = null;
    public espesor = 0;
    public transmisividad = 0;
    public conductividad = 0;
    public coeficiente_almacenamiento = 0;
}
